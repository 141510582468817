
export default {
    setProjects(state, projects) {
        state.projects = projects
    },
    setItems(state, items) {
        state.items = items
    },
    setIsModalVisible(state, isModalVisible) {
        state.isModalVisible = isModalVisible
    },
    setItem2project(state, itemId) {
        state.item2project = itemId
    },
    updateItem(state, context) {
        let index = state.items.findIndex(d => d.id === context.id);

        if(index > -1) {
            if(context.count) {
                state.items[index].count = context.count;
            }
            if('is_checked' in context){
                state.items[index].is_checked = context.is_checked;
            }
        }
    },
    setCheckedRoom(state, context) {
        state.items = state.items.map((item) => {
            if(item.room === context.room) {
                item.is_checked = context.checked;
            }

            return item;
        });
    },
    setCheckedAll(state, context) {
        state.items = state.items.map((item) => {
            item.is_checked = context.checked;

            return item;
        });
    },
    removeItem(state, itemId) {
        let index = state.items.findIndex(d => d.id === itemId);
        if(index > -1) {
            if(state.items[index].show_room_title && state.items[index + 1]) {
                state.items[index + 1].show_room_title = true;
            }
            state.items = state.items.filter(function(el) { return el.id !== itemId; });
        }
    },
}
