<template>
    <label class="set-room" v-if="item.show_room_title">
        <div class="set-room__checkbox">
            <checkbox
                :fieldId="item.id + '_' + item.room"
                v-model:values="checkedRoom"
                :value="item.room"
                :title="''"
                :disabled="false"
            />
        </div>
        <span>{{ item.room === '' ? 'Без помещения' : item.room }}</span>
    </label>
    <div class="set-item">
        <div class="set-item__checkbox">
            <checkbox
                :fieldId="item.id"
                v-model:values="checked"
                :value="item.id"
                :title="''"
                :disabled="false"
            />
        </div>
        <div class="set-item__image">
            <img :src="getWebUrl() + item.image" alt="">
        </div>
        <div class="set-item__title">
            <router-link
                :to="generatePartnerRedirectUrl('/catalog/detail/' + item.code + '/')"
            >
                {{ item.title }}
            </router-link>
<!--            <div class="set-item__room" @click="showModal">
                Изменить помещение
            </div>-->
        </div>
        <div class="set-item__price">
            {{ item.sum_formatted }}
        </div>
        <div class="set-item__quantity">
            <div class="product-quantity">
                <input
                    type="text"
                    :value="parseInt(item.count)"
                    @keypress="isNumber($event)"
                    @change="setQuantity(setQnt($event.target.value, item.measure_ratio))"
                >
                <button
                    class="product-quantity__btn plus"
                    @click="setQuantity(setQnt(parseInt(item.count) + item.measure_ratio, item.measure_ratio))"
                >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 5H9M5 9L5 1" stroke="#788496" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
                <button
                    class="product-quantity__btn minus"
                    @click="setQuantity(setQnt(parseInt(item.count) - item.measure_ratio, item.measure_ratio))"
                >
                    <svg width="10" height="2" viewBox="0 0 10 2" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 1H9" stroke="#788496" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
        <div class="set-item__action">
            <svg @click="remove" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.5 0L10.5 1H14V3H0V1H3.5L4.5 0H9.5ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6Z"
                      fill="#D2D2D2"
                />
            </svg>
        </div>
        <Modal
            v-show="isModalVisible"
            @close="closeModal"
            small
        >
            <template v-slot:header>
                Изменить помещение
            </template>
            <template v-slot:body>
                <div
                    class="feedback-form"
                >
                    <div class="feedback-form__group">
                        <input
                            type="text"
                            class="feedback-form__input"
                            placeholder="Введите название помещения"
                            v-model="room"
                        >
                    </div>
                    <div
                        class="btn btn--orange"
                        @click="setRoomSubmit"
                    >
                        Применить
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
    import {ref, watch, computed} from 'vue'
    import store from '@/store/store'
    import axios from "axios";
    import Modal from "@/components/Modal"
    import Checkbox from "@/components/form/Checkbox"

    export default {
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        components: {
            Modal,
            Checkbox
        },
        setup(props) {
            const remove = () => {
                store.dispatch('project/removeItem', props.item.id);
                store.commit('project/removeItem', props.item.id);
            }

            const setQuantity = (qnt) => {
                if (qnt < 1 || qnt === props.item.count) return false

                store.dispatch('project/setItemQuantity', {id: props.item.id, count: parseInt(qnt)});
                store.commit('project/updateItem', {id: props.item.id, count: parseInt(qnt)});
            }

            const room = ref(props.item.room);

            const setRoomSubmit = () => {
                axios.post(process.env.VUE_APP_API_URL + '/project/room/', {
                    id: props.item.id,
                    room: room.value,
                })
                    .then(response => {
                        store.dispatch('project/getItems');
                        closeModal();
                    })
                    .catch(error => console.log('Ошибка: ', error))
            }

            const isModalVisible = ref(false);

            const showModal = () => {
                isModalVisible.value = true
            }

            const closeModal = () => {
                isModalVisible.value = false
            }

            const checkedRoom = ref([]);

            watch(checkedRoom, (newCheckedRoom) => {
                store.commit('project/setCheckedRoom', {room: props.item.room, checked: newCheckedRoom[0] === props.item.room});
            });

            const checked = ref([]);

            watch(checked, (newChecked) => {
                store.commit('project/updateItem', {id: props.item.id, is_checked: newChecked[0] === props.item.id});
            });

            const checkedItems = computed(() => {
                return store.getters['project/checkedItems'];
            });

            watch(checkedItems, () => {
                let index = checkedItems.value.findIndex(d => d.id === props.item.id);

                if(index > -1) {
                    checked.value = [props.item.id];
                }
                else {
                    checked.value = [];
                }
            });

            return {
                remove,
                setQuantity,
                setRoomSubmit,
                room,
                isModalVisible,
                showModal,
                closeModal,
                checked,
                checkedRoom,
                checkedItems
            };
        }
    }
</script>

<style lang="scss">
    .set-room {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 34px;
        color: #434A54;
        padding-top: 15px;
        position: relative;
        background: #f0f2f5;
        top: -5px;
        cursor: pointer;
        width: fit-content;
    }

    .set-room__checkbox {
        padding-left: 20px;
    }

    @media (max-width: 991px) {
        .set-room__checkbox {
            padding-left: 10px;
        }
    }
</style>
