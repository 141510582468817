import axios from 'axios'
import store from "@/store/store";

export default {
    getProjects({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/project/list/',
            params = {};

        return axios.get(url, {params: params})
            .then(response => {
                commit('setProjects', response.data.data);
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getItems({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/project/items/',
            params = {};

        return axios.get(url, {params: params})
            .then(response => {
                if (response.data.data.length > 0) {
                    response.data.data.sort((a, b) => a.room.localeCompare(b.room));

                    let lastRoom = response.data.data[0].room;
                    for (let i in response.data.data) {
                        const room = response.data.data[i].room;
                        response.data.data[i].show_room_title = room !== lastRoom;
                        lastRoom = room;
                    }
                    response.data.data[0].show_room_title = true;
                }
                commit('setItems', response.data.data);
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    addProject({commit, dispatch}, name) {
        let url = process.env.VUE_APP_API_URL + '/project/add/';

        return axios.post(url, {name: name})
            .then(response => {
                dispatch('getItems')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    deleteProject({commit, dispatch}, id) {
        let url = process.env.VUE_APP_API_URL + '/project/delete/';
        console.log('{id: id}', {id: id})
        return axios.post(url, {id: id})
            .then(response => {
                dispatch('getItems')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    selectProject({commit, dispatch}, id) {
        let url = process.env.VUE_APP_API_URL + '/project/select/';

        return axios.post(url, {id: id})
            .then(response => {
                dispatch('getItems')
                dispatch('getProjects')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    renameProject({commit, dispatch}, {id, name}) {
        let url = process.env.VUE_APP_API_URL + '/project/rename/';

        return axios.post(url, {id, name})
            .then(response => {
            
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    addItem({commit, dispatch}, productId) {
        let url = process.env.VUE_APP_API_URL + '/project/item/add/';

        return axios.post(url, {productId: productId})
            .then(response => {
                dispatch('getItems')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    setItemQuantity({commit, dispatch}, {id, count}) {
        let url = process.env.VUE_APP_API_URL + '/project/item/quantity/';

        return axios.post(url, {itemId: id, quantity: count})
            .then(response => {
                dispatch('getItems')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    removeItem({commit, dispatch}, id) {
        let url = process.env.VUE_APP_API_URL + '/project/item/delete/';

        return axios.post(url, {id: id})
            .then(response => {

            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getPdf({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/project/pdf/'

        let items = [];
        for (let i in getters['items']) {
            let item = getters['items'][i];

            items.push({
                'id': item.id,
                'product_id': item.product_id,
                'count': item.count,
                'room': item.room,
            });
        }

        return axios.post(
            url,
            {items: items},
            {
                responseType: 'arraybuffer',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const d = new Date();
                const dateString = `${d.getHours()}-${d.getMinutes()} ${d.getDate()}-${(d.getMonth()+1)}-${d.getFullYear()}`
                link.href = url;
                link.setAttribute('download', `Проект интерьерного освещения ${dateString}.pdf`);
                document.body.appendChild(link);
                link.click();

                return true;
            })
            .catch(error => console.log('Ошибка: ', error))
    },
    getExcel({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/project/excel/'

        let items = [];
        for (let i in getters['items']) {
            let item = getters['items'][i];

            items.push({
                'id': item.id,
                'product_id': item.product_id,
                'count': item.count,
                'room': item.room,
            });
        }

        return axios.post(
            url,
            {items: items},
            {
                responseType: 'arraybuffer',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const d = new Date();
                const dateString = `${d.getHours()}-${d.getMinutes()} ${d.getDate()}-${(d.getMonth()+1)}-${d.getFullYear()}`
                link.href = url;
                link.setAttribute('download', `Проект интерьерного освещения ${dateString}.xlsx`);
                document.body.appendChild(link);
                link.click();

                return true;
            })
            .catch(error => console.log('Ошибка: ', error))
    },
    loadProject({commit, getters, dispatch}, project_id) {
        try {
            if (project_id) {
                let url = process.env.VUE_APP_API_URL + '/project/load/'

                return axios.post(url, {projectId: project_id})
                    .then(async response => {
                        if (response.data.error) {
                            console.log(response.data.error)
                        } else {
                            await dispatch('getItems');
                        }
                    })
                    .catch(error => console.log('Ошибка: ', error))
            }
        } catch {
            console.log('Примерка не найдена');
            return false
        }
    },
    getLink({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/project/share/'
        
        let items = [];
        for (let i in getters['items']) {
            let item = getters['items'][i];
            
            items.push({
                'id': item.id,
                'product_id': item.product_id,
                'count': item.count,
                'room': item.room,
            });
        }
        
        return axios.post(url, {items: items})
            .then(response => {
                return response.data.data;
            })
            .catch(error => console.log('Ошибка: ', error))
    },
}

