<template>
    <div
        class="fitting-viewed"
    >
        <div class="fitting-viewed__title">Ранее вы смотрели</div>

        <carousel
            class="fitting-viewed__wrapper"
            hideArrowsOnBound
        >
            <slide
                v-for="product in products"
                :key="product.id"
                class="fitting-viewed__slide"
            >
                <div
                    class="fitting-viewed-item"
                    @click="goToProduct('/catalog/detail/' + product.code + '/')"
                >
                    <div class="fitting-viewed-item__image">
                        <img :src="getWebUrl() + product.image">
                    </div>
                    <div
                        v-if="product.image_fitting"
                        class="fitting-viewed-item__title"
                        @click.stop="fittingAdd(product.id)"
                        :class="{ active: product.is_in_fitting }"
                    >
                        <span>{{ product.is_in_fitting ? 'В примерке' : 'Примерить' }}</span>
                    </div>
                    <div v-else class="fitting-viewed-item__stub"></div>
                </div>

            </slide>
        </carousel>
        <!-- <div
             class="fitting-viewed__wrapper fitting-viewed&#45;&#45;mobile"
         >
             <div
                 v-for="product in products"
                 :key="product.id"
                 class="fitting-viewed-item"
                 @click="goToProduct('/catalog' + product.url)"
             >
                 <div class="fitting-viewed-item__image">
                     <img :src="product.image">
                 </div>
                 <div
                     class="fitting-viewed-item__title"
                     @click.stop="fittingAdd(product.id)"
                     :class="{ active: product.is_in_fitting }"
                 >
                     <span>{{ product.is_in_fitting ? 'В примерке' : 'Примерить' }}</span>
                 </div>
             </div>
         </div>-->
    </div>
</template>

<script>
    import {Carousel, Slide} from 'vue-snap'
    import 'vue-snap/dist/vue-snap.css'
    import store from '@/store/store'
    import {onMounted, onUnmounted, ref} from 'vue'
    import {useRouter} from 'vue-router'

    export default {
        components: {
            Carousel,
            Slide
        },
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        setup(props) {
            const router = useRouter();

            const goToProduct = async (url) => {
                await router.push(url);
            }

            const fittingAdd = (id) => {
                let index = props.products.findIndex(d => d.id === id);

                if (index > -1) {
                    const product = props.products[index];

                    if (!product.is_in_fitting) {
                        store.dispatch('fitting/addItem', product.id);
                        store.commit('catalog/addToFittingFromViewed', product.id);
                    }
                }
            }


            const displayItemsCount = ref(9);

            onMounted(() => {
                window.addEventListener("resize", onResize);
            });

            onUnmounted(() => {
                window.removeEventListener("resize", onResize);
            });

            const onResize = () => {
                if (window.innerWidth <= 576) {
                    displayItemsCount.value = 4;
                    return;
                }
                if (window.innerWidth <= 768) {
                    displayItemsCount.value = 5;
                    return;
                }
                if (window.innerWidth <= 991) {
                    displayItemsCount.value = 7;
                    return;
                }
                displayItemsCount.value = 9;
            }

            onResize();

            return {
                fittingAdd,
                displayItemsCount,
                goToProduct
            }
        }
    }
</script>

<style lang="scss">

    .fitting-viewed {
        padding: 0 15px;
        margin: 0 -15px;
        margin-top: 20px;
        padding-bottom: 30px;
    }

    .fitting-viewed__wrapper {
        margin-bottom: 12px;

        .fitting-viewed__slide {
            flex: 0 0 33%
        }

        @media(min-width: 500px) {
            .fitting-viewed__slide {
                flex: 0 0 25%
            }
        }

        @media(min-width: 768px) {
            .fitting-viewed__slide {
                flex: 0 0 20%
            }
        }

        @media(min-width: 1024px) {
            .fitting-viewed__slide {
                flex: 0 0 12%
            }
        }

        @media(min-width: 1280px) {
            .fitting-viewed__slide {
                flex: 0 0 10%
            }
        }
    }

    .fitting-viewed__title {
        font-size: 20px;
        font-weight: bold;
        color: #434A54;
        margin-bottom: 12px;
    }

    .fitting-viewed-item {
        padding: 12px;
        background: #FFFFFF;
        border-radius: 4px;
        text-align: center;
        max-width: 100px;
        cursor: pointer;
        margin-right: 10px;
        box-shadow: 0px 2px 4px rgb(47 61 81 / 10%), 0px 5px 10px rgb(47 61 81 / 8%);
    }

    .fitting-viewed-item__title {
        margin-top: 6px;
        font-size: 13px;

        span {
            border-bottom: 1px dashed #4C4C4C;
        }

        &.active {
            span {
                border-bottom: none;
            }
        }
    }

    .fitting-viewed-item__stub {
        height: 24px;
    }

    .fitting-viewed-item__image {
        width: 70px;
        height: 70px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .clearfix:after {
        clear: both;
    }
</style>
