import {createApp} from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './router'
import IconBase from '@/components/IconBase'
import SlideUpDown from 'vue3-slide-up-down'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {dragscrollNext} from "vue-dragscroll"
import './assets/scss/main.scss'
import VueDraggableResizable from './libs/vue-draggable-resizable/'
import Cookies from 'js-cookie'
import Popper from "vue3-popper"
import Preloader from "@/components/Preloader"
import SplitCarousel from "vue-split-carousel"
import VueYandexMetrika from 'vue-v3-yandex-metrika'
import VueSmoothScroll from 'vue3-smooth-scroll'

window._ = require('lodash');

function uniqueID() {
	function chr10() {
		return Math.random().toString(16).slice(-10)
	}
	
	return chr10() + chr10() + chr10() + chr10()
}

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.has('from')) {
	const from = urlParams.get('from');
	const redirectUrl = new URL(from);
	redirectUrl.searchParams.append('dsBackUrl', location.pathname + location.search);
	if (from.includes('eway.elevel.ru')) {
		redirectUrl.searchParams.append('configurator', 'light');
	} else {
		redirectUrl.searchParams.append('dsconf', '1');
	}
	window.location.replace(redirectUrl.href);
}
let token = urlParams.get('session') ? urlParams.get('session') : Cookies.get('token');
let partner = urlParams.get('partner') || '';
let href = urlParams.get('href') || '';

if (!partner && process.env.NODE_ENV === 'development') {
	partner = 'https://elevel.ru/shop';
}

if (!token) {
	token = uniqueID();
	Cookies.set('token', token, {expires: 365 * 5})
}

window.axios = require('axios');

//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common['token'] = token
window.axios.defaults.headers.common['partner'] = partner

createApp(App)
	.use(store)
	.use(router)
	.use(VueAxios, axios)
	.use(VueDraggableResizable)
	.use(SplitCarousel)
	.use(VueYandexMetrika, {
		id: 87977077,
		router: router,
		env: process.env.NODE_ENV
		// other options
	})
	.use(VueSmoothScroll)
	.mixin({
		methods: {
			getDecl(n, words) {
				return words[(n % 100 > 4 && n % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(n % 10 < 5) ? n % 10 : 5]]
			},
			generateImagePath(path) {
				const url = process.env.VUE_APP_ENV_MODE === 'dev' ? process.env.VUE_APP_URL : '';
				
				return url + path
			},
			isNumber(evt) {
				evt = (evt) ? evt : window.event
				
				let charCode = (evt.which) ? evt.which : evt.keyCode
				
				if ((charCode > 31 && (charCode < 48 || charCode > 57))/* && charCode !== 46*/) {
					evt.preventDefault()
				} else {
					return true
				}
			},
			setQnt(value, ratio) {
				if (ratio <= 0 || isNaN(ratio)) ratio = 1;
				
				if (value < 1) value = 1;
				
				let newVal = value;
				
				if (ratio > 1) {
					let rem = newVal % ratio;
					
					if (rem) {
						if (rem >= Math.ceil(ratio / 2)) {
							newVal = value - rem + ratio;
						} else {
							newVal = value - rem;
						}
					}
					
					if (newVal === 0) {
						newVal = ratio;
					}
				}
				
				return newVal;
			},
			add2Basket(vendor, raecId, qnt) {
				let items = [
					{
						vendor_code: vendor,
						raec_id: raecId,
						quantity: qnt,
					}
				];
				
				window.parent.postMessage({
					type: 'DsAdd2Basket',
					items: items,
				}, '*');
			},
			getPartner() {
				return partner
			},
			getWebUrl() {
				return process.env.NODE_ENV === 'development' ? process.env.VUE_APP_WEB_URL : ''
			},
			generatePartnerRedirectUrl(path) {
				if (partner) {
					const url = new URL(path, location);
					if (href) {
						url.searchParams.append('from', href);
						
						path = url.pathname + url.search;
					}
				}
				
				return path
			},
		},
	})
	.directive('dragscroll', dragscrollNext)
	.directive('click-outside', {
		beforeMount(el, binding, vnode) {
			el.clickOutsideEvent = function (event) {
				if (!(el === event.target || el.contains(event.target))) {
					binding.value(event, el);
				}
			};
			document.body.addEventListener('click', el.clickOutsideEvent);
		},
		unmounted(el) {
			document.body.removeEventListener('click', el.clickOutsideEvent);
		}
	})
	.component('icon-base', IconBase)
	.component('slide-up-down', SlideUpDown)
	.component("Popper", Popper)
	.component("preloader", Preloader)
	.mount('#app')
