<template>
    <div class="catalog-sidebar-range">
        <div class="catalog-sidebar-range__from">
            <input class="input__area catalog-sidebar__label"
                   name="arrFilter_P3_MIN"
                   :value="values[0]"
                   @change="onInputMin(parseInt($event.target.value))"
            >
        </div>
        <div class="catalog-sidebar-range__to">
            <input class="input__area catalog-sidebar__label"
                   name="arrFilter_P3_MAX"
                   :value="values[1]"
                   @change="onInputMax(parseInt($event.target.value))"
            >
        </div>
    </div>
    <div
        v-if="!hideSlider"
        class="catalog-sidebar-slider"

    >
        <Slider
            v-model="values"
            :tooltips="false"
            @change="setFilter"
            :min="parseInt(min.value)"
            :max="parseInt(max.value)"
            class="slider-orange"
        />
    </div>

</template>

<script>
    import Slider from '@vueform/slider'
    import {ref, watch, onMounted} from 'vue'
    import store from '@/store/store'
    import {useRouter, useRoute} from 'vue-router'

    export default {
        components: {
            Slider
        },
        props: {
            min: {
                type: Object,
                required: true
            },
            max: {
                type: Object,
                required: true
            },
            changed: {
                type: Boolean,
                required: true,
                default: false
            },
            hideSlider: {
                type: Boolean,
                default: false
            }
        },
        setup(props) {
            const router = useRouter();
            const route = useRoute();
            let activeFilters = store.getters[route.meta.store + '/activeFilters'];

            let min = parseInt(props.min.value),
                max = parseInt(props.max.value);

            if(activeFilters[props.min.param]) {
                min = parseInt(activeFilters[props.min.param])
            }

            if(activeFilters[props.max.param]) {
                max = parseInt(activeFilters[props.max.param])
            }

            onMounted(() => {
                if (props.changed) {
                    if (props.min.checked_value) {
                        min = props.min.checked_value
                    }
                    if (props.max.checked_value) {
                        max = props.max.checked_value
                    }
                }
            });

            const values = ref([min, max]);

            const onInputMin = (val) => {
                if (val < min) {
                    val = min;
                }
                if (val > max) {
                    val = max;
                }
                values.value = [val, values.value[1]];
                setFilter()
            };

            const onInputMax = (val) => {
                if (val < min) {
                    val = min;
                }
                if (val > max) {
                    val = max;
                }
                values.value = [values.value[0], val];
                setFilter()
            };

            const setFilter = () => {
                let min_param = props.min.param,
                    max_param = props.max.param;

                let activeFilters = store.getters[route.meta.store + '/activeFilters'];

                if (values.value[0] === parseInt(props.min.value)) {
                    delete activeFilters[min_param];
                } else {
                    activeFilters[min_param] = values.value[0];
                }

                if (values.value[1] === parseInt(props.max.value)) {
                    delete activeFilters[max_param];
                } else {
                    activeFilters[max_param] = values.value[1];
                }

                store.commit(route.meta.store + '/setActiveFilters', activeFilters);
                //store.dispatch(route.meta.store + '/getProducts');
                router.push({ query: store.getters[route.meta.store + '/query']});
            }

            return {
                setFilter,
                onInputMin,
                onInputMax,
                values,
            };
        },
    }
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">
    .slider-orange {
        --slider-connect-bg: var(--orange);
        --slider-tooltip-bg: var(--orange);
        --slider-handle-ring-color: #fd762163;
        --slider-height: 4px;
        --slider-handle-bg: var(--orange);
        --slider-handle-shadow: none;
    }
</style>
