<template>
    <div
        class="header-search"
        :data-path="path"
        v-click-outside="hide"
        :class="{active: query && showResult}"
    >
        <back :key="path"/>
        <div class="header-search__inner">
            <div class="header-search__top">
                <div class="header-search__title">
                    Поиск
                </div>
                <div
                    @click="hide"
                    class="header-search__close"
                >
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1L1 11M1 1L11 11" stroke="#434A54" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </div>
            </div>
            <div
                class="header-search__form"
                :class="{active: query}"
            >
                <input type="text"
                       name="search"
                       autocomplete="off"
                       placeholder="Введите название товара или артикул..."
                       class="header-search__input"
                       @input="search"
                       v-model="query"
                       @click="show"
                       @keyup.enter="goToSearchPage"
                >
                <div class="header-search__clear" v-if="query" @click="query = ''">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 1L1 11M1 1L11 11" stroke="#8B8B8B" stroke-width="2" stroke-linecap="round"></path>
                    </svg>
                </div>
                <button class="header-search__btn">
                    <div
                        v-if="query"
                        class="btn btn--orange"
                        @click="goToSearchPage"
                    >
                        Найти
                    </div>
                    <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.71 11H12.5L17.49 16L16 17.49L11 12.5V11.71L10.73 11.43C9.59 12.41 8.11 13 6.5 13C2.91 13 0 10.09 0 6.5C0 2.91 2.91 0 6.5 0C10.09 0 13 2.91 13 6.5C13 8.11 12.41 9.59 11.43 10.73L11.71 11ZM2 6.5C2 8.99 4.01 11 6.5 11C8.99 11 11 8.99 11 6.5C11 4.01 8.99 2 6.5 2C4.01 2 2 4.01 2 6.5Z"
                              fill="#8B8B8B"
                        />
                    </svg>
                </button>
            </div>
            <div v-if="query && showResult"
                 class="header-search__container"
            >
                <div
                    v-if="isSearching"
                    class="header-search__preloader"
                >
                    <preloader/>
                </div>
                <div
                    v-else
                    class="header-search__wrapper"
                >
                    <div class="header-search__left">
                        <!--<div
                            v-if="result.queries && result.queries.length > 0"
                            class="search-query"
                        >
                            <div class="search-query__title">
                                Дополнить поиск:
                            </div>
                            <div
                                v-for="q in result.queries"
                                :key="q"
                                class="search-query__item"
                                @click="setQuery(q)"
                            >
                                <span>{{ query }}</span> {{ q }}
                            </div>
                        </div>-->
                        <div
                            v-if="result.sections && result.sections.length > 0"
                            class="search-sections noscroll-x"
                            v-dragscroll
                        >
                            <div class="search-sections__title">
                                Категории товаров:
                            </div>
                            <div
                                v-for="section in result.sections"
                                :key="section.id"
                                class="search-sections__item"
                                @click="goToSection(`/search/?q=${query}&section=${section.id}`)"
                            >
                                {{ section.title }} <span>{{ section.count }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="header-search__right">
                        <div
                            v-if="result.items_count > 0"
                            class="search-products"
                        >
                            <div class="search-products__title">
                                Найдено товаров: {{ result.items_count }}
                            </div>
                            <div class="search-products__wrapper">
                                <header-search-item
                                    v-for="item in result.items"
                                    :key="item.id"
                                    :product="item"
                                    @changeUrl="onChangeUrl"
                                />
                            </div>
                        </div>
                        <div
                            class="header-search__footer"
                            @click="goToSearchPage"
                        >
                            <span>Показать все результаты поиска</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ref, watch, computed} from 'vue'
    import store from '@/store/store'
    import HeaderSearchItem from "@/components/header/HeaderSearchItem"
    import debounce from "lodash/debounce"
    import {useRouter, useRoute} from 'vue-router'
    import Back from '@/components/Back'

    export default {
        components: {
            HeaderSearchItem,
            Back
        },
        setup() {
            const router = useRouter();
            const route = useRoute();

            const path = computed(() => {
                return route.path
            });

            const routeName = computed(() => {
                return route.name
            });

            const showResult = ref(false);
            const isSearching = ref(false);

            const query = ref('');
            const urlQuery = computed(() => {
                return store.getters['search/q']
            });

            const defaultResult = {
                queries: [],
                sections: [],
                items: [],
                items_count: 0,
            };

            const result = ref(defaultResult);

            const hide = () => {
                showResult.value = false;
                isSearching.value = false;
            }

            const show = () => {
                if (query.value && !urlQuery.value) {
                    showResult.value = true
                }
            }

            const search = () => {
                if(!urlQuery.value) {
                    isSearching.value = true;
                    showResult.value = true;
                    result.value = defaultResult;
                    if (!query.value) {
                        isSearching.value = false;
                        return result.value = defaultResult;
                    }
                    searchItems();
                }
            }

            const searchItems = debounce(() => {
                store.dispatch('search/searchPreview', query.value)
                    .then(data => {
                        result.value = data;
                        showResult.value = parseInt(data.items_count) > 0;
                        isSearching.value = false;
                    })
                    .catch(error => console.log('Ошибка: ', error));
            }, 500)

            const goToSearchPage = () => {
                if (query.value) {
                    showResult.value = false;
                    /*this.$store.commit('search/SET_FILTERS', { q: this.query })
                    this.$nuxt.$router.push(`/search/?q=${this.query}`)*/
                    router.push('/search/?q=' + query.value);

                }
            }

            const onChangeUrl = () => {
                hide();
            }

            const goToSection = async (url) => {
                hide();
                await router.push(url);
            }

            const setQuery = (q) => {
                query.value = query.value + ' ' + q;
                setTimeout(() => isSearching.value = true, 10)
                searchItems();
            }

            watch(urlQuery, (newUrlQuery) => {
                if(newUrlQuery) {
                    query.value = newUrlQuery;
                }
            });

            watch(routeName, (newRouteName) => {
                if(newRouteName !== 'search') {
                    query.value = '';
                }
                else {
                    query.value = urlQuery.value;
                }
            });

            watch(showResult, () => {
                if(window.innerWidth < 768) {
                    if(showResult.value) {
                        document.documentElement.classList.add('overflow-y-hidden');
                    }
                    else {
                        document.documentElement.classList.remove('overflow-y-hidden');
                    }
                }

            });

            return {
                query,
                showResult,
                isSearching,
                result,
                hide,
                show,
                search,
                goToSearchPage,
                onChangeUrl,
                goToSection,
                setQuery,
                path
            }
        }
    }
</script>

<style lang="scss">

</style>
