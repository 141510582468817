<template>
    <div
        class="catalog-sort"
    >
        <div class="catalog-sort__wrap">
            <div class="catalog-sort__item">
                <span class="catalog-sort__title">Сортировать по:</span>
                <form-select
                    :options="sorts"
                    :default="selectedSort"
                    @select="setSort($event)"
                />
            </div>
            <div v-for="filter in filters" class="catalog-sort__item " :key="filter.name">
                <span class="catalog-sort__title">{{ filter.name }}:</span>
                <form-select-multiple
                    :placeholder="filter.placeholder"
                    :values="filter.values"
                    :default="activeFilters"
                    :show-disabled="!!filter.show_disabled"
                    @select="setFilter($event)"
                    @remove="removeFilter($event)"
                />
            </div>
        </div>
        <div class="catalog-sort__item">
            <span class="catalog-sort__title">Вид:</span>
            <div class="catalog-sort__grid">
                <button
                    class="catalog-sort__view view-tile"
                    :class="{'catalog-sort__view--active':activeView === 'table'}"
                    @click="setActiveView('table')"
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#8B8B8B" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1C0 0.447715 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1V3C4 3.55228 3.55228 4 3 4H1C0.447715 4 0 3.55228 0 3V1Z"/>
                        <path d="M6 1C6 0.447715 6.44772 0 7 0H9C9.55228 0 10 0.447715 10 1V3C10 3.55228 9.55228 4 9 4H7C6.44772 4 6 3.55228 6 3V1Z"/>
                        <path d="M12 1C12 0.447715 12.4477 0 13 0H15C15.5523 0 16 0.447715 16 1V3C16 3.55228 15.5523 4 15 4H13C12.4477 4 12 3.55228 12 3V1Z"/>
                        <path d="M0 7C0 6.44772 0.447715 6 1 6H3C3.55228 6 4 6.44772 4 7V9C4 9.55228 3.55228 10 3 10H1C0.447715 10 0 9.55228 0 9V7Z"/>
                        <path d="M6 7C6 6.44772 6.44772 6 7 6H9C9.55228 6 10 6.44772 10 7V9C10 9.55228 9.55228 10 9 10H7C6.44772 10 6 9.55228 6 9V7Z"/>
                        <path d="M12 7C12 6.44772 12.4477 6 13 6H15C15.5523 6 16 6.44772 16 7V9C16 9.55228 15.5523 10 15 10H13C12.4477 10 12 9.55228 12 9V7Z"/>
                        <path d="M0 13C0 12.4477 0.447715 12 1 12H3C3.55228 12 4 12.4477 4 13V15C4 15.5523 3.55228 16 3 16H1C0.447715 16 0 15.5523 0 15V13Z"/>
                        <path d="M6 13C6 12.4477 6.44772 12 7 12H9C9.55228 12 10 12.4477 10 13V15C10 15.5523 9.55228 16 9 16H7C6.44772 16 6 15.5523 6 15V13Z"/>
                        <path d="M12 13C12 12.4477 12.4477 12 13 12H15C15.5523 12 16 12.4477 16 13V15C16 15.5523 15.5523 16 15 16H13C12.4477 16 12 15.5523 12 15V13Z"/>
                    </svg>
                </button>
                <button
                    class="catalog-sort__view view-row"
                    @click="setActiveView('row')"
                    :class="{'catalog-sort__view--active':activeView === 'row'}"
                >
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="#8B8B8B" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 1C0 0.447715 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1V3C4 3.55228 3.55228 4 3 4H1C0.447715 4 0 3.55228 0 3V1Z"/>
                        <path d="M6 1C6 0.447715 6.44772 0 7 0H15C15.5523 0 16 0.447715 16 1V3C16 3.55228 15.5523 4 15 4H7C6.44772 4 6 3.55228 6 3V1Z"/>
                        <path d="M0 7C0 6.44772 0.447715 6 1 6H3C3.55228 6 4 6.44772 4 7V9C4 9.55228 3.55228 10 3 10H1C0.447715 10 0 9.55228 0 9V7Z"/>
                        <path d="M6 7C6 6.44772 6.44772 6 7 6H15C15.5523 6 16 6.44772 16 7V9C16 9.55228 15.5523 10 15 10H7C6.44772 10 6 9.55228 6 9V7Z"/>
                        <path d="M0 13C0 12.4477 0.447715 12 1 12H3C3.55228 12 4 12.4477 4 13V15C4 15.5523 3.55228 16 3 16H1C0.447715 16 0 15.5523 0 15V13Z"/>
                        <path d="M6 13C6 12.4477 6.44772 12 7 12H15C15.5523 12 16 12.4477 16 13V15C16 15.5523 15.5523 16 15 16H7C6.44772 16 6 15.5523 6 15V13Z"/>
                    </svg>

                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import FormSelect from '@/components/form/Select'
    import FormSelectMultiple from '@/components/form/SelectMultiple'
    import store from '@/store/store'
    import {computed} from 'vue'
    import {useRoute, useRouter} from 'vue-router'
    import Cookies from "js-cookie";

    export default {
        components: {
            FormSelect,
            FormSelectMultiple
        },
        props: {
            sorts: {
                type: Array,
                required: true
            },
            selectedSort: {
                type: Object,
                required: true
            },
            showFilters: {
                type: Boolean,
            },
            filters: {
                type: Array,
            },
        },
        setup(props) {
            const route = useRoute();
            const router = useRouter();

            const setActiveView = (value) => {
                if(window.innerWidth <= 768) {
                    value = value === 'table' ? 'row' : 'table';
                }
                store.commit(route.meta.store + '/setActiveView', value);
            };

            const activeView = computed(() => {
                return store.getters[route.meta.store + '/activeView']
            });

            const setSort = (sort) => {
                router.replace({query: Object.assign({...route.query}, {sort: sort.value})});
                store.commit(route.meta.store + '/setSort', sort.value);
                //store.dispatch(route.meta.store + '/getProducts');
            };

            const activeFilters = computed(() => {
                return Object.keys(store.getters[route.meta.store + '/activeFilters'])
            });

            const setFilter = (filter) => {
                let activeFilters = store.getters[route.meta.store + '/activeFilters'];

                if(!activeFilters[filter]) {
                    activeFilters[filter] = 'Y';

                    store.commit(route.meta.store + '/setActiveFilters', activeFilters);
                    router.push({ query: store.getters[route.meta.store + '/query']});
                }
                /*router.replace({query: Object.assign({...route.query}, {sort: sort.value})});
                store.commit(route.meta.store + '/setSort', sort.value);
                //store.dispatch(route.meta.store + '/getProducts');*/
            };

            const removeFilter = (filter) => {
                let activeFilters = store.getters[route.meta.store + '/activeFilters'];

                if(activeFilters[filter]) {
                    delete activeFilters[filter];

                    store.commit(route.meta.store + '/setActiveFilters', activeFilters);
                    router.push({ query: store.getters[route.meta.store + '/query']});

                }
            };

            return {
                setActiveView,
                activeView,
                setSort,
                setFilter,
                removeFilter,
                activeFilters
            }
        }
    }
</script>

<style lang="scss">

</style>
