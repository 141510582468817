<template>
    <li>
        <span v-if="page === null" class="dots__holder">
            ...
        </span>
        <button
            v-else
            class="page"
            type="button"
            :class="{ 'page-active': isActive }"
            @click="clickHandler"
        >
            {{ page }}
        </button>
    </li>
</template>

<script>
import {defineComponent, computed} from 'vue';

export default defineComponent({
    name: 'Page',
    components: {},
    props: {
        page: {
            type: Number,
            default: null,
        },
        current: {
            type: Number,
            default: 0,
        },
        activeColor: {
            type: String,
            default: '#DCEDFF',
        },
    },
    emits: ['update'],

    setup(props, {emit}) {
        const isActive = computed(() => {
            return props.page === props.current;
        });

        function clickHandler() {
            emit('update', props.page);
        }

        return {isActive, clickHandler};
    },
});
</script>

<style lang="scss">

.page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: #434A54;
    margin: 0 5px;
    border: 1px solid transparent;

    &:hover {
        border-color: #434A54;
        color: var(--orange);
    }

    &-active {
        background: #CBD1D9;
    }
}

.dots__holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin: 0 2px;
    font-size: 13px;
}

@media (max-width: 576px) {
    .page {
        margin: 0 2px;
        padding: 10px 9px;
    }
}
</style>