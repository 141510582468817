<template>
    <div
        v-if="reviews.length > 0"
        class="card-review"
    >
        <div class="card-review__head">
            <h3 class="card-review__title">Отзывы</h3>
            <button class="card-review__btn btn btn--orange open-review">Оставить отзыв</button>
            <div class="card-review-star">
                <div class="card-review-star__list">
                    <img src="https://www.elevel.ru/local/templates/new/img/icon/star-orange.svg" alt="">
                    <img src="https://www.elevel.ru/local/templates/new/img/icon/star-orange.svg" alt="">
                    <img src="https://www.elevel.ru/local/templates/new/img/icon/star-orange.svg" alt="">
                    <img src="https://www.elevel.ru/local/templates/new/img/icon/star-orange.svg" alt="">
                    <img src="https://www.elevel.ru/local/templates/new/img/icon/star-orange.svg" alt="">
                </div>
                <span class="card-review-star__number">
                    <b>{{ reviews.length }} {{ getDecl(reviews.length, ['отзыв', 'отзыва', 'отзывов']) }}</b> со средней оценкой <b>5 из 5</b>
                </span>
            </div>
        </div>
        <div class="card-review-select">
            <form-select
                :options="sort"
                :default="selected_sort"
            />
        </div>
        <div class="js-wraper-review">
            <div
                v-for="review in reviews"
                :key="review.id"
                class="card-review__card"
            >
                <div class="card-review__top">
                    <div class="card-review-user">
                        <span class="card-review-user__name">{{ review.name }}</span>
                        <div class="card-review__stars">
                            <svg
                                v-for="star in review.rating"
                                :key="star"
                                width="14"
                                height="13"
                                viewBox="0 0 14 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M6.73316 1.01941C6.84475 0.802194 7.15525 0.802194 7.26684 1.01941L9.03735 4.46564C9.08091 4.55043 9.16217 4.60947 9.25627 4.6247L13.081 5.2436C13.322 5.28261 13.418 5.57792 13.2459 5.75117L10.5154 8.49997C10.4482 8.56759 10.4172 8.66313 10.4318 8.75732L11.0251 12.5861C11.0625 12.8274 10.8113 13.0099 10.5933 12.8998L7.1353 11.1524C7.05022 11.1094 6.94978 11.1094 6.8647 11.1524L3.40668 12.8998C3.18873 13.0099 2.93753 12.8274 2.97492 12.5861L3.5682 8.75732C3.5828 8.66312 3.55176 8.56759 3.48458 8.49997L0.754129 5.75117C0.582032 5.57792 0.677983 5.28261 0.919048 5.2436L4.74373 4.6247C4.83783 4.60947 4.91909 4.55043 4.96265 4.46564L6.73316 1.01941Z"
                                      fill="#FD7621"
                                />
                            </svg>
                        </div>
                    </div>
                    <span class="card-review__date">{{ review.date }}</span>
                </div>
                <div class="card-review__down">
                    <div
                        v-if="review.advantages"
                        class="card-review-opinion"
                    >
                        <span class="card-review-opinion__title">Достоинства:</span>
                        <p class="card-review-opinion__text">{{ review.advantages }}</p>
                    </div>
                    <div
                        v-if="review.disadvantages"
                        class="card-review-opinion"
                    >
                        <span class="card-review-opinion__title">Недостатки:</span>
                        <p class="card-review-opinion__text">{{ review.disadvantages }}</p>
                    </div>
                    <div
                        v-if="review.comment"
                        class="card-review-opinion"
                    >
                        <span class="card-review-opinion__title">Комментарий:</span>
                        <p class="card-review-opinion__text">{{ review.comment }}</p>
                    </div>
                    <div
                        v-if="review.images && review.images.length > 0"
                        class="card-review__photos"
                    >
                        <div
                            v-for="(url, i) in review.images"
                            :key="i"
                            class="card-review__photo"
                        >
                            <div>
                                <img :src="getWebUrl() + url" alt="">
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="review.response"
                        class="card-review-reply"
                    >
                        <h3 class="card-review-reply__title">Ответ магазина:</h3>
                        <p class="card-review-reply__desc">{{ review.response }}</p>
                    </div>
                    <div class="card-review-likes">
                        <span class="card-review-likes__title">Вам помог этот отзыв?</span>
                        <div class="card-review-likes__wrap">
                            <button class="card-review-likes__btn">
                                Да&nbsp;<span>{{ review.help }}</span>
                            </button>
                            <button class="card-review-likes__btn">
                                Нет&nbsp;<span>{{ review.not_help }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        class="card-review"
    >
        <div class="card-review__head">
            <h3 class="card-review__title">Отзывы</h3>
            <div class="card-review-star">
                <span class="card-review-star__number">
                   Пока ещё нет отзывов.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import FormSelect from '@/components/form/Select'

    export default {
        components: {
            FormSelect
        },
        props: {
            reviews: {
                type: Array
            }
        },
        setup() {
            const sort = [
                {
                    text: 'Сначала новые',
                    value: 'id-desc',
                },
                {
                    text: 'Сначала старые',
                    value: 'id-asc',
                },
                {
                    text: 'Полезные',
                    value: 'useful-desc',
                },
            ];

            const selected_sort = {
                text: 'Сначала новые',
                value: 'id-desc',
            };

            return {
                sort,
                selected_sort
            }
        },

    }
</script>

<style>

</style>
