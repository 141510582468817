<template>
    <div
        class="catalog-menu__block"
        :class="{active: isOpen}"
    >
        <div
            class="catalog-menu__top"
        >
            <div
                class="catalog-menu__title"
                :class="{active: route.params.sectionCode === sectionGroup.code || route.params.code === sectionGroup.code}"
                @click="goToSection(sectionGroup.code)"
            >
                <div class="catalog-menu__icon"
                     :class="{'catalog-menu__icon--yellow': getPartner().includes('amperkin.ru')}"
                >
                    <img v-if="sectionGroup.icon" :src="getWebUrl() + sectionGroup.icon">
                </div>
                <span>{{ sectionGroup.title }}</span>
            </div>
            <div
                v-if="sectionGroup.children.length > 0"
                class="catalog-menu__arrow"
                @click="toggleGroup"
            >
                <icon-base width="10"
                           height="16"
                           fill="transparent"
                >
                    <icon-arrow-right/>
                </icon-base>
            </div>
        </div>
        <slide-up-down v-model="isOpen" :duration="300">
            <div
                v-if="sectionGroup.children.length > 0"
                class="catalog-menu__body"
            >
                <div
                    v-for="sectionChildren in sectionGroup.children"
                    :key="sectionChildren.id"
                    class="catalog-menu__item"
                    :class="{active: route.params.sectionCode === sectionChildren.code || route.params.code === sectionChildren.code}"
                >
                    <div
                        class="product-link"
                        @click="goToSection(sectionChildren.code)"
                    >
                        {{ sectionChildren.title }}
                    </div>
                </div>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
    import {ref, watch, computed} from 'vue'
    import IconArrowRight from "@/components/icons/IconArrowRight"
    import {useRoute, useRouter} from 'vue-router'
    import store from '@/store/store'

    export default {
        components: {
            IconArrowRight
        },
        props: {
            sectionGroup: {
                type: Object,
                required: true
            }
        },
        setup(props, { emit }) {
            const router = useRouter();
            const route = useRoute();
            const sectionCode = computed(() => {
                return route.params.sectionCode || route.params.code
            });
            const isOpen = ref(sectionCode.value === props.sectionGroup.code);
            const toggleGroup = () => {
                isOpen.value = !isOpen.value
            };


            const isChildrenSection = (sectionCode) => {
                let index = props.sectionGroup.children.findIndex(d => d.code === sectionCode);
                return index > -1;
            }

            if(isChildrenSection(sectionCode.value)) {
                isOpen.value = true;
            }

            watch(sectionCode, (newSectionCode) => {
                isOpen.value = newSectionCode === props.sectionGroup.code || (isChildrenSection(newSectionCode));
            });

            const goToSection = (section) => {
                if(route.params.section === section) {
                    return
                }

                let rootUrl = '';
                if(route?.meta?.menu_url === 'brand') {
                    rootUrl = 'brand/' + route.params.code;
                }
                else {
                    rootUrl = 'catalog'
                }

                let url = '/' + rootUrl + '/section/' + section + '/';

                store.commit('catalog/setSection', section);

                router.push(url);
                isOpen.value = true;
                //sectionCode.value = section;

                emit('close-menu');
            }

            return {
                route,
                toggleGroup,
                goToSection,
                isOpen,
                sectionCode
            }
        }
    }
</script>

<style lang="scss">
    .catalog-menu__block {
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 10px;

        &.active {
            svg {
                transform: rotate(90deg);
            }
        }
    }

    .catalog-menu__top {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            transition: 200ms linear;
            width: 7px;
            height: 9px;
        }
    }

    .catalog-menu__title {
        display: flex;
        align-items: center;
        padding: 15px 18px;
        width: 100%;

        &.active {
            color: var(--orange);
        }

        span {
            margin-left: 16px;
            font-size: 16px;
        }
    }

    .catalog-menu__arrow {
        padding: 15px 18px;
    }

    .catalog-menu__body {
        margin-left: 36px;
        padding: 5px 18px;
    }

    .catalog-menu__item {
        cursor: pointer;
        margin-bottom: 12px;
        font-size: 14px;
        color: #434A54;
        transition: 200ms linear;

        &:hover, &.active {
            color: var(--orange);
        }
    }

    .catalog-menu__icon {
        width: 24px;
        height: 24px;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        &.catalog-menu__icon--yellow {
            img {
                filter: sepia(100%) contrast(170%) saturate(2);
            }
        }
    }
</style>
