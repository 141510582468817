<template>
    <div class="project-list__item"
         @click="selectProject(project.id)"
         :class="{active: selectedProject?.id === project.id}"
    >
        <span>{{ project.name }}</span>
        <div class="project-list__item_actions">
            <div @click.stop="isShowEditProjectModal = true">
                <svg data-v-32652c81="" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                     viewBox="0 0 18 18" role="presentation" fill="#D2D2D2">
                    <g data-v-32652c81="">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M16.7196 0.910826C16.3942 0.585389 15.8666 0.585389 15.5411 0.910826L14.8142 1.63779C13.8849 1.19387 12.7382 1.35676 11.9685 2.12645L3.12965 10.9653L7.8437 15.6793L16.6825 6.8405C17.4522 6.0708 17.6151 4.92408 17.1712 3.99481L17.8982 3.26785C18.2236 2.94241 18.2236 2.41477 17.8982 2.08934L16.7196 0.910826ZM13.163 8.00302L7.8437 13.3223L5.48668 10.9653L10.806 5.646L13.163 8.00302ZM14.6782 6.48779L15.504 5.66199C15.8295 5.33655 15.8295 4.80891 15.504 4.48348L14.3255 3.30496C14.0001 2.97953 13.4724 2.97953 13.147 3.30496L12.3212 4.13077L14.6782 6.48779Z"></path>
                        <path
                            d="M0.666748 18.1253L2.43484 11.6431L7.14856 16.3575L0.666748 18.1253Z"></path>
                    </g>
                </svg>

            </div>
            <div @click.stop="deleteProject(project.id)">
                <svg width="14" height="18" viewBox="0 0 14 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.5 0L10.5 1H14V3H0V1H3.5L4.5 0H9.5ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6Z"
                          fill="#D2D2D2"></path>
                </svg>
            </div>
        </div>
        <Modal
            v-show="isShowEditProjectModal"
            @close="isShowEditProjectModal = false"
            small
        >
            <template v-slot:header>
                Переименовать проект
            </template>
            <template v-slot:body>
                <div
                    class="feedback-form"
                >
                    <div class="feedback-form__group">
                        <input
                            type="text"
                            class="feedback-form__input"
                            placeholder="Введите название проекта"
                            v-model="projectName"
                        >
                    </div>
                    <div
                        class="btn btn--orange"
                        @click="renameProject(projectName)"
                    >
                        Применить
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
    import {ref, computed} from 'vue'
    import store from '@/store/store'
    import Modal from "@/components/Modal"
    import ProjectCatalogViewed from "@/components/project/ProjectCatalogViewed";
    import Breadcrumbs from "@/components/Breadcrumbs"
    import FormSelect from '@/components/form/Select'
    import {useConfirm} from '@/hooks/useConfirm';

    export default {
        components: {
            ProjectCatalogViewed,
            Modal,
            Breadcrumbs,
            FormSelect
        },
        props: {
            project: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            const projectName = ref(props.project.name);
            const confirm = useConfirm();

            const selectedProject = computed(() => store.getters['project/selectedProject']);

            const selectProject = async (id) => {
                await store.dispatch('project/selectProject', id);
                store.dispatch('catalog/getViewedItems');
                store.dispatch('project/getProjects');
            }

            const renameProject = async (name) => {
                await store.dispatch('project/renameProject', {id: props.project.id, name});
                store.dispatch('project/getProjects');
                isShowEditProjectModal.value = false;
            }

            const deleteProject = (id) => {
                confirm({
                    title: 'Удаление проекта',
                    content: 'Вы уверены, что хотите удалить этот проект?',
                    async onConfirm() {
                        await store.dispatch('project/deleteProject', id);
                        store.dispatch('project/getProjects');
                    },
                    onCancel() {
                    },
                });
            }

            const isShowEditProjectModal = ref(false);

            return {
                selectProject,
                selectedProject,
                deleteProject,
                isShowEditProjectModal,
                projectName,
                renameProject
            };
        }
    }
</script>

<style lang="scss">
.project-main {
    display: flex;
    gap: 20px;

    .fitting-main__header {
        background: none;
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 20px;
    }
}

.project-menu {
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 12px;
}

.project-list {


    .project-list__item {
        padding: 10px 18px;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 10px;
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.active {
            color: var(--orange);
        }
    }
}

.project-list__actions {
    .btn {
        padding: 15px 20px;
        min-height: 32px;
        margin-bottom: 8px;
        width: 100%;
        text-align: left;
        justify-content: flex-start;
        font-size: 14px;

        svg {
            width: 18px;
            height: 18px;
            fill: currentColor;
        }
    }
}

.project-list__item_actions {
    display: flex;
    align-items: center;
    gap: 8px;
}
</style>
