<template>
    <div
        class="catalog-sort"
    >
        <div class="catalog-sort__item">
            <span class="catalog-sort__title">Сортировать по:</span>
            <form-select
                :options="sort"
                :default="selected_sort"
                @select="setSort($event)"
            />
        </div>
    </div>
</template>

<script>
    import FormSelect from '@/components/form/Select'
    import store from '@/store/store'
    import {computed} from 'vue'
    import {useRoute, useRouter} from 'vue-router'
    import Cookies from "js-cookie";

    export default {
        components: {
            FormSelect
        },
        setup() {
            const route = useRoute();

            const sort = [
                {
                    text: 'Наличие',
                    value: 'available',
                },
                {
                    text: 'Сначала дешевые',
                    value: 'price-asc',
                },
                {
                    text: 'Сначала дорогие',
                    value: 'price-desc',
                },
            ];

            let selected_sort = {
                text: 'Наличие',
                value: 'available',
            };

            const setSort = (sort) => {
                store.commit(route.meta.store + '/setLampsParams', {
                    sort: sort.value,
                });
                store.dispatch('catalog/getProductLamps', route.params.code);
            };

            return {
                sort,
                selected_sort,
                setSort
            }
        }
    }
</script>

<style lang="scss">
    .catalog-sort__wrap {
        display: block;
    }

    .catalog-sort__title {
        display: block;
        font-size: 12px;
        line-height: 14px;
        color: #434A54;
        margin-bottom: 12px;
    }

    .catalog-products-select {
        z-index: 12;
    }
</style>
