
export default {
    setIsFetching(state, isFetching) {
        state.isFetching = isFetching
    },
    setProducts(state, products) {
        state.products = products
    },
    productsAppendTo(state, products) {
        state.products = [...state.products, ...products];
    },
    setNavigation(state, navigation) {
        state.navigation = navigation
    },
    setActiveView(state, activeView) {
        state.activeView = activeView
    },
    setCurrentPage(state, currentPage) {
        state.currentPage = currentPage
    },
    setSort(state, sort) {
        state.sort = sort
    },
    setFilters(state, filters) {
        state.filters = filters.filter(filter => filter.values.length > 0)
    },
    setActiveFilters(state, filters) {
        state.activeFilters = filters
    },
    setOpenFilters(state, filters) {
        state.openFilters = filters
    },
    setIsResetFilters(state, isResetFilters) {
        state.isResetFilters = isResetFilters
    },
    setIsAppendTo(state, isAppendTo) {
        state.isAppendTo = isAppendTo
    },
}
