<template>
    <div class="fitting">
        <!--        <back/>-->
        <breadcrumbs :breadcrumbs="breadcrumbs"/>
        <div class="project-main">
            <project-list/>
            <div class="">
                <h1 v-if="selectedProject?.id" class="fitting__title page-title">Проект {{ selectedProject.name }}</h1>
                <div class="fitting-main__header">

                    <div class="fitting-main__action">
                        <div class="fitting-main__action_row">
                            <div
                                v-if="projectItems.length > 0"
                                class="btn btn--gray"
                                @click="share"
                            >
                                <svg width="25" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                     fill="white">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M4.25 7A2.75 2.75 0 017 4.25h2.5a.75.75 0 010 1.5H7c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25V15a.75.75 0 011.5 0v2.5a2.75 2.75 0 01-2.75 2.75H7a2.75 2.75 0 01-2.75-2.75V7zm10-2a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V6.81l-6.22 6.22a.75.75 0 11-1.06-1.06l6.22-6.22H15a.75.75 0 01-.75-.75z"></path>
                                </svg>
                                <span>Поделиться проектом</span>
                            </div>

                        </div>
                        <div class="fitting-main__action_row">
                            <div
                                v-if="projectItems.length > 0"
                                class="btn btn--gray"
                                @click="savePdf"
                            >
                                <svg class="svg-icon"
                                     viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M746.7 597c-11.4-16.3-33.8-20.3-50.1-8.9L548 691.9V185.7c0-19.9-16.1-36-36-36s-36 16.1-36 36v504.7L327 587.2c-16.5-11.4-38.9-7.5-50 8.8-11.1 16.3-6.8 38.7 9.7 50.1l205.5 142.4c0.3 0.2 0.5 0.3 0.8 0.5 12.1 8.6 28.7 9.1 41.5 0.2l203.3-142.1c16.3-11.4 20.2-33.8 8.9-50.1z"/>
                                    <path
                                        d="M924 640c-19.9 0-36 16.1-36 36v189.3c0 12.6-6.7 22.7-15 22.7H151c-8.3 0-15-10.2-15-22.7V676c0-19.9-16.1-36-36-36s-36 16.1-36 36v197c0 48 39 87 87 87h722c48 0 87-39 87-87V676c0-19.9-16.1-36-36-36z"/>
                                </svg>
                                <span>Выгрузить в PDF</span>
                            </div>
                            <div
                                v-if="projectItems.length > 0"
                                class="btn btn--gray"
                                @click="saveExcel"
                            >
                                <svg class="svg-icon"
                                     viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M746.7 597c-11.4-16.3-33.8-20.3-50.1-8.9L548 691.9V185.7c0-19.9-16.1-36-36-36s-36 16.1-36 36v504.7L327 587.2c-16.5-11.4-38.9-7.5-50 8.8-11.1 16.3-6.8 38.7 9.7 50.1l205.5 142.4c0.3 0.2 0.5 0.3 0.8 0.5 12.1 8.6 28.7 9.1 41.5 0.2l203.3-142.1c16.3-11.4 20.2-33.8 8.9-50.1z"/>
                                    <path
                                        d="M924 640c-19.9 0-36 16.1-36 36v189.3c0 12.6-6.7 22.7-15 22.7H151c-8.3 0-15-10.2-15-22.7V676c0-19.9-16.1-36-36-36s-36 16.1-36 36v197c0 48 39 87 87 87h722c48 0 87-39 87-87V676c0-19.9-16.1-36-36-36z"/>
                                </svg>
                                <span>Выгрузить в Excel</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    v-show="isShareModalVisible"
                    @close="closeShareModal"
                    small
                >
                    <template v-slot:header>
                        Проект {{ '№' + shareProjectId }}
                    </template>
                    <template v-slot:body>
                        <div
                            class="feedback-form"
                        >
                            <div class="feedback-form__group">
                                <input
                                    id="url-share"
                                    type="text"
                                    class="feedback-form__input"
                                    v-model="shareLink"
                                    readonly
                                >
                            </div>
                            <div
                                class="btn"
                                @click="copyUrl"
                                :class="{'btn--border': isCopy, 'btn--orange': !isCopy}"
                            >
                                {{ isCopy ? 'Скопировано' : 'Скопировать' }}
                            </div>
                        </div>
                    </template>
                </Modal>
                <project-items v-if="projectItems.length > 0" :items="projectItems"/>
                <div v-else-if="!selectedProject" class="fitting__description">
                    Проект не выбран
                </div>
                <div v-else class="fitting__description">
                    В проекте нет товаров
                </div>
            </div>
        </div>
        <project-catalog-viewed v-if="viewedItems.length > 0" :products="viewedItems"/>
    </div>
</template>

<script>
    import Back from "@/components/Back"
    import {ref, computed, onMounted} from 'vue'
    import store from '@/store/store'
    import ProjectItems from "@/components/project/ProjectItems"
    import Modal from "@/components/Modal"
    import ProjectCatalogViewed from "@/components/project/ProjectCatalogViewed";
    import Breadcrumbs from "@/components/Breadcrumbs"
    import FormSelect from '@/components/form/Select'
    import {useConfirm} from '@/hooks/useConfirm';
    import ProjectList from "@/components/project/ProjectList";

    export default {
        components: {
            ProjectList,
            ProjectCatalogViewed,
            ProjectItems,
            Back,
            Modal,
            Breadcrumbs,
            FormSelect
        },
        setup() {
            const confirm = useConfirm();

            const breadcrumbs = ref([
                {
                    'url': '/catalog/',
                    'title': 'Каталог товаров',
                },
                {
                    'title': 'Проекты',
                }
            ]);

            const selectedProject = computed(() => store.getters['project/selectedProject']);

            onMounted(async () => {
                store.dispatch('project/getProjects');
                store.dispatch('project/getItems');
                store.dispatch('catalog/getViewedItems');
            });


            const projectItems = computed(() => {
                return store.getters['project/items']
            });

            const viewedItems = computed(() => {
                return store.getters['catalog/viewed']
            });


            const savePdf = () => {
                store.dispatch('project/getPdf');
            };

            const saveExcel = () => {
                store.dispatch('project/getExcel');
            };

            const share = async () => {
                const project = await store.dispatch('project/getLink');
                shareLink.value = project.link;
                shareProjectId.value = project.project_id;
                showShareModal()
            };

            const shareLink = ref('');
            const shareProjectId = ref('');
            const isCopy = ref(false);

            const isShareModalVisible = ref(false);

            const showShareModal = () => {
                isShareModalVisible.value = true
            }

            const closeShareModal = () => {
                isShareModalVisible.value = false
            }

            const copyUrl = () => {
                const textArea = document.createElement("textarea");
                textArea.value = shareLink.value;

                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                document.execCommand('copy');
                textArea.remove();
                isCopy.value = true;
                setTimeout(() => {
                    isCopy.value = false;
                }, 2000);
            }


            return {
                projectItems,
                viewedItems,
                savePdf,
                share,
                isShareModalVisible,
                showShareModal,
                closeShareModal,
                copyUrl,
                shareLink,
                shareProjectId,
                isCopy,
                saveExcel,
                breadcrumbs,
                selectedProject,
            };
        }
    }
</script>

<style lang="scss">

</style>
