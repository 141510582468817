<template>
    <div class="form__select"
         :tabindex="tabindex"
         v-click-outside="hide"
    >
        <div
            class="form__select_selected"
            :class="{ open: open, disabled: disabled }"
            @click="show"
        >
            {{ selected.text }}
        </div>
        <div class="form__select_wrapper"
             v-if="open"
        >
            <div
                v-if="addInput"
                class="form__select_input"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                     viewBox="0 0 12 12" role="presentation" fill="#B5B8C1">
                    <g data-v-d8dabae0="" data-v-32652c81="">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M7 1C7 0.447715 6.55228 0 6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1Z"></path>
                    </g>
                </svg>
                <label>
                    <input :placeholder="addInput" v-model="newValue" />
                </label>
                <div
                    v-if="newValue"
                    class="form__select_btn"
                    @click="addValue"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9"
                         viewBox="0 0 12 9" role="presentation" fill="#fff">
                        <g>
                            <path
                                d="M4.58578 6.41418L1.75735 3.58575L0.34314 4.99996L4.58578 9.24261L11.6568 2.17154L10.2426 0.757324L4.58578 6.41418Z"></path>
                        </g>
                    </svg>
                </div>
            </div>
            <div
                class="form__select_item"
                :class="{active: selected?.value === option?.value}"
                v-for="(option, i) of options"
                :key="i"
                @click="select(option)"
                v-html="option.text"
            >
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: {
            options: {
                type: Array,
                required: true,
            },
            default: {
                type: Object,
                required: false,
                default: () => {
                },
            },
            disabled: {
                type: Boolean,
                required: false,
                default: () => false,
            },
            tabindex: {
                type: Number,
                required: false,
                default: () => 0,
            },
            addInput: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                selected: this.default
                    ? this.default
                    : this.options.length > 0
                        ? this.options[0]
                        : null,
                open: false,
                newValue: ''
            };
        },
        watch: {
            default() {
                this.selected = JSON.parse(JSON.stringify(this.default))
            }
        },
        methods: {
            show() {
                if (!this.disabled) {
                    this.open = !this.open
                }
            },
            hide() {
                this.open = false
            },
            addValue() {
                this.selected = {
                    text: this.newValue,
                    value: this.newValue
                };
                this.open = false;
                this.$emit('add', this.selected);
                this.newValue = '';
            },
            select(option) {
                this.selected = JSON.parse(JSON.stringify(option));
                this.open = false;
                this.$emit('select', option);
            }
        },
        mounted() {
            //this.$emit("input", this.selected);
        },
    };
</script>

<style lang="scss">
    .form__select {
        position: relative;
        width: 220px;
        min-height: 34px;

        .form__select_selected {
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
            width: 100%;
            background: #ffffff;
            border: 1px solid #CBD1D9;
            border-radius: 4px;
            padding: 6px 15px 6px 10px;
            font-size: 13px;
            line-height: 16px;
            color: #434A54;
            cursor: pointer;
            min-height: 34px;

            &.open {
                border-radius: 4px 4px 0 0;

                &:after {
                    transform: rotate(-45deg);
                }
            }

            &:after {
                content: "";
                position: relative;
                right: -5px;
                width: 6px;
                height: 6px;
                border-top: 2px solid #4C4C4C;
                border-right: 2px solid #4C4C4C;
                transform: rotate(135deg);
                transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
                opacity: 0.4;
            }

            &:before {
                content: "";
                display: block;
            }

            &.disabled {
                cursor: default;

                &:after {
                    display: none;
                }
            }

            span {
                color: #95A3BA;
                margin-left: 10px;
            }

            svg {
                margin-right: 10px;
            }
        }

        .form__select_wrapper {
            position: absolute;
            left: 0;
            top: calc(100% - 1px);
            width: 100%;
            z-index: 20;
            background: #ffffff;
            border: 1px solid #CBD1D9;
            border-top-color: #ffffff;
            border-radius: 0 0 4px 4px;
            max-height: 400px;
            overflow: auto;
        }

        .form__select_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            font-size: 13px;
            line-height: 16px;
            color: #434A54;
            cursor: pointer;
            transition: 200ms linear;

            &:not(.form__select_item--search):hover, &.active {
                background: #CBD1D9;
            }
        }

        .form__select_item--disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .form__select_item--hide {
            display: none;
        }

        .form__select_item--active {
            background: #CBD1D9;
        }

        .form__select_input {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            background: #fff;
            border-radius: 4px;
            height: 32px;
            padding: 3px 10px;
            flex: 1;
            margin-right: 10px;
            margin-left: 10px;
            margin-bottom: 5px;
            border: 1px solid #CBD1D9;

            label {
                width: 100%;
                height: 100%;
                margin: 0;
            }

            input {
                border: 0;
                outline: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                box-shadow: none;
                box-sizing: border-box;
                width: 100%;
                padding-left: 11px;
                font-weight: 500;
                font-size: 13px;
                color: #868994;
            }
        }

        .form__select_btn {
            background: var(--orange);
            border-radius: 4px;
            width: 28px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

</style>
