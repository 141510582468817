<template>
    <!--<div
        v-if="sections.length > 0"
        class="catalog-sidebar__title"
    >
        Категории каталога
    </div>-->
    <div
        class="catalog-menu__block catalog-menu__block--brands"
        :class="{active: isOpen}"
    >
        <div
            class="catalog-menu__top"
        >
            <div class="catalog-menu__title">
                <span>Бренды</span>
            </div>
            <div class="catalog-menu__arrow"
                 @click="toggleGroup"
            >
                <icon-base width="10"
                           height="16"
                           fill="transparent"
                >
                    <icon-arrow-right/>
                </icon-base>
            </div>
        </div>
        <slide-up-down v-model="isOpen" :duration="300">
            <div class="catalog-menu__body">
                <div
                    v-if="activeBrand"
                    class="catalog-menu__item active"
                >
                    <div
                        class="product-link"
                    >
                        {{ activeBrand }}
                    </div>
                    <router-link to="/catalog/"
                                 class="catalog-menu__close"
                    >
                        <svg width="10" height="10" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 1L1 11M1 1L11 11" stroke="#8B8B8B" stroke-width="2"
                                  stroke-linecap="round"></path>
                        </svg>
                    </router-link>
                </div>
                <div
                    v-for="(brand, i) in brands"
                    :key="i"
                    class="catalog-menu__item"
                    :class="{active: route.params.code === brand, hide: brand === activeBrand}"
                >
                    <div
                        class="product-link"
                        @click="goToBrand(brand)"
                    >
                        {{ brand }}
                    </div>
                </div>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
    import CatalogMenuGroup from "@/components/catalog/CatalogMenuGroup"
    import {ref, computed} from 'vue'
    import IconArrowRight from "@/components/icons/IconArrowRight"
    import {useRoute, useRouter} from "vue-router";
    import store from "@/store/store";

    export default {
        components: {
            CatalogMenuGroup,
            IconArrowRight
        },
        props: {
            brands: {
                type: Array,
                required: true
            }
        },
        setup() {
            const route = useRoute();
            const router = useRouter();
            const width = window.innerWidth;
            const isOpen = ref(width >= 991);

            const activeBrand = computed(() => store.getters['catalog/brand']);

            const toggleGroup = () => {
                isOpen.value = !isOpen.value
            };

            const goToBrand = (brand) => {
                console.log('oks')
                store.commit('catalog/setBrand', brand);
                router.push('/brand/' + brand + '/');
            }

            return {
                isOpen,
                toggleGroup,
                route,
                goToBrand,
                activeBrand
            }
        }
    }
</script>

<style lang="scss">
.catalog-menu__block--brands {
    margin-bottom: 20px;

    .catalog-menu__body {
        max-height: 320px;
        overflow: auto;
    }

    .catalog-menu__title {
        span {
            margin-left: 0;
        }
    }

    .catalog-menu__body {
        margin-left: 16px;
    }

    .catalog-menu__item {
        display: flex;
        justify-content: space-between;
        gap: 12px;

        &.hide {
            display: none;
        }
    }
}
</style>
