<template>
    <div class="fitting-search">
        <div class="fitting-search__form">
            <input type="text"
                   name="search"
                   autocomplete="off"
                   placeholder="Название товара или артикул..."
                   class="fitting-search__input"
                   @input="search"
                   v-model="query"
            >
            <button class="fitting-search__btn">
                <svg width="18"
                     height="18"
                     viewBox="0 0 18 18"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.71 11H12.5L17.49 16L16 17.49L11 12.5V11.71L10.73 11.43C9.59 12.41 8.11 13 6.5 13C2.91 13 0 10.09 0 6.5C0 2.91 2.91 0 6.5 0C10.09 0 13 2.91 13 6.5C13 8.11 12.41 9.59 11.43 10.73L11.71 11ZM2 6.5C2 8.99 4.01 11 6.5 11C8.99 11 11 8.99 11 6.5C11 4.01 8.99 2 6.5 2C4.01 2 2 4.01 2 6.5Z"
                          fill="#8B8B8B"
                    ></path>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    import {ref} from 'vue'
    import store from '@/store/store'

    export default {
        components: {

        },
        setup() {
            const query = ref('');

            const search = () => {
                if(store.getters['fitting/isRecommended']) {
                    const items = store.getters['fitting/allRecommendedItems'].filter(obj => {
                        let title = obj.title.toLowerCase();

                        return title.includes(query.value.toLowerCase());
                    });

                    store.commit('fitting/setRecommendedItems', items);
                }
                else {
                    const items = store.getters['fitting/allItems'].filter(obj => {
                        let title = obj.title.toLowerCase();

                        return title.includes(query.value.toLowerCase());
                    });

                    store.commit('fitting/setItems', items);
                }
            }

            return {
                query,
                search
            };
        }
    }
</script>

<style>

</style>
