<template>
    <div class="fitting-filters">
        <radio fieldId="added"
               value="added"
               v-model:values="checkedValues"
               title="Добавленные"
               name="filter"
        />
        <radio fieldId="recommended"
               value="recommended"
               v-model:values="checkedValues"
               title="Рекомендуемые"
               name="filter"
        />
    </div>
</template>

<script>
    import {ref, watch} from 'vue'
    import store from '@/store/store'
    import {useRouter} from 'vue-router'
    import Radio from "@/components/form/Radio"

    export default {
        components: {
            Radio
        },
        setup() {
            const router = useRouter();

            const checkedValues = ref([]);
            let activeFilters = store.getters['fitting/activeFilters'];

            if (activeFilters['recommended']) {
                checkedValues.value.push('recommended');
            } else {
                checkedValues.value.push('added');
            }

            watch(checkedValues, (newCheckedValues) => {
                let newActiveFilters = [];

                newCheckedValues.forEach(function (filterName) {
                    if (filterName !== 'added')
                        newActiveFilters[filterName] = 'Y';

                    if (filterName !== 'recommended') {
                        store.commit('fitting/setIsRecommended', true);
                    }
                });

                store.commit('fitting/setIsRecommended', !!newActiveFilters['recommended']);
                delete newActiveFilters['recommended']

                store.commit('fitting/setActiveFilters', newActiveFilters);
                store.dispatch('fitting/getItems');
                //router.push({query: store.getters['fitting/query']});
            });

            return {
                checkedValues
            };
        }
    }
</script>

<style>

</style>
