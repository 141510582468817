<template>
    <div
        v-if="navigation.items_count > 0 && navigation.pages_count > 1"
        class="catalog-pagination"
    >
        <button
            v-if="parseInt(navigation.current_page) !== navigation.pages_count"
            type="button"
            class="catalog-products__more"
            @click="showMore"
        >
            Показать еще
        </button>
        <pagination
            hideFirstButton
            hideLastButton
            v-model="page"
            :range-size="2"
            :pages="navigation.pages_count"
        />
    </div>
</template>

<script>
    import store from '@/store/store'
    import {inject, computed, ref, watch} from 'vue'
    import Pagination from "@/components/pagination/Pagination"
    import {useRoute, useRouter} from 'vue-router'

    export default {
        components: {
            Pagination,
        },
        props: {
            navigation: {
                type: Object,
                required: true
            },
            isLamps: {
                type: Boolean,
                required: false
            }
        },
        setup(props) {
            const currentPage = parseInt(props.navigation.current_page);

            const page = ref(currentPage ? currentPage : 1);
            const isAppendTo = ref(false);

            const route = useRoute();
            const router = useRouter();

            watch(page, (newPage) => {
                if(!isAppendTo.value) {
                    scrollToTop();
                }

                if(props.isLamps) {
                    store.commit(route.meta.store + '/setLampsParams', {
                        isAppendTo: isAppendTo.value,
                        page: newPage,
                    });
                    store.dispatch('catalog/getProductLamps', route.params.code);
                }
                else {
                    //store.commit(route.meta.store + '/setCurrentPage', newPage);
                    router.replace({query: Object.assign({...route.query}, {page: newPage})});
                    //store.dispatch(route.meta.store + '/getProducts', isAppendTo.value);
                }
                isAppendTo.value = false;
            });

            const showMore = () => {
                page.value++;
                store.commit(route.meta.store + '/setIsAppendTo', true);
                isAppendTo.value = true;
            };

            const smoothScroll = inject('smoothScroll');

            const scrollToTop = () => {
                const el = document.querySelector('.catalog-products')

                if (el) {
                    el.scrollIntoView({behavior: 'smooth'});
                }
                /*smoothScroll({
                    scrollTo: el,
                    hash: '#sampleHash',
                    offset: -200
                })*/
            }

            return {
                page,
                showMore
            }
        },
    }
</script>

<style lang="scss">
    .catalog-products__more {
        width: 210px;
        height: 40px;
        margin: 0 auto;
        border: 1px solid #D0D5DB;
        border-radius: 4px;
        font-size: 14px;
        line-height: 17px;
        color: #434A54;
        margin-top: 40px;

        &:hover {
            color: #ffffff;
            background: #D0D5DB;
        }
    }

</style>
