<template>
    <div class="catalog">
        <div class="catalog-grid">
            <div
                class="catalog-sidebar"
            >
                <div v-if="sections && sections.length > 0" class="catalog-sidebar__title">
                    <span>Разделы</span>
                </div>
                <search-sections  v-if="sections && sections.length > 0" :sections="sections"/>
                <catalog-filters
                    v-if="!isEmptyFilters"
                    :filters="filters"
                    :activeFilter="activeFilter"
                    @closeFilter="onCloseFilter"
                />
            </div>
            <div
                class="catalog__right"
                v-if="!isFetching"
            >
                <div class="search-top">
                    <div class="search-top__title">
                        Результаты поиска
                    </div>
                    <div class="search-top__count">
                        Найдено товаров: {{ itemsCount }}
                    </div>
                </div>
                <!--<search-queries :queries="queries" @changeQuery="onChangeQuery"/>-->
                <catalog-top v-if="products.length > 0" :sorts="sorts" :selected-sort="selectedSort"/>
                <div class="catalog-products-filters">
                    <div
                        @click="activeFilter = !activeFilter"
                        class="btn btn--orange"
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.4495 1H2.55054C1.90061 1 1.52192 1.73396 1.89857 2.26362L5.8 7.75V13L8.2 11.875V7.75L12.1014 2.26362C12.4781 1.73396 12.0994 1 11.4495 1Z" stroke="white" stroke-width="1.5" stroke-linejoin="round"/>
                        </svg>
                        <span>
                            Все фильтры
                        </span>
                    </div>
                    <div class="btn btn--border">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1L1 9M1 1L9 9" stroke="#8B8B8B" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        <span>
                            Сбросить фильтры
                        </span>
                    </div>
                </div>
                <catalog-products v-if="products.length > 0" :products="products"/>
                <catalog-pagination v-if="products.length > 0" :navigation="navigation"/>
            </div>
            <div
                v-else
                class="catalog__preloader"
            >
                <preloader/>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchSections from "@/components/search/SearchSections"
    import CatalogTop from "@/components/catalog/CatalogTop"
    import CatalogProducts from "@/components/catalog/CatalogProducts"
    import CatalogPagination from "@/components/catalog/CatalogPagination"
    import {useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
    import store from '@/store/store'
    import {computed, onMounted, watch, ref, onBeforeMount} from 'vue'
    import CatalogFilters from "@/components/catalog/CatalogFilters"
    import SearchQueries from "@/components/search/SearchQueries"

    export default {
        components: {
            SearchQueries,
            CatalogFilters,
            CatalogPagination,
            CatalogProducts,
            CatalogTop,
            SearchSections
        },
        setup() {
            const route = useRoute();
            const router = useRouter();

            onBeforeMount(() => {
                if(!route.query.q) {
                    router.push('/catalog/');
                }
            });

            const isFetching = computed(() => {
                return store.getters['search/isFetching']
            });

            const banners = ref([]);

            const fetchCatalog = async (newRoute) => {
                store.commit('search/setIsFetching', true);

                let activeFilters = {};
                Object.keys(newRoute.query).map((key, index) => {
                    let val = newRoute.query[key]

                    if (key === 'q') {
                        store.commit('search/setQuery', val);
                    }
                    else if (key === 'page') {
                        store.commit('search/setCurrentPage', val);
                    }
                    else if(key === 'sort') {
                        store.commit('search/setSort', val);
                    }
                    else {
                        activeFilters[key] = val
                    }
                });

                store.commit('search/setActiveFilters', activeFilters);
                if(!newRoute.query['page']) {
                    store.commit('catalog/setCurrentPage', 1);
                }
                await store.dispatch('search/getProducts');

                store.commit('search/setIsFetching', false);
            }

            const onChangeQuery = () => {
                setTimeout(() => fetchCatalog(), 10)
            }

            onBeforeRouteUpdate((to, from) => {
                store.commit('search/setIsFetching', true);
                fetchCatalog(to);
            })

            onMounted(() => {
                fetchCatalog(route);
            });

            watch(() => route.query.q, () => {
                //setTimeout(() => fetchCatalog(), 10)
            });

            const sections = computed(() => {
                return store.getters['search/sections']
            });
            const products = computed(() => {
                return store.getters['search/products']
            });
            const filters = computed(() => {
                return store.getters['search/filters']
            });
            const navigation = computed(() => {
                return store.getters['search/navigation']
            });
            const queries = computed(() => {
                return store.getters['search/queries']
            });
            const itemsCount = computed(() => {
                return store.getters['search/itemsCount']
            });

            const isEmptyFilters = ref(true);
            watch(filters, () => {
                isEmptyFilters.value = true;
                for(let code in filters.value) {
                    if(filters.value[code].items && filters.value[code].items.length > 0) {
                        isEmptyFilters.value = false;
                        break;
                    }
                }
            });

            const activeFilter = ref(false);

            watch(activeFilter, () => {
                if(window.innerWidth < 991) {
                    if(activeFilter.value) {
                        document.documentElement.classList.add('overflow-y-hidden');
                    }
                    else {
                        document.documentElement.classList.remove('overflow-y-hidden');
                    }
                }

            });

            const onCloseFilter = () => {
                activeFilter.value = false;
            }

            const sorts = [
                {
                    text: 'Популярность',
                    value: 'popular',
                },
                {
                    text: 'Сначала дешевые',
                    value: 'price-asc',
                },
                {
                    text: 'Сначала дорогие',
                    value: 'price-desc',
                },
            ];

            let selectedSort = computed(() => {
                let index = sorts.findIndex(d => d.value === store.getters['search/sort']);

                if(index > -1) {
                    return sorts[index];
                }
                else {
                    return sorts[0]
                }
            });

            return {
                sections,
                products,
                filters,
                isEmptyFilters,
                isFetching,
                navigation,
                banners,
                queries,
                onChangeQuery,
                itemsCount,
                activeFilter,
                onCloseFilter,
                sorts,
                selectedSort
            };
        }
    }
</script>

<style lang="scss">
    .search-top {
        border-bottom: 1px solid #d2d2d278;
        margin-bottom: 20px;
    }

    .search-top__title {
        font-weight: bold;
        font-size: 28px;
        color: #434A54;
        margin-bottom: 16px;
    }

    .search-top__count {
        font-size: 12px;
        text-transform: uppercase;
        color: #8B8B8B;
        margin-bottom: 20px;
    }
</style>
