<template>
    <div class="search-sections">
        <div
            class="search-sections__item"
            :class="{active: !activeSection}"
            @click="goToSection(null)"
        >
            <div class="search-sections__title">
                Все разделы
            </div>
            <div class="search-sections__count">
                {{ totalCount }}
            </div>
        </div>
        <div
            v-for="section in sections"
            :key="section.id"
            class="search-sections__item"
            :class="{active: activeSection === section.id}"
            @click="goToSection(section.id)"
        >
            <div class="search-sections__title">
                {{ section.title }}
            </div>
            <div class="search-sections__count">
                {{ section.count }}
            </div>
        </div>
    </div>
</template>

<script>
    import {useRouter} from 'vue-router'
    import {computed} from "vue";
    import store from "@/store/store";

    export default {
        components: {},
        props: {
            sections: {
                type: Array,
                required: true
            }
        },
        setup(props) {
            const router = useRouter();
            const totalCount = props.sections.reduce((n, {count}) => n + count, 0);

            const urlParams = new URLSearchParams(window.location.search);
            if(urlParams.has('section')) {
                store.commit('search/setActiveSection', urlParams.get('section'))
            }

            const goToSection = async (id) => {
                store.commit('search/setActiveSection', id)
                let url = `/search/?q=${urlQuery.value}`;
                if(id !== null) {
                    url += `&section=${id}`;
                }
                await router.push(url);
            }

            const activeSection = computed(() => {
                return store.getters['search/activeSection']
            });

            const urlQuery = computed(() => {
                return store.getters['search/q']
            });

            return {
                goToSection,
                urlQuery,
                activeSection,
                totalCount
            }
        }
    }
</script>

<style lang="scss">
    .catalog {
        .search-sections {
            background: #FFFFFF;
            border-radius: 4px;
            margin-bottom: 16px;
            padding: 20px;
        }

        .search-sections__item {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                .search-sections__title {
                    color: var(--orange);
                }
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            &.active {
                .search-sections__title {
                    color: var(--orange);
                }
            }
        }

        .search-sections__title {
            font-size: 14px;
            color: #8B8B8B;
            transition: 200ms linear;
        }

        .search-sections__count {
            font-size: 12px;
            color: #434A54;
            margin-left: 10px;
        }
    }

</style>
