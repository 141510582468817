export default {
    items: state => state.items,
    recommendedItems: state => state.recommendedItems,
    allItems: state => state.allItems,
    allRecommendedItems: state => state.allRecommendedItems,
    backgrounds: state => state.backgrounds,
    selectedBackground: state => state.selectedBackground,
    kitItems: state => state.kitItems,
    viewItems: state => state.viewItems,
    activeFilters: state => state.activeFilters,
    sections: state => state.sections,
    activeSections: state => state.activeSections,
    query: (state, getters) => {
        let values = {};

        Object.keys(getters.activeFilters).map((key) => {
            if (getters.activeFilters[key]) {
                values[key] = getters.activeFilters[key];
            }
        });
        if(getters['activeSections'].length > 0) {
            values['sections'] = getters['activeSections'].join(',');
        }

        return values
    },
    priceSum: (state, getters) => {
        let price = 0;
        getters.kitItems.forEach(function (item) {
            price += parseFloat(item.discount_price) * item.count;
        })

        return price
    },
    backgroundWidth: state => state.backgroundWidth,
    isRecommended: state => state.isRecommended,
    rooms: state => state.rooms,
    selectedRoom: state => state.selectedRoom,
}
