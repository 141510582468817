<template>
    <div class="form__select form__select--multiple"
         :tabindex="tabindex"
         v-click-outside="hide"
    >
        <div
            class="form__select_selected"
            :class="{ open: open, disabled: disabled }"
            @click="show"
        >
            <div class="form__select_tags">
                <div v-for="option in selected" class="form__select_tag" :key="option.value">
                    <span>{{ option.text }}</span>
                    <svg @click.stop="remove(option)" viewBox="64 64 896 896" focusable="false" data-icon="close"
                         width="1em"
                         height="1em"
                         fill="currentColor" aria-hidden="true">
                        <path
                            d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                    </svg>
                </div>
                <span v-if="selected.length === 0 && placeholder" class="form__select_placeholder">
                    {{ placeholder }}
                </span>
            </div>
        </div>
        <div class="form__select_wrapper"
             v-if="open"
        >
            <div
                class="form__select_item form__select_item--search"
            >
                <input type="text"
                       @keyup="search"
                       v-model="query"
                       ref="input_search"
                >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M11.71 11H12.5L17.49 16L16 17.49L11 12.5V11.71L10.73 11.43C9.59 12.41 8.11 13 6.5 13C2.91 13 0 10.09 0 6.5C0 2.91 2.91 0 6.5 0C10.09 0 13 2.91 13 6.5C13 8.11 12.41 9.59 11.43 10.73L11.71 11ZM2 6.5C2 8.99 4.01 11 6.5 11C8.99 11 11 8.99 11 6.5C11 4.01 8.99 2 6.5 2C4.01 2 2 4.01 2 6.5Z"
                          fill="#8B8B8B"></path>
                </svg>
            </div>
            <div
                class="form__select_item"
                :class="{
                    'form__select_item--disabled': option.disabled,
                    'form__select_item--hide': !showDisabled && option.disabled,
                    'form__select_item--active': selected.find(item => item.value === option.value)
                }"
                v-for="(option, i) of options"
                :key="i"
                @click="select(option)"
            >
                <span>{{ option.text }}</span>
                <svg v-if="selected.find(item => item.value === option.value)" @click.stop="remove(option)"
                     viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em"
                     height="1em"
                     fill="currentColor" aria-hidden="true">
                    <path
                        d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                </svg>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        props: {
            values: {
                type: Array,
                required: true,
            },
            default: {
                type: Array,
                required: false,
                default: () => {
                },
            },
            disabled: {
                type: Boolean,
                required: false,
                default: () => false,
            },
            tabindex: {
                type: Number,
                required: false,
                default: () => 0,
            },
            addInput: {
                type: String,
                required: false
            },
            placeholder: {
                type: String,
                required: false
            },
            showDisabled: {
                type: Boolean,
                required: false
            },
        },
        data() {
            return {
                selected: [],
                open: !!this.addInput,
                newValue: '',
                query: '',
                options: []
            };
        },
        watch: {
            default() {
                //console.log('WATCH default', this.default)
                //this.selected = this.default
            }
        },
        methods: {
            show() {
                if (!this.disabled) {
                    this.open = !this.open
                    setTimeout(() => {
                        if(this.$refs['input_search']) {
                            this.$refs['input_search'].focus()
                        }
                    }, 100)
                }
            },
            hide() {
                this.open = false
            },
            addValue() {
                this.selected = {
                    text: this.newValue,
                    value: this.newValue
                };
                this.open = false;
                this.$emit('add', this.selected);
                this.newValue = '';
            },
            select(option) {
                if (!option.disabled) {
                    if (!this.selected.find(item => item.value === option.value)) {
                        this.selected.push(option);
                        this.$emit('select', option.value);
                    }

                    this.open = false;
                }
            },
            remove(option) {
                if (this.selected.find(item => item.value === option.value)) {
                    this.selected = this.selected.filter(function (item) {
                        return item.value !== option.value;
                    })
                    this.$emit('remove', option.value);
                }
            },
            search() {
                this.options = this.values.filter(obj => {
                    let title = obj.text.toLowerCase();

                    return title.includes(this.query.toLowerCase());
                });
            }
        },
        created() {
            this.options = this.values;
            if (this.default.length > 0) {
                this.default.map((item) => {
                    const option = this.options.find(option => option.value === item);

                    if (option && option.text) {
                        this.selected.push({
                            text: option.text,
                            value: option.value,
                        })
                    }
                })
            }
        },
    };
</script>

<style lang="scss">
.form__select.form__select--multiple {
    .form__select_selected {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: space-between;
        min-height: 34px;

        &:before {
            display: none;
        }

        span {
            color: #434A54;
            margin-left: 0;
        }

        svg {
            margin-right: 0;
            width: 10px;
            height: 10px;
        }

        .form__select_placeholder {
            color: #95A3BA;
        }
    }

    .form__select_item--search {
        border: 1px solid #CBD1D9;
        margin: 3px;
        padding: 0 5px;
        border-radius: 4px;

        input {
            width: 100%;
            height: 28px;
        }

        svg {
            margin: 5px 0;
            cursor: auto;
        }
    }

    .form__select_tags {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        width: calc(100% - 10px);
    }

    .form__select_tag {
        border: 1px solid #ababab;
        border-radius: 4px;
        background: #f6f6f6;
        padding: 2px 8px;
        display: flex;
        align-items: baseline;
        gap: 5px;
    }
}

</style>
