import { createWebHistory, createRouter } from "vue-router";
import Catalog from "@/views/catalog"
import CatalogDetail from "@/views/catalog/detail"
import Search from "@/views/search"
import Fitting from "@/views/fitting"
import Catalogs from "@/views/catalogs"
import Project from "@/views/project"
import Brand from "@/views/brand"

const routes = [
    {
        path: '/',
        redirect: {name: 'catalog'}
    },
    {
        path: "/catalog/",
        name: "catalog",
        component: Catalog,
        meta: {
            store: 'catalog'
        },
        children: [
            /*{
                path: '/catalog/:sectionParent/',
                meta: {
                    store: 'catalog'
                },
                component: Catalog
            },
            {
                path: '/catalog/:sectionParent/:section/',
                meta: {
                    store: 'catalog'
                },
                component: Catalog
            }*/
            {
                path: '/catalog/section/:code/',
                name: "catalogSection",
                meta: {
                    store: 'catalog'
                },
                component: Catalog,
            },
        ]
    },
    /*{
        path: '/catalog/section/:code/',
        name: "catalogSection",
        meta: {
            store: 'catalog'
        },
        component: Catalog,
    },*/
    {
        path: '/catalog/detail/:code/',
        name: "catalogDetail",
        meta: {
            store: 'catalog'
        },
        component: CatalogDetail,
    },
    /*{
        path: '/catalog/:sectionParent/:section/:code/',
        name: "catalogDetail",
        meta: {
            store: 'catalog'
        },
        component: CatalogDetail,
    },*/
    {
        path: "/search/",
        name: "search",
        meta: {
            store: 'search'
        },
        component: Search,
    },
    {
        path: '/fitting/',
        name: "fitting",
        component: Fitting,
    },
    {
        path: "/catalogs/",
        name: "catalogs",
        meta: {
            store: 'catalogs'
        },
        component: Catalogs,
    },
    {
        path: '/project/',
        name: "project",
        component: Project,
    },
    {
        path: "/brand/",
        redirect: to => {
            return { path: '/catalog/'}
        },
    },
    {
        path: '/brand/:code/',
        name: "brand",
        meta: {
            store: 'catalog',
            menu_url: 'brand'
        },
        component: Brand,
        children: [
            {
                path: '/brand/:code/section/:sectionCode/',
                name: "brandSection",
                meta: {
                    store: 'catalog',
                    menu_url: 'brand'
                },
                component: Brand,
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
