<template>
    <div
        class="catalog-filters"
        :class="{active: activeFilter}"
    >
        <div
            class="catalog-sidebar__title"
        >
            <span>Фильтры</span>
            <button
                @click="closeFilter"
                class="catalog-filters__close"
            >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 1L1 11M1 1L11 11" stroke="#434A54" stroke-width="2" stroke-linecap="round"></path>
                </svg>
            </button>
        </div>
        <div class="catalog-filters-group">
            <div>
                <catalog-filters-item
                    v-for="filter in filters"
                    :key="filter.id + '_' + route.path"
                    :filter="filter"
                />
            </div>
        </div>
        <div class="catalog-filters__footer">
            <div
                @click="resetFilter"
                class="btn btn--border"
            >
                Сбросить фильтр
            </div>
            <div
                @click="closeFilter"
                class="btn btn--orange"
            >
                Показать товары
            </div>
        </div>
    </div>
</template>

<script>
    import CatalogFiltersItem from "@/components/catalog/CatalogFiltersItem"
    import {useRoute, useRouter} from 'vue-router'
    import store from '@/store/store'

    export default {
        components: {
            CatalogFiltersItem
        },
        props: {
            filters: {
                type: Array,
                required: true
            },
            activeFilter: {
                type: Boolean,
                required: true
            }
        },
        setup(props, { emit }) {
            const route = useRoute();
            const router = useRouter();

            const closeFilter = () => {
                emit("closeFilter")
            }

            const resetFilter = async () => {
                store.commit(route.meta.store + '/setActiveFilters', {});
                store.commit(route.meta.store + '/setIsFetching', true);
                await store.dispatch(route.meta.store + '/getProducts');
                store.commit(route.meta.store + '/setIsFetching', false);
                await router.push({ query: store.getters[route.meta.store + '/query']});
                //document.querySelectorAll('.catalog-filters input[type=checkbox]').forEach(el => el.checked = false);
            }

            return {
                route,
                closeFilter,
                resetFilter
            }
        }

    }
</script>

<style lang="scss">
    .catalog-filters-group {
        margin-bottom: 20px;
    }

    .catalog-filters-group__merge {
        border-radius: 4px;
        background: #ffffff;
        padding: 17px 0;

        .catalog-filters__block {
            background: unset;
            margin-bottom: 0;
            border-radius: 0;
        }

        .catalog-filters__body {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    .catalog-filters-group__title {
        font-size: 14px;
        text-transform: uppercase;
        color: #8B8B8B;
        margin-bottom: 12px;
    }
</style>
