
export default {
    setItems(state, items) {
        state.items = items
    },
    setRecommendedItems(state, recommendedItems) {
        state.recommendedItems = recommendedItems
    },
    setAllItems(state, items) {
        state.allItems = items
    },
    setAllRecommendedItems(state, items) {
        state.allRecommendedItems = items
    },
    setBackgrounds(state, backgrounds) {
        state.backgrounds = backgrounds
    },
    setSelectedBackground(state, selectedBackground) {
        state.selectedBackground = selectedBackground
    },
    setActiveFilters(state, filters) {
        state.activeFilters = filters
    },
    setActiveSections(state, sections) {
        state.activeSections = sections
    },
    setSections(state, sections) {
        state.sections = sections
    },
    removeItem(state, itemId) {
        state.items = state.items.filter(function(el) { return el.id !== itemId; })
    },
    setSetsQuantity(state, context) {
        let index = state.items.findIndex(d => d.id === context.id);

        state.items[index].count = context.count
    },
    addBackground(state, image) {
        state.backgrounds.unshift(image)
        state.selectedBackground = image
    },
    addToKit(state, item) {
        let index = state.kitItems.findIndex(d => d.id === item.id);

        if(index > -1) {
            state.kitItems[index].count += item.measure_ratio;
        }
        else {
            item.count = item.measure_ratio;
            state.kitItems.push(item);
        }
        let viewItem = JSON.parse(JSON.stringify(item));

        viewItem.view_id = state.viewItems.length + 1;
        for(let i = 1; i <= item.measure_ratio; i++) {
            state.viewItems.push(viewItem);
        }

    },
    removeFromKit(state, itemId) {
        state.kitItems = state.kitItems.filter(function(el) { return el.id !== itemId; })
        state.viewItems = state.viewItems.filter(function(el) { return el.id !== itemId; })
    },
    removeAllItems(state) {
        state.items = []
        state.kitItems = []
        state.viewItems = []
    },
    removeFromView(state, viewId) {
        let indexView = state.viewItems.findIndex(d => d.view_id === viewId);
        const itemId = state.viewItems[indexView].id;

        state.viewItems = state.viewItems.filter(function(el) { return el.view_id !== viewId; })

        let indexKit = state.kitItems.findIndex(d => d.id === itemId);
        if(indexKit > -1) {
            let count = state.kitItems[indexKit].count;

            count -= state.kitItems[indexKit].measure_ratio;

            if(count < 1) {
                state.kitItems = state.kitItems.filter(function(el, i) { return i !== indexKit; })
            }
            else {
                state.kitItems[indexKit].count = count;
            }
        }
    },
    updateItem(state, context) {
        let index = state.kitItems.findIndex(d => d.id === context.id);

        if(index > -1) {
            state.kitItems[index].count = context.count;
        }

        let viewItems = state.viewItems.filter(function(el) { return el.id === context.id; });
        let lastViewItem = viewItems[viewItems.length - 1];

        if(viewItems.length > context.count) {
            state.viewItems = state.viewItems.filter(function(el) { return el.view_id !== lastViewItem.view_id; })
        }
        else {
            let viewItem = JSON.parse(JSON.stringify(state.kitItems[index]));

            viewItem.view_id = state.viewItems.length + 1;
            for(let i = 1; i <= state.kitItems[index].measure_ratio; i++) {
                state.viewItems.push(viewItem);
            }
        }
    },
    setBackgroundWidth(state, width) {
        state.backgroundWidth = width
    },
    setIsRecommended(state, isRecommended) {
        state.isRecommended = isRecommended
    },
    updateViewItem(state, context) {
        let index = state.viewItems.findIndex(d => d.view_id === context.view_id);

        if(index > -1) {
            for(let i in context.params) {
                state.viewItems[index][i] = context.params[i]
            }
        }
    },
    addRoom(state, room) {
        state.rooms.push(room)
    },
    setSelectedRoom(state, room) {
        state.selectedRoom = room
    },
}
