<template>
    <div class="set-item">
        <div class="set-item__image">
            <img :src="getWebUrl() + item.image" alt="">
        </div>
        <div class="set-item__title">
            {{ item.title }}
        </div>
        <div class="set-item__price">
            {{ item.discount_price_formatted }}
        </div>
        <div class="set-item__quantity">
            <div class="product-quantity">
                <input
                    type="text"
                    :value="parseInt(item.count)"
                    @keypress="isNumber($event)"
                    @change="setQuantity(setQnt($event.target.value, item.measure_ratio))"
                >
                <button
                    class="product-quantity__btn plus"
                    @click="setQuantity(setQnt(parseInt(item.count) + item.measure_ratio, item.measure_ratio))"
                >
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 5H9M5 9L5 1" stroke="#788496" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
                <button
                    class="product-quantity__btn minus"
                    @click="setQuantity(setQnt(parseInt(item.count) - item.measure_ratio, item.measure_ratio))"
                >
                    <svg width="10" height="2" viewBox="0 0 10 2" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 1H9" stroke="#788496" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
        <div class="set-item__action">
            <svg @click="remove" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.5 0L10.5 1H14V3H0V1H3.5L4.5 0H9.5ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6Z"
                      fill="#D2D2D2"
                />
            </svg>
        </div>
    </div>
</template>

<script>
    import {ref, computed, onMounted} from 'vue'
    import store from '@/store/store'

    export default {
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            const remove = () => {
                store.commit('fitting/removeFromKit', props.item.id);
            }

            const setQuantity = (qnt) => {
                if (qnt < 1 || qnt === props.item.count) return false

                store.commit('fitting/updateItem', {id: props.item.id, count: parseInt(qnt)});
            }

            return {
                remove,
                setQuantity
            };
        }
    }
</script>

<style>

</style>
