<template>
    <div
        class="catalog-sections"
    >
        <Carousel
            class="catalog-sections__wrapper"
            :itemsToShow="itemsToShow"
            snapAlign="start"
            :touchDrag="false"
            :mouseDrag="false"
            :modelValue="activeSectionIndex"
        >
            <slide
                v-for="section in sections"
                :key="section.id"
                :class="{active: section.active}"
            >
                <div
                    class="catalog-sections__item"
                    @click="goToSection(section.url)"
                >
                    <div class="catalog-sections__image">
                        <img :src="getWebUrl() + section.image">
                    </div>
                    <div class="catalog-sections__title">
                        {{ section.title }}
                    </div>
                    <!--                    <div class="catalog-sections__count">
                                            {{ section.items_count.toLocaleString() }} {{ getDecl(section.items_count, ['товар', 'товара',
                                            'товаров']) }}
                                        </div>-->
                </div>
            </slide>
            <template #addons>
                <Navigation/>
            </template>
        </Carousel>
    </div>
</template>

<script>
    //import 'vue-snap/dist/vue-snap.css'
    //import {Carousel, Slide} from 'vue-snap'
    import {useRouter} from 'vue-router'
    import {onMounted, onUnmounted, ref} from 'vue'
    import store from "@/store/store";
    import {Carousel, Navigation, Slide} from 'vue3-carousel'
    import 'vue3-carousel/dist/carousel.css'

    export default {
        components: {
            Carousel,
            Slide,
            Navigation
        },
        props: {
            sections: {
                type: Array,
                required: true
            }
        },
        setup(props) {
            const router = useRouter();
            const displayItemsCount = ref(5);
            const activeSectionIndex = ref(props.sections.findIndex(item => item.active));
            const itemsToShow = ref(5);

            const goToSection = async (sectionUrl) => {
                await router.push(sectionUrl);
                const params = Object.fromEntries(new URLSearchParams(window.location.search));

                store.commit('catalog/setActiveFilters', params);
            }

            onMounted(() => {
                /*function getDomIndex(target) {
                    return [].slice.call(target.parentNode.children).indexOf(target)
                }

                const vsWrapper = document.querySelector('.catalog-sections__wrapper .vs-carousel__wrapper');
                const activeSection = document.querySelector('.catalog-sections__slide.active');
                if (activeSection) {
                    const index = getDomIndex(activeSection);
                    console.log('activeSection', activeSection)
                    vsWrapper.scrollBy({left: activeSection.clientWidth * index, behavior: 'smooth'})
                }*/

                window.addEventListener("resize", onResize);
            });

            onUnmounted(() => {
                window.removeEventListener("resize", onResize);
            });

            const onResize = () => {
                const width = window.innerWidth;

                if(width <= 450) {
                    itemsToShow.value = 2;
                    return;
                }
                if(width <= 574) {
                    itemsToShow.value = 3;
                    return;
                }
                if(width <= 768) {
                    itemsToShow.value = 4;
                    return;
                }
                if(width <= 991) {
                    itemsToShow.value = 5;
                    return;
                }
                if (width <= 1100) {
                    itemsToShow.value = 4;
                    return;
                }

                itemsToShow.value = 5;
            }

            onResize();

            return {
                router,
                goToSection,
                displayItemsCount,
                activeSectionIndex,
                itemsToShow
            }
        }
    }
</script>

<style lang="scss">
.catalog-sections {
    padding: 0 10px;
    margin: 0 -10px;
}

.catalog-sections__wrapper {
    margin-bottom: 12px;

    .catalog-sections__slide {
        flex: 0 0 50%;
        margin-right: 10px;
        user-select: none;

        &.active {
            .catalog-sections__title {
                color: var(--orange);
            }
        }
    }

    .carousel__slide {
        &.active {
            .catalog-sections__title {
                color: var(--orange);
            }
        }
    }

    @media(min-width: 500px) {
        .catalog-sections__slide {
            flex: 0 0 33%
        }
    }

    @media(min-width: 768px) {
        .catalog-sections__slide {
            flex: 0 0 20%
        }
    }

    @media(min-width: 991px) {
        .catalog-sections__slide {
            flex: 0 0 22%
        }
    }

    @media(min-width: 1100px) {
        .catalog-sections__slide {
            flex: 0 0 18%
        }
    }
}

.carousel__slide {
    padding: 0 5px;
}

.catalog-sections__item {
    height: 100%;
    width: 100%;
    padding: 24px;
    background: #FFFFFF;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.catalog-sections__title {
    margin-top: 16px;
    font-size: 16px;
    min-height: 50px;
}

.catalog-sections__count {
    margin-top: 10px;
    font-size: 13px;
    color: #8B8B8B;
}

.catalog-sections__image {
    width: 100px;
    height: 100px;
    margin: 0 auto;

    img {
        object-fit: cover;
    }
}

.catalog-sections {

}
</style>
