export default {
    items: state => state.items,
    projects: state => state.projects,
    isModalVisible: state => state.isModalVisible,
    item2project: state => state.item2project,
    selectedProject: (state, getters) => {
        return getters.projects.find(item => item.selected)
    },
    priceSum: (state, getters) => {
        let price = 0;

        getters.items.forEach(function (item) {
            price += parseFloat(item.discount_price) * item.count;
        })

        return price
    },
    checkedItems: (state, getters) => {
        let checkedItems = [];

        getters.items.forEach(function (item) {
            if(item.is_checked) {
                checkedItems.push(item)
            }
        })

        return checkedItems
    },
}
