import {useRoute} from "vue-router";

export default {
	isFetching: state => state.isFetching,
	sections: state => state.sections,
	products: state => state.products,
	navigation: state => state.navigation,
	activeView: state => state.activeView,
	detailProduct: state => state.detailProduct,
	currentPage: state => state.currentPage,
	sectionParent: state => state.sectionParent,
	section: state => state.section,
	sort: state => state.sort,
	filters: state => state.filters,
	activeFilters: state => state.activeFilters,
	query: (state, getters) => {
		let values = {};
		
		if (getters.currentPage > 1) {
			values['page'] = getters.currentPage;
		}
		if (getters.sort) {
			values['sort'] = getters.sort;
		}
		Object.keys(getters.activeFilters).map((key) => {
			if (getters.activeFilters[key]) {
				values[key] = getters.activeFilters[key];
			}
		});
		
		return values
	},
	sectionsCarousel: state => state.sectionsCarousel,
	openFilters: state => state.openFilters,
	viewed: state => state.viewed,
	productLamps: state => state.productLamps,
	navigationLamps: state => state.navigationLamps,
	isResetFilters: state => state.isResetFilters,
	isAppendTo: state => state.isAppendTo,
	lampsParams: state => state.lampsParams,
	typesLamps: state => state.typesLamps,
	brandsLamps: state => state.brandsLamps,
	brand: state => state.brand,
	brands: state => {
		/*const route = useRoute();
		if (route?.params?.code) {
			let index = state.brands.indexOf(route?.params?.code);
			
			if (index !== -1) {
				let selectedBrand = state.brands.splice(index, 1)[0];
				state.brands.unshift(selectedBrand);
			}
		}*/
		
		return state.brands
	},
	mainFilters: (state, getters) => {
		const commFilters = state.filters.find(item => item.code === 'COMM');
		
		let mainFilters = [];
		if (commFilters) {
			for (const filter of commFilters.items) {
				if (filter.code === 'COMM_BRAND' || filter.code === 'COMM_COLLECTION') {
					if (filter?.values.length > 0) {
						let item = {
							'name': filter.name,
							'code': filter.code,
							'values': filter?.values.map((value) => {
								return {
									text: value.name,
									value: value.control_id,
									disabled: value.disabled,
								}
							}),
						}
						
						if (filter.code === 'COMM_BRAND') {
							item.placeholder = 'Выберите бренд';
							item.show_disabled = true;
						}
						if (filter.code === 'COMM_COLLECTION') {
							item.placeholder = 'Выберите коллекцию';
						}
						
						mainFilters.push(item)
					}
				}
			}
		}
		
		
		return mainFilters
	},
}
