<template>
    <div
        class="fitting-view"
        id="fitting"
    >
        <div class="fitting-view__wrapper">
            <div class="fitting-view__actions">
                <div
                    @click="setFullScreen"
                    class="fitting-view__btn"
                >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 17L6.00005 12M1 17L1.00014 13M1 17L5.00008 17" stroke="#8B8B8B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17 1.00001L11.9999 6M17 1.00001L16.9999 5M17 1.00001L12.9999 1" stroke="#8B8B8B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div
                    v-if="items.length > 0"
                    @click="savePdf"
                    class="fitting-view__btn"
                >
                    <div
                        v-if="isSavingPdf"
                        class="loader-btn"
                    ></div>
                    <div v-else class="fitting-view__btn_inner">
                        PDF
                    </div>
                </div>
                <div
                    v-if="items.length > 0"
                    @click="saveExcel"
                    class="fitting-view__btn"
                >
                    <div
                        v-if="isSavingExcel"
                        class="loader-btn"
                    ></div>
                    <div v-else class="fitting-view__btn_inner">
                        Excel
                    </div>
                </div>
            </div>
            <div
                id="fitting_view"
                class="fitting-view__inner"
                @mouseover="onResizeStartCallback"
                @touchstart="onResizeStartCallback"
            >
                <img :src="getWebUrl() + selectedBackground" alt="" class="fitting-view__bg">
                <fitting-drag-item
                    v-for="item in items"
                    :key="item.view_id"
                    :item="item"
                    :isFullScreen="isFullScreen"
                    :lockRatio="lockRatio"
                />
            </div>
            <fitting-backgrounds/>
        </div>
    </div>
</template>

<script>
    import Back from "@/components/Back"
    import FittingDragItem from "@/components/fitting/FittingDragItem"
    import FittingBackgrounds from "@/components/fitting/FittingBackgrounds"
    import {ref, computed, onMounted} from 'vue'
    import store from '@/store/store'
    import html2canvas from 'html2canvas';

    export default {
        components: {
            FittingBackgrounds,
            FittingDragItem,
            Back,
        },
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        setup() {
            const backgrounds = computed(() => {
                return store.getters['fitting/backgrounds']
            });

            const selectedBackground = computed(() => {
                return store.getters['fitting/selectedBackground']
            });

            const isFullScreen = ref(false);
            const setFullScreen = () => {
                if(document.fullscreenElement) {
                    document.exitFullscreen();
                    isFullScreen.value = false;
                }
                else {
                    const fitting = document.getElementById('fitting');

                    if (fitting.webkitRequestFullscreen) fitting.webkitRequestFullscreen();
                    else if (fitting.mozRequestFullScreen) fitting.mozRequestFullScreen();
                    else if (fitting.msRequestFullscreen) fitting.msRequestFullscreen();
                    else if (fitting.requestFullscreen) fitting.requestFullscreen();

                    isFullScreen.value = true;
                }

            };

            const isSavingPdf = ref(false);
            const savePdf = () => {
                isSavingPdf.value = true;
                setTimeout(() => {
                    html2canvas(document.querySelector("#fitting_view"))
                        .then(async canvas => {
                            let url = canvas.toDataURL("image/png");

                            await store.dispatch('fitting/getPdf', url);
                            isSavingPdf.value = false;
                        })
                        .catch(error => console.log('Ошибка: ', error));
                }, 100)

            };

            const isSavingExcel = ref(false);
            const saveExcel = () => {
                isSavingExcel.value = true;
                setTimeout(() => {
                    html2canvas(document.querySelector("#fitting_view"))
                        .then(async canvas => {
                            let url = canvas.toDataURL("image/png");

                            await store.dispatch('fitting/getExcel', url);
                            isSavingExcel.value = false;
                        })
                        .catch(error => console.log('Ошибка: ', error));
                }, 100)

            };

            const lockRatio = ref(false);

            const onResizeStartCallback = (event) => {
                const cls = event.target.classList;

                if (!cls.contains('handle')) return;

                lockRatio.value = (cls.contains('handle-tl') ||
                    cls.contains('handle-tr') ||
                    cls.contains('handle-bl') ||
                    cls.contains('handle-br')
                );
            };

            return {
                backgrounds,
                selectedBackground,
                setFullScreen,
                isFullScreen,
                savePdf,
                isSavingPdf,
                lockRatio,
                onResizeStartCallback,
                isSavingExcel,
                saveExcel
            };
        }
    }
</script>

<style>

</style>
