<template>
    <div class="fitting-set">
        <div class="fitting-set__title">
            Комплект на примерке
        </div>
        <div class="fitting-set__wrapper">
            <fitting-set-item
                v-for="item in items"
                :key="item.id"
                :item="item"
            />
            <div class="set-footer">
                <div class="set-footer__info">
                    <div class="set-footer__title">
                        Стоимость комплекта
                    </div>
                    <div class="set-footer__price">
                        <span>{{ priceSum.toLocaleString('ru-RU') }} руб.</span>
                        <Popper
                            hover
                            closeDelay="100"
                            arrow
                        >
                            <span class="price-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                     width="24" height="24"
                                     viewBox="0 0 24 24"
                                >
                                    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path>
                                </svg>
                            </span>
                            <template #content>
                                <div class="price-icon__text">
                                    Указана розничная цена. Цену с учетом вашей индивидуальной скидки можно увидеть в корзине, после добавления товара.
                                </div>
                            </template>
                        </Popper>
                    </div>
                </div>
                <div
                    class="btn btn--orange"
                    @click="buySet"
                >
                    Добавить товары в корзину
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from 'vue'
    import store from '@/store/store'
    import FittingSetItem from "@/components/fitting/FittingSetItem"

    export default {
        components: {FittingSetItem},
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        setup(props) {
            const priceSum = computed(() => {
                return store.getters['fitting/priceSum']
            });

            const buySet = () => {
                let items = [];
                props.items.forEach(function (item) {
                    items.push({
                        vendor_code: item.vendor_code,
                        raec_id: item.raec_id,
                        quantity: item.count,
                    })
                })

                window.parent.postMessage({
                    type: 'DsAdd2Basket',
                    items: items,
                }, '*');
            };

            return {
                priceSum,
                buySet
            };
        }
    }
</script>

<style>

</style>
