<template>
    <article class="product catalogs-product"
             :class="{'product--row': activeView === 'row'}"
    >
        <div class="catalogs-product__body">
            <div
                v-if="product.image"
                class="catalogs-product__image"
            >
                <img :src="getWebUrl() + product.image">
            </div>
            <div class="catalogs-product__info">
                <div class="catalogs-product__title">
                    {{ product.title }}
                </div>
                <div class="catalogs-product__subtitle">
                    {{ product.brand }}
                </div>
            </div>
        </div>
        <a :href="product.pdf_url" download class="btn btn--orange">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 8L8 11M8 11L11 8M8 11L8 1M1 8V15H15V8" stroke="#FFFFFF" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            <span>Скачать</span>
        </a>
    </article>
</template>

<script>
import {computed} from 'vue'
import store from '@/store/store'
import {useRoute} from 'vue-router'

export default {
    components: {},
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const route = useRoute();

        const activeView = computed(() => {
            return store.getters[route.meta.store + '/activeView']
        });

        return {
            activeView,
        }
    }
}
</script>

<style lang="scss">

</style>
