<template>
    <vue-draggable-resizable
        :x="xPixel"
        :y="yPixel"
        :w="width"
        :h="height"
        :resizable="true"
        :lock-aspect-ratio="lockRatio"
        class-name="drag-item"
        drag-handle=".drag-item__wrap"
        @dragging="(left, top) => onDragging(left, top)"
        @resizing="(left, top, newWidth, newHeight) => onResizing(newWidth, newHeight)"
    >
        <div class="drag-item__wrap" :class="'item-' + item.view_id">
            <div
                class="drag-item__image"
                :style="{transform: 'rotate(' + rotateDeg + 'deg)'}"
                :class="{flip: isFlip}"
            >
                <img :src="getWebUrl() + (item.image_fitting ? item.image_fitting.src : item.image)">
            </div>
        </div>
        <div class="drag-item-actions">
            <div class="drag-item-actions__row">
                <!--<div
                    class="drag-item-actions__btn"
                    @click="sizeDec"
                >
                    <svg width="12"
                         height="2"
                         viewBox="0 0 12 2"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 1H11"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div
                    class="drag-item-actions__btn"
                    @click="sizeInc"
                >
                    <svg width="12"
                         height="12"
                         viewBox="0 0 12 12"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 6H11M6 11L6 1"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        />
                    </svg>
                </div>-->
            </div>
            <div class="drag-item-actions__row">
                <div
                    class="drag-item-actions__btn"
                    @click="rotateLeft"
                >
                    <svg width="20"
                         height="20"
                         viewBox="0 0 20 20"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M5.00002 10C5.00002 8.54927 5.52566 7.14765 6.47961 6.05465C7.43357 4.96166 8.75123 4.25131 10.1886 4.05513C11.6261 3.85894 13.0859 4.19021 14.2979 4.9876"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                        />
                        <path d="M4.99999 10C4.99999 8.54927 5.52563 7.14765 6.47958 6.05465C7.43354 4.96166 8.7512 4.25131 10.1886 4.05513C11.626 3.85894 13.0859 4.19021 14.2978 4.9876C15.5098 5.78498 16.3918 6.99448 16.7806 8.39217C17.1693 9.78985 17.0385 11.2811 16.4123 12.5897C15.7862 13.8984 14.7071 14.9358 13.3748 15.51"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-dasharray="1 8"
                        />
                        <path d="M2 9L5 12L8 9"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div
                    class="drag-item-actions__btn"
                    @click="rotateRight"
                >
                    <svg width="20"
                         height="20"
                         viewBox="0 0 20 20"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M15 10C15 8.54927 14.4743 7.14765 13.5204 6.05465C12.5664 4.96166 11.2488 4.25131 9.81135 4.05513C8.37393 3.85894 6.91411 4.19021 5.70215 4.9876"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                        />
                        <path d="M15 10C15 8.54927 14.4744 7.14765 13.5204 6.05465C12.5665 4.96166 11.2488 4.25131 9.81138 4.05513C8.37396 3.85894 6.91414 4.19021 5.70218 4.9876C4.49022 5.78498 3.60821 6.99448 3.21945 8.39217C2.83069 9.78985 2.9615 11.2811 3.58767 12.5897C4.21383 13.8984 5.29295 14.9358 6.62522 15.51"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-dasharray="1 8"
                        />
                        <path d="M18 9L15 12L12 9"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>
            <div class="drag-item-actions__row">
                <div
                    class="drag-item-actions__btn"
                    @click="isFlip = !isFlip"
                >
                    <svg width="18"
                         height="18"
                         viewBox="0 0 18 18"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M9 1L9 17"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                        />
                        <path d="M9 3H1V15H9"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        />
                        <path d="M12 3H17V15H12"
                              stroke="#8B8B8B"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-dasharray="1 4"
                        />
                    </svg>
                </div>
                <div
                    class="drag-item-actions__btn"
                    @click="remove"
                >
                    <svg width="12"
                         height="16"
                         viewBox="0 0 12 16"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.08366 0.5L8.91699 1.33333H11.8337V3H0.166992V1.33333H3.08366L3.91699 0.5H8.08366ZM1.00033 13.8333C1.00033 14.75 1.75033 15.5 2.66699 15.5H9.33366C10.2503 15.5 11.0003 14.75 11.0003 13.8333V3.83333H1.00033V13.8333ZM2.66699 5.5H9.33366V13.8333H2.66699V5.5Z"
                              fill="#8B8B8B"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </vue-draggable-resizable>
</template>

<script>
    import {ref, watch, onMounted, onUnmounted, computed} from 'vue'
    import store from '@/store/store'

    export default {
        props: {
            item: {
                type: Object,
                required: true
            },
            isFullScreen: {
                type: Boolean,
                required: true
            },
            lockRatio: {
                type: Boolean,
                required: true
            },
        },
        setup(props) {
            const setViewItemParams = () => {
                store.commit('fitting/updateViewItem', {
                    view_id: props.item.view_id,
                    params: {
                        left: xPixel.value,
                        top: yPixel.value,
                        width: width.value,
                        height: height.value,
                        rotate: rotateDeg.value,
                        is_flip: isFlip.value,
                        kef: kef,
                    }
                })
            }

            const defaultWidth = 730;

            const backgroundWidth = computed(() => {
                return store.getters['fitting/backgroundWidth']
            });

            let fittingWrapper = document.querySelector('.fitting-view__wrapper');

            let fittingWidth = 0;
            if(backgroundWidth.value > 0) {
                fittingWidth = backgroundWidth.value;
            }
            else {
                fittingWidth = fittingWrapper.offsetWidth;
                store.commit('fitting/setBackgroundWidth', fittingWidth);
            }

            const kef = fittingWidth / defaultWidth,
                ratio = Math.min(150 / props.item.image_fitting.width, 150 / props.item.image_fitting.height),
                ratioWidth = props.item.image_fitting.width * ratio,
                ratioHeight = props.item.image_fitting.height * ratio,
                width = ref((props.item.width ? props.item.width : ratioWidth) * kef),
                height = ref((props.item.height ? props.item.height : ratioHeight) * kef),
                xPixel = ref((props.item.left ? props.item.left : 0) * kef),
                yPixel = ref((props.item.top ? props.item.top : 0) * kef),
                rotateDeg = ref(props.item.rotate ? props.item.rotate : 0),
                isFlip = ref(props.item.is_flip ? props.item.is_flip : false);

            const sizeInc = () => {
                width.value += 10;
                height.value += 10;
                setViewItemParams()
            }
            const sizeDec = () => {
                if (width.value > 10) {
                    width.value -= 10;
                    height.value -= 10;

                    setViewItemParams()
                }
            }
            const rotateLeft = () => {
                rotateDeg.value -= 5;
                setViewItemParams()
            }
            const rotateRight = () => {
                rotateDeg.value += 5;
                setViewItemParams()
            }

            const remove = () => {
                store.commit('fitting/removeFromView', props.item.view_id);
            }

            const onDragging = (left, top) => {
                xPixel.value = left;
                yPixel.value = top;

                setViewItemParams()
            }

            const onResizing = (newWidth, newHeight) => {
                width.value = newWidth;
                height.value = newHeight;

                setViewItemParams()
            }

            onMounted(() => {
                window.addEventListener("resize", onResize);
                setViewItemParams();
            });

            onUnmounted(() => {
                window.removeEventListener("resize", onResize);
            });

            const onResize = () => {
                if(!fittingWrapper) {
                    fittingWrapper = document.querySelector('.fitting-view__wrapper');
                }

                const currentFittingWidth = fittingWrapper.offsetWidth,
                    kef = currentFittingWidth / fittingWidth;


                let newWidth = ((width.value * kef) > 10) ? (width.value * kef) : (10);
                let newHeight = ((height.value * kef) > 10) ? (height.value * kef) : (10);

                width.value = newWidth;
                height.value = newHeight;
                xPixel.value = xPixel.value * kef;
                yPixel.value = yPixel.value * kef;


                fittingWidth = currentFittingWidth;
                store.commit('fitting/setBackgroundWidth', fittingWidth);
                setViewItemParams()
            }

            return {
                width,
                height,
                xPixel,
                yPixel,
                sizeInc,
                sizeDec,
                rotateDeg,
                rotateLeft,
                rotateRight,
                isFlip,
                remove,
                onDragging,
                onResizing

            };
        }
    }
</script>

<style>

</style>
