<template>
    <div
        v-if="!filter.hasOwnProperty('related_to') || activeFilters[filter.related_to]"
        class="catalog-filters__block"
        :class="{active: isOpen, checked: isChecked, hide: isHidden}"
    >
        <div
            v-if="!filter.hide_title"
            class="catalog-filters__top"
            @click="toggleGroup"
        >
            <div
                class="catalog-filters__arrow"
            >
                <icon-base width="10"
                           height="16"
                           fill="transparent"
                >
                    <icon-arrow-right/>
                </icon-base>
            </div>
            <div
                class="catalog-filters__title"
            >
                <span>{{ filter.name }}</span>
            </div>
        </div>
        <slide-up-down v-model="isOpen" :duration="300">
            <div
                class="catalog-filters__body"
            >
                <div class="catalog-filters__search"
                     v-if="filter.display_type === 'F' && (filterValues.length > 7 || query !== '')"
                >
                    <input type="text"
                           name="search"
                           autocomplete="off"
                           placeholder="Я ищу..."
                           class="fitting-search__input"
                           @input="search"
                           v-model="query"
                    >
                    <button class="catalog-filters__search_btn">
                        <svg width="18"
                             height="18"
                             viewBox="0 0 18 18"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.71 11H12.5L17.49 16L16 17.49L11 12.5V11.71L10.73 11.43C9.59 12.41 8.11 13 6.5 13C2.91 13 0 10.09 0 6.5C0 2.91 2.91 0 6.5 0C10.09 0 13 2.91 13 6.5C13 8.11 12.41 9.59 11.43 10.73L11.71 11ZM2 6.5C2 8.99 4.01 11 6.5 11C8.99 11 11 8.99 11 6.5C11 4.01 8.99 2 6.5 2C4.01 2 2 4.01 2 6.5Z"
                                  fill="#8B8B8B"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div class="catalog-filters__inner">
                    <aggregation-checkbox
                        v-if="filter.display_type === 'F'"
                        :aggregations="filterValues"
                        :key="filter.checked ? 1 : 0"
                        :isCustom="!!filter.custom"
                        :title="filter?.values.length > 0 && filter.hide_title && filter?.values[0]?.name === 'Да' ? filter.name : ''"
                        :search-query="query"
                    />
                    <aggregation-radio
                        v-if="filter.display_type === 'K'"
                        :aggregations="filterValues"
                        :key="filter.checked ? 1 : 0"
                    />
                    <aggregation-range
                        v-if="filter.display_type === 'A' && filterValues[0] && filterValues[1]"
                        :min="filterValues[0]"
                        :max="filterValues[1]"
                        :changed="!!filter.checked"
                    />
                    <aggregation-range
                        v-if="filter.display_type === 'B'"
                        :min="filterValues[0]"
                        :max="filterValues[1]"
                        :changed="!!filter.checked"
                        hide-slider
                    />
                </div>
            </div>
        </slide-up-down>
    </div>
</template>

<script>
    import {ref, computed} from 'vue'
    import IconArrowRight from "@/components/icons/IconArrowRight"
    import {useRoute} from 'vue-router'
    import AggregationCheckbox from "@/components/catalog/aggregation/AggregationCheckbox"
    import AggregationRadio from "@/components/catalog/aggregation/AggregationRadio"
    import AggregationRange from "@/components/catalog/aggregation/AggregationRange"
    import store from '@/store/store'

    export default {
        components: {
            AggregationRange,
            AggregationCheckbox,
            AggregationRadio,
            IconArrowRight
        },
        props: {
            filter: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            const hiddenValues = ref(Object.assign(props.filter.values));
            const route = useRoute();
            const isOpen = ref(false);
            const query = ref('');
            if(props.filter.checked || props.filter.hide_title|| props.filter.open) {
                isOpen.value = true;
            }

            const filterValues = computed(() => {
                return Object.assign(props.filter.values);
            });

            const isChecked = computed(() => {
                return props.filter.checked
            });
            const toggleGroup = () => {
                isOpen.value = !isOpen.value
            };

            const activeFilters = computed(() => {
                return store.getters[route.meta.store + '/activeFilters']
            });

            const isHidden = ref(
                (props.filter.code === 'COMM_CATALOG' && window.axios.defaults.headers.common['partner'] !== '') ||
                (props.filter.code === 'COMM_CATEGORYLD' && window.axios.defaults.headers.common['partner'] !== '')
            );

            const search = () => {
                /*filterValues.value = props.filter.values.filter(obj => {
                    let name = obj.name.toLowerCase();

                    return name.includes(query.value.toLowerCase());
                });*/
                console.log('query', query.value)
            }

            return {
                route,
                toggleGroup,
                isHidden,
                isOpen,
                isChecked,
                activeFilters,
                search,
                filterValues,
                query
            }
        }
    }
</script>

<style lang="scss">
    .catalog-filters__block {
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 10px;

        &.active {
            svg {
                transform: rotate(90deg);
            }
        }

        &.checked {
            .catalog-filters__title {
                span {
                    color: var(--orange);
                }
            }
        }

        &.hide {
            display: none;
        }
    }

    .catalog-filters__top {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 18px;

        svg {
            transition: 200ms linear;
            width: 7px;
            height: 9px;
        }
    }

    .catalog-filters__title {
        display: flex;
        align-items: center;
        width: 100%;

        &.active {
            color: var(--orange);
        }

        span {
            margin-left: 16px;
            font-size: 16px;
            color: #1F2226;
        }
    }

    .catalog-filters__body {
        padding: 0 8px;
        padding-bottom: 20px;
        padding-top: 17px;
    }

    .catalog-filters__inner {
        max-height: 250px;
        padding: 5px 10px;
        overflow: auto;
    }

    .catalog-filters__item {
        cursor: pointer;
        margin-bottom: 12px;
        font-size: 14px;
        color: #434A54;
        transition: 200ms linear;

        &:hover, &.active {
            color: var(--orange);
        }
    }

    .catalog-filters__icon {
        width: 24px;
        height: 24px;
    }
</style>

