<template>
    <g>
        <path d="M2 8H12" :stroke="stroke" stroke-linecap="round"/>
        <path d="M2 8L6 5" :stroke="stroke" stroke-linecap="round"/>
        <path d="M2 8L6 11" :stroke="stroke" stroke-linecap="round"/>
    </g>
</template>

<script>
    export default {
        props: {
            stroke: {
                type: String,
            }
        }
    }
</script>
