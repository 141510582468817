<template>
    <div
        class="catalog-products"
        v-if="products.length > 0"
    >
        <div class="catalog-products__grid"
             :class="{'catalog-products-row': activeView === 'row', 'columns-4': isLamps}"
        >
            <catalog-products-card
                v-for="product in products"
                :key="product.id"
                :product="product"
            />
        </div>
    </div>
</template>

<script>
    import CatalogProductsCard from "./CatalogProductsCard"
    import store from '@/store/store'
    import {computed} from 'vue'
    import {useRoute} from 'vue-router'

    export default {
        components: {
            CatalogProductsCard
        },
        props: {
            products: {
                type: Array,
                required: true
            },
            isLamps: {
                type: Boolean,
                required: false
            }
        },
        setup() {
            const route = useRoute();

            const activeView = computed(() => {
                return store.getters[route.meta.store + '/activeView']
            });

            return {
                activeView
            };
        }
    }
</script>

<style lang="scss">
    .catalog-products {
        margin-top: 15px;
    }

</style>
