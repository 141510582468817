<template>
    <div class="catalog-share">
        <div class="catalog__right_top">
            <div class="catalog__right_title">
                {{ title }}
            </div>
            <div
                class="btn btn--orange"
                @click="showShareModal"
            >
                <svg width="25"
                     height="24"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24"
                     fill="white"
                >
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.25 7A2.75 2.75 0 017 4.25h2.5a.75.75 0 010 1.5H7c-.69 0-1.25.56-1.25 1.25v10.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25V15a.75.75 0 011.5 0v2.5a2.75 2.75 0 01-2.75 2.75H7a2.75 2.75 0 01-2.75-2.75V7zm10-2a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V6.81l-6.22 6.22a.75.75 0 11-1.06-1.06l6.22-6.22H15a.75.75 0 01-.75-.75z"/>
                </svg>
                <span>Поделиться</span>
            </div>
        </div>
        <Modal
            v-show="isShareModalVisible"
            @close="closeShareModal"
            medium
        >
            <template v-slot:header>
                Поделиться страницей
            </template>
            <template v-slot:body>
                <div
                    class="share-form"
                >
                    <div class="share-form__group">
                        <input
                            type="text"
                            class="share-form__input"
                            v-model="url"
                            readonly="readonly"
                            id="url-share"
                        >
                    </div>
                    <div
                        class="btn btn--orange"
                        :class="{active: isUrlCopied}"
                        @click="copyUrl"
                    >
                        <span>{{ isUrlCopied ? 'Ссылка скопирована' : 'Скопировать ссылку' }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19"
                             viewBox="0 0 15 19" fill="#FFFFFF">
                            <g>
                                <path d="M8.83333 5.83325H3.83333V4.16658H8.83333V5.83325Z"></path>
                                <path d="M8.83333 9.16658H3.83333V7.49992H8.83333V9.16658Z"></path>
                                <path d="M3.83333 12.4999H8.83333V10.8333H3.83333V12.4999Z"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M0.5 15.8333V0.833252H12.1667V4.16658H15.5V19.1666H3.83333V15.8333H0.5ZM10.5 14.1666V2.49992H2.16667V14.1666H10.5ZM12.1667 5.83325V15.8333H5.5V17.4999H13.8333V5.83325H12.1667Z"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            </template>
        </Modal>
    </div>

</template>

<script>
    import {computed, ref} from 'vue'
    import Modal from "@/components/Modal"
    import {useRoute} from "vue-router";

    export default {
        components: {
            Modal
        },
        props: {
            title: {
                type: String,
                required: true
            },
        },
        setup() {
            const route = useRoute();
            const isShareModalVisible = ref(false);
            const showShareModal = () => {
                isShareModalVisible.value = true
            }
            const closeShareModal = () => {
                isShareModalVisible.value = false
            }

            const url = computed(() => {
                let url = '';
                if (window.axios.defaults.headers.common['partner']) {
                    const partnerUrl = new URL(window.axios.defaults.headers.common['partner']);
                    partnerUrl.searchParams.append('dsconf', '1');
                    partnerUrl.searchParams.append('item', 'url-' + route.fullPath);

                    url = partnerUrl.href;
                }

                return url;
            });

            const isUrlCopied = ref(false);
            const copyUrl = () => {
                let urlToCopy = document.querySelector('#url-share');
                urlToCopy.select();
                document.execCommand('copy');
                isUrlCopied.value = true;
                setTimeout(() => {
                    isUrlCopied.value = false
                }, 3000)
            }

            return {
                isShareModalVisible,
                showShareModal,
                closeShareModal,
                url,
                copyUrl,
                isUrlCopied,
            }
        }
    }
</script>

<style lang="scss">

</style>
