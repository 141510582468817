<template>
    <div class="card-lamps">
        <div class="card-lamps__actions">
            <catalog-lamps-sort/>
            <catalog-lamps-filters/>
        </div>

        <catalog-products v-if="lamps.length > 0" :products="lamps" isLamps/>
        <div
            v-else
            class="card-lamps__description"
        >
            Нет подходящих ламп
        </div>
        <catalog-pagination v-if="lamps.length > 0" :navigation="navigation" isLamps/>

    </div>

</template>

<script>
    import CatalogProducts from "@/components/catalog/CatalogProducts"
    import CatalogPagination from "@/components/catalog/CatalogPagination"
    import PopperAvailability from "@/components/PopperAvailability"
    import CatalogLampsSort from "@/components/catalog/detail/CatalogLampsSort";
    import CatalogLampsFilters from "@/components/catalog/detail/CatalogLampsFilters";

    export default {
        components: {
            CatalogLampsFilters,
            CatalogLampsSort,
            CatalogProducts,
            CatalogPagination,
            PopperAvailability,
        },
        props: {
            lamps: {
                type: Array,
                required: true
            },
            navigation: {
                type: Object,
                required: true
            },
        },
        setup(props) {
            return {

            }
        },
    }
</script>

<style>
    .card-lamps__actions {
        display: flex;
    }

    .card-lamps__description {
        font-size: 18px;
        margin-top: 16px;
    }
</style>
