<template>
    <div class="search-query">
        <div class="search-query__title">
            Дополнить поиск:
        </div>
        <div
            v-for="query in queries"
            :key="query"
            class="search-query__item"
            @click="setQuery(query)"
        >
            {{ query }}
        </div>
    </div>
</template>

<script>
    import {useRouter, useRoute} from 'vue-router'
    import store from '@/store/store'

    export default {
        components: {},
        props: {
            queries: {
                type: Array,
                required: true
            }
        },
        setup(props, { emit }) {
            const router = useRouter();
            const route = useRoute();

            const setQuery = (q) => {
                let query = route.query.q;
                router.push('/search/?q=' + query + ' ' + q);
                store.commit('search/setIsResetFilters', true);
                emit("changeQuery")
            }

            return {
                setQuery
            }
        }
    }
</script>

<style lang="scss">
    .catalog {
        .search-query {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }

        .search-query__title {
            font-size: 14px;
            color: #8B8B8B;
        }

        .search-query__item {
            cursor: pointer;
            margin-left: 10px;
            font-size: 14px;
            color: #1F2226;
            border-bottom: 1px dashed #1F2226;
            transition: all 200ms linear;

            &:hover {
                color: var(--orange);
                border-color: var(--orange);
            }
        }
    }

</style>
