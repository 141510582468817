<template>
    <div
        class="fitting-backgrounds"
        :class="{active: backgroundsPanel}"
    >
        <div class="fitting-backgrounds__wrapper">
            <div
                class="fitting-backgrounds__btn"
                @click="backgroundsPanel = !backgroundsPanel"
            >
                <svg width="16"
                     height="10"
                     viewBox="0 0 16 10"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                >
                    <path opacity="0.4"
                          d="M1 9L8 2L15 9"
                          stroke="#4C4C4C"
                          stroke-width="2"
                          stroke-linecap="round"
                    />
                </svg>

            </div>
            <div class="fitting-backgrounds__inner">
                <div class="fitting-backgrounds__head">
                    <div class="fitting-backgrounds__title">
                        Помещение:
                    </div>
                    <div class="fitting-backgrounds__select">
                        <form-select
                            :options="rooms"
                            :default="selectedRoom"
                            @select="setRoom($event)"
                            add-input="Создать помещение"
                            @add="addRoom($event)"
                        />
                    </div>
                    <div class="fitting-backgrounds__button">
                        <div class="fitting-backgrounds__button_title">
                            <svg width="10"
                                 height="10"
                                 viewBox="0 0 10 10"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 5H9M5 9L5 1"
                                      stroke="#8B8B8B"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                />
                            </svg>
                            Загрузить фото
                        </div>
                        <vue-base64-file-upload
                            accept="image/png,image/jpeg"
                            :max-size="3"
                            @size-exceeded="onSizeExceeded"
                            :disable-preview="true"
                            placeholder=""
                            @load="uploadBackgroundImage"
                        />
                    </div>

                </div>
                <div
                    class="fitting-backgrounds__items"
                    v-dragscroll
                    @dragscrollmove="scrolling"
                    @dragscrollend="stopScrolling"
                >
                    <div
                        v-for="(background, i) in backgrounds"
                        :key="i"
                        class="fitting-backgrounds__item"
                        :style="{background: 'url(' + background + ')'}"
                        @click="setBackground(background)"
                    >

                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import Back from "@/components/Back"
    import FormSelect from '@/components/form/Select'
    import {ref, computed, onMounted} from 'vue'
    import FittingDragItem from "@/components/fitting/FittingDragItem"
    import store from '@/store/store'
    import VueBase64FileUpload from 'vue-base64-file-upload'

    export default {
        components: {
            FittingDragItem,
            Back,
            FormSelect,
            VueBase64FileUpload
        },
        setup() {
            const rooms = store.getters['fitting/rooms'];

            const selectedRoom = computed(() => {
                return store.getters['fitting/selectedRoom']
            });

            //const selectedRoom = store.getters['fitting/selectedRoom'];

            const setRoom = (room) => {
                store.commit('fitting/setSelectedRoom', room);
            };

            const addRoom = (room) => {
                store.commit('fitting/addRoom', room);
                store.commit('fitting/setSelectedRoom', room);
            };

            const backgroundsPanel = ref(false);

            onMounted(async () => {
                await store.dispatch('fitting/getBackgrounds');

                if(!store.getters['fitting/selectedBackground'] && store.getters['fitting/backgrounds'][0]) {
                    store.commit('fitting/setSelectedBackground', store.getters['fitting/backgrounds'][0]);
                }
            });

            const backgrounds = computed(() => {
                return store.getters['fitting/backgrounds']
            });

            const dragging = ref(false);

            const scrolling = () => {
                setTimeout(() => dragging.value = true)
            };
            const stopScrolling = () => {
                setTimeout(() => dragging.value = false, 100)
            };

            const setBackground = (background) => {
                if (!dragging.value) {
                    store.commit('fitting/setSelectedBackground', background);
                }
            };

            const uploadBackgroundImage = (dataUri) => {
                store.dispatch('fitting/addBackground', dataUri);
            };

            const onSizeExceeded = (size) => {
                console.log('size', size)
            };

            return {
                rooms,
                selectedRoom,
                setRoom,
                backgroundsPanel,
                backgrounds,
                setBackground,
                dragging,
                scrolling,
                stopScrolling,
                uploadBackgroundImage,
                onSizeExceeded,
                addRoom
            };
        },
    }
</script>

<style>

</style>
