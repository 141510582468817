import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import Cookies from "js-cookie";

const getDefaultState = () => {
    return {
        isFetching: false,
        sections: [],
        products: [],
        query: null,
        navigation: {},
        activeView: 'table',
        currentPage: 1,
        filters: {},
        activeFilters: {},
        sort: Cookies.get('sort') ? Cookies.get('sort') : null,
        openFilters: [],
        queries: [],
        isResetFilters: false,
        itemsCount: 0,
        isAppendTo: false,
        activeSection: null,
    }
};

const state = getDefaultState();

export default {
    defaultState: getDefaultState(),
    state,
    mutations,
    getters,
    actions
};
