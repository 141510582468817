<template>
    <div v-if="product.id">
<!--        <back/>-->
        <breadcrumbs :breadcrumbs="breadcrumbs"/>
        <catalog-card :key="product.id" :product="product" :lamps="lamps" :navigation="navigation"/>
        <catalog-similar-products-carousel
            v-if="product.similar_products.length > 0"
            :products="product.similar_products"
            :title="'Похожие товары'"
        />
        <catalog-similar-products-carousel
            v-if="product.collection_products.length > 0"
            :products="product.collection_products"
            :title="'Товары этой коллекции'"
        />
    </div>
</template>

<script>
    import Back from '@/components/Back'
    import CatalogCard from '@/components/catalog/detail/CatalogCard'
    import {useRoute} from 'vue-router'
    import store from '@/store/store'
    import {computed, onMounted, watch, ref} from 'vue'
    import CatalogSimilarProductsCarousel from "@/components/catalog/detail/CatalogSimilarProductsCarousel"
    import Breadcrumbs from "@/components/Breadcrumbs"


    export default {
        components: {
            Breadcrumbs,
            CatalogSimilarProductsCarousel,
            Back,
            CatalogCard
        },
        setup() {
            const route = useRoute();
            const code = route.params.code;

            const breadcrumbs = ref([]);

            onMounted(async () => {
                await store.dispatch('catalog/getDetailProduct', code);
                if(store.getters['catalog/sections'].length === 0) {
                    await store.dispatch('catalog/getSections');
                }

                breadcrumbs.value = [
                    {
                        'url': '/catalog/',
                        'title': 'Каталог товаров',
                    }
                ];

                if (store.getters['catalog/detailProduct'].section_code) {
                    for (let i in store.getters['catalog/sections']) {
                        let section = store.getters['catalog/sections'][i];
                        if (section.code === store.getters['catalog/detailProduct'].section_code) {
                            breadcrumbs.value.push({
                                'url': '/catalog/section/' + section.code + '/',
                                'title': section.title,
                            });
                            break;
                        }

                        for (let i2 in section.children) {
                            let childrenSection = section.children[i2];

                            if (childrenSection.code === store.getters['catalog/detailProduct'].section_code) {
                                breadcrumbs.value.push({
                                    'url': '/catalog/section/' + section.code + '/',
                                    'title': section.title,
                                });

                                breadcrumbs.value.push({
                                    'url': '/catalog/section/' + childrenSection.code + '/',
                                    'title': childrenSection.title,
                                });

                                break;
                            }
                        }
                    }
                }

                breadcrumbs.value.push({
                    'url': '/catalog/' + route.params.sectionParent + '/' + route.params.section + '/' + code + '/',
                    'title': store.getters['catalog/detailProduct'].title,
                });

                await store.dispatch('catalog/getProductLamps', code);
            });

            const product = computed(() => {
                return store.getters['catalog/detailProduct']
            });
            const lamps = computed(() => {
                return store.getters['catalog/productLamps']
            });
            const navigation = computed(() => {
                return store.getters['catalog/navigationLamps']
            });

            watch(() => route.fullPath, async () => {
                if(route.name === 'catalogDetail') {
                    await store.dispatch('catalog/getDetailProduct', route.params.code);
                    await store.dispatch('catalog/getProductLamps', {code: route.params.code});
                }
            });

            return {
                product,
                lamps,
                navigation,
                breadcrumbs
            }
        }
    }
</script>

<style>

</style>
