import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const getDefaultState = () => {
    return {
        isFetching: false,
        products: [],
        navigation: {},
        activeView: 'table',
        currentPage: 1,
        filters: [],
        activeFilters: {},
        sort: null,
        openFilters: [],
        isResetFilters: false,
        isAppendTo: false,
    }
};

const state = getDefaultState();

export default {
    defaultState: getDefaultState(),
    state,
    mutations,
    getters,
    actions
};
