import axios from 'axios'

export default {
    getItems({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/fitting/items/',
            params = {};

        if(getters['isRecommended']) {
            url = process.env.VUE_APP_API_URL + '/fitting/recommended/';
        }

        if (Object.keys(getters['activeFilters']).length) {
            for (let filterName in getters['activeFilters']) {
                params[filterName] = getters['activeFilters'][filterName];
            }
        }

        /*if (getters['activeSections'].length > 0) {
            params['sections'] = getters['activeSections'].join(',');
        }*/

        return axios.get(url, {params: params})
            .then(response => {
                if (response.data.data.length > 0) {
                    if(getters['isRecommended']) {
                        commit('setRecommendedItems', response.data.data);
                        commit('setAllRecommendedItems', response.data.data);
                    }
                    else {
                        commit('setItems', response.data.data);
                        commit('setAllItems', response.data.data);
                    }

                    commit('setSections', []);
                    commit('setActiveSections', []);

                    let url = process.env.VUE_APP_API_URL + '/catalog/sections/';

                    return axios.get(url, {params: {all: 'Y'}})
                        .then(response => {
                            if(response.data.data.length > 0) {
                                let sections = response.data.data;

                                let items = [];
                                if(getters['isRecommended']) {
                                    items = getters['recommendedItems']
                                }
                                else {
                                    items = getters['items']
                                }

                                let newSections = [];
                                for (let i in sections) {
                                    let section = sections[i];

                                    if(items.find(item => item.section_id === section.id)) {
                                        let index = newSections.findIndex(d => d.id === section.id);

                                        if(index < 0) {
                                            let newSection = Object.assign({}, section);
                                            newSection.children = [];

                                            newSections.push(newSection);
                                        }
                                    }

                                    if(section.children && section.children.length > 0) {
                                        for(let i2 in section.children) {
                                            let childrenSection = section.children[i2];

                                            if(items.find(item => item.section_id === childrenSection.id)) {
                                                let index = newSections.findIndex(d => d.id === section.id);

                                                if(index < 0) {
                                                    let newSection = Object.assign({}, section);
                                                    newSection.children = [];

                                                    index = newSections.push(newSection) - 1;
                                                }

                                                newSections[index].children.push(childrenSection);
                                            }
                                        }
                                    }
                                }

                                commit('setSections', newSections);
                            }
                        })
                        .catch(error => console.log('Ошибка: ', error))


                }
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getBackgrounds({commit}) {
        let url = process.env.VUE_APP_API_URL + '/fitting/backgrounds/';

        return axios.get(url)
            .then(response => {
                commit('setBackgrounds', response.data.data)
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    /*getProducts({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/fitting/catalog/',
            params = {};

        if(Object.keys(getters['activeFilters']).length) {
            for(let filterName in getters['activeFilters']) {
                params[filterName] = getters['activeFilters'][filterName];
            }
        }

        if(getters['activeSections'].length > 0) {
            params['sections'] = getters['activeSections'].join(',');
        }

        return axios.get(url, {params: params})
            .then(response => {
                if(response.data.data.length > 0) {
                    commit('setCatalogItems', response.data.data)
                }

            })
            .catch(error => console.log('Ошибка: ', error))

    },*/
    addItem({commit, dispatch}, productId) {
        let url = process.env.VUE_APP_API_URL + '/fitting/add/';

        return axios.post(url, {productId: productId})
            .then(response => {
                //commit('setItems', response.data.data)
                dispatch('getItems')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    removeItem({commit, dispatch}, id) {
        let url = process.env.VUE_APP_API_URL + '/fitting/delete/';

        return axios.post(url, {id: id})
            .then(response => {
                //commit('setItems', response.data.data)
                //dispatch('getItems')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    removeAllItems({commit, dispatch}, id) {
        let url = process.env.VUE_APP_API_URL + '/fitting/delete/all/';

        return axios.post(url, {id: id})
            .then(response => {
            
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    updateItem({commit, dispatch}, params) {
        let url = process.env.VUE_APP_API_URL + '/fitting/update/';

        if (params.count) {
            commit('setSetsQuantity', {id: params.id, count: params.count})
        }

        return axios.post(url, params)
            .then(response => {
                if (params.count) {
                    commit('setSetsQuantity', {id: params.id, count: params.count})
                }
                //commit('setItems', response.data.data)
                //dispatch('getItems')
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    addBackground({commit}, base64) {
        let url = process.env.VUE_APP_API_URL + '/fitting/backgrounds/add/';

        commit('addBackground', base64)

        return axios.post(url, {base64: base64})
            .then(response => {
                //commit('addBackground', response.data.data)
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getPdf({commit, getters}, imageUrl) {
        let url = process.env.VUE_APP_API_URL + '/fitting/pdf/'

        let items = [];
        for (let i in getters['viewItems']) {
            let viewItem = getters['viewItems'][i];

            items.push({
                'product_id': viewItem.product_id,
                'left': viewItem.left / viewItem.kef,
                'top': viewItem.top / viewItem.kef,
                'width': viewItem.width / viewItem.kef,
                'height': viewItem.height / viewItem.kef,
                'rotate': viewItem.rotate,
                'is_flip': viewItem.is_flip,
            });
        }

        return axios.post(
            url,
            {
                image: imageUrl,
                items: items,
                background: getters['selectedBackground'],
                room: getters['selectedRoom'].value,
            },
            {
                responseType: 'arraybuffer',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const d = new Date();
                const dateString = `${d.getHours()}-${d.getMinutes()} ${d.getDate()}-${(d.getMonth()+1)}-${d.getFullYear()}`
                link.href = url;
                link.setAttribute('download', `Примерка интерьерного освещения ${dateString}.pdf`);
                document.body.appendChild(link);
                link.click();

                return true;
            })
            .catch(error => console.log('Ошибка: ', error))
    },
    getExcel({commit, getters}, imageUrl) {
        let url = process.env.VUE_APP_API_URL + '/fitting/excel/'

        let items = [];
        for (let i in getters['viewItems']) {
            let viewItem = getters['viewItems'][i];

            items.push({
                'product_id': viewItem.product_id,
                'left': viewItem.left / viewItem.kef,
                'top': viewItem.top / viewItem.kef,
                'width': viewItem.width / viewItem.kef,
                'height': viewItem.height / viewItem.kef,
                'rotate': viewItem.rotate,
                'is_flip': viewItem.is_flip,
            });
        }

        return axios.post(
            url,
            {
                image: imageUrl,
                items: items,
                background: getters['selectedBackground'],
                room: getters['selectedRoom'].value,
            },
            {
                responseType: 'arraybuffer',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                const d = new Date();
                const dateString = `${d.getHours()}-${d.getMinutes()} ${d.getDate()}-${(d.getMonth()+1)}-${d.getFullYear()}`
                link.href = url;
                link.setAttribute('download', `Примерка интерьерного освещения ${dateString}.xlsx`);
                document.body.appendChild(link);
                link.click();

                return true;
            })
            .catch(error => console.log('Ошибка: ', error))
    },
    loadFittingKit({commit, getters, dispatch}, link) {
        try {
            const urlObj = new URL(link);
            const urlParams = new URLSearchParams(urlObj.search)
            let kit_id = urlParams.get('share')

            if (kit_id) {
                let url = process.env.VUE_APP_API_URL + '/fitting/load/'

                return axios.post(url, {kit_id: kit_id})
                    .then(async response => {
                        if (response.data.error) {
                            console.log(response.data.error)
                        } else {
                            await dispatch('getItems');
                            if (response.data.data.items) {
                                const loadImage = src =>
                                    new Promise((resolve, reject) => {
                                        const img = new Image();
                                        img.onload = () => resolve(img);
                                        img.onerror = reject;
                                        img.src = src;
                                    })
                                ;

                                loadImage(response.data.data.image).then(() => {
                                    commit('setSelectedBackground', response.data.data.image);
                                    let fittingItems = getters['items']

                                    for (let i in response.data.data.items) {
                                        let viewItem = response.data.data.items[i];

                                        let index = fittingItems.findIndex(d => parseInt(d.product_id) === parseInt(viewItem.product_id));

                                        if (index > -1) {
                                            let kitItem = {...getters['items'][index], ...viewItem};

                                            commit('addToKit', kitItem)
                                        }
                                    }

                                    const room = {
                                        text: response.data.data.room,
                                        value: response.data.data.room,
                                    };
                                    commit('addRoom', room);
                                    commit('setSelectedRoom', room);
                                });

                            }
                        }
                    })
                    .catch(error => console.log('Ошибка: ', error))
            }
        } catch (_) {
            alert('Примерка не найдена');
            return false
        }
    },
}

