<template>
    <div class="fitting">
<!--        <back/>-->
        <breadcrumbs :breadcrumbs="breadcrumbs"/>
        <h1 class="fitting__title page-title">Онлайн-примерка освещения</h1>
        <div class="fitting-grid">
            <div class="fitting-main">
                <fitting-view :items="viewItems"/>
                <fitting-set v-if="kitItems.length > 0" :items="kitItems"/>
                <div class="fitting-description">
                    <div class="fitting-description__title">
                        Как будет выглядеть светильник в вашем интерьере?
                    </div>
                    <div class="fitting-description__text">
                        Мы подумали и решили, что примерять можно не только одежду, но и светильники! Именно поэтому был
                        создан простой, удобный и интуитивно понятный сервис - «Примерка товаров».
                        <br><br>
                        Воспользоваться им может каждый: для этого совершенно необязательно быть дизайнером интерьеров
                        или иметь профильное образование. Достаточно под нужным углом сфотографировать свою комнату,
                        загрузить фото и выбрать понравившийся светильник - из уже отобранных товаров, рекомендуемых или
                        через систему поиска по артикулу/названию - для «примерки» доступно более 10 000 артикулов!
                        через систему поиска по артикулу/названию - для «примерки» доступно более 10 000 артикулов!
                        Нажимаем кнопку «Примерить» и прямо на фото можно редактировать размер светильника, угол его
                        наклона и расположение.
                    </div>
                </div>
            </div>
            <div class="fitting-sidebar">
                <fitting-filters/>
                <fitting-category/>
                <fitting-search/>
                <fitting-catalog v-if="fittingItems.length > 0" :products="isRecommended ? recommendedItems : fittingItems"/>
            </div>
        </div>
        <fitting-catalog-viewed v-if="viewedItems.length > 0" :products="viewedItems"/>
    </div>
</template>

<script>
    import Back from "@/components/Back"
    import FittingView from "@/components/fitting/FittingView"
    import FittingFilters from "@/components/fitting/FittingFilters"
    import FittingCategory from "@/components/fitting/FittingCategory"
    import FittingCatalog from "@/components/fitting/FittingCatalog"
    import {computed, onMounted} from 'vue'
    import store from '@/store/store'
    import {useRoute} from 'vue-router'
    import FittingSet from "@/components/fitting/FittingSet"
    import FittingSearch from "@/components/fitting/FittingSearch"
    import FittingCatalogViewed from "@/components/fitting/FittingCatalogViewed"
    import Breadcrumbs from "@/components/Breadcrumbs"
    import {ref} from "vue";

    export default {
        components: {
            FittingCatalogViewed,
            FittingSearch,
            FittingSet,
            FittingCatalog,
            FittingCategory,
            FittingFilters,
            FittingView,
            Back,
            Breadcrumbs
        },
        setup() {
            const route = useRoute();
            const breadcrumbs = ref([
                {
                    'url': '/catalog/',
                    'title': 'Каталог товаров',
                },
                {
                    'title': 'Примерка',
                }
            ]);

            let activeFilters = {},
                activeSections = [];

            Object.keys(route.query).map((key, index) => {
                let val = route.query[key]

                if (key === 'sections') {
                    activeSections = val.split(',');
                } else {
                    activeFilters[key] = val
                }
            });

            /*if (!Object.keys(activeFilters).length && !activeSections.length) {
                activeFilters['added'] = 'Y';
            }*/

            store.commit('fitting/setActiveFilters', activeFilters);
            store.commit('fitting/setActiveSections', activeSections);

            onMounted(() => {
                //await store.dispatch('catalog/getSections');
                store.dispatch('fitting/getItems');
                store.dispatch('catalog/getViewedItems');
            });

            const kitItems = computed(() => {
                return store.getters['fitting/kitItems']
            });

            const viewItems = computed(() => {
                return store.getters['fitting/viewItems']
            });

            const fittingItems = computed(() => {
                return store.getters['fitting/items']
            });

            const viewedItems = computed(() => {
                return store.getters['catalog/viewed']
            });

            const isRecommended = computed(() => {
                return store.getters['fitting/isRecommended']
            });

            const recommendedItems = computed(() => {
                return store.getters['fitting/recommendedItems']
            });

            return {
                kitItems,
                viewItems,
                fittingItems,
                viewedItems,
                isRecommended,
                recommendedItems,
                breadcrumbs
            };
        }
    }
</script>

<style>

</style>
