<template>
    <label
        class="catalog-sidebar__label radio"
        :for="fieldId"
    >
        <input
            @input="check()"
            type="radio"
            :name="name"
            :checked="checked"
            :id="fieldId"
            v-bind="$attrs"
            hidden
        />

        <span class="catalog-sidebar__name">
            {{ title }}
        </span>
    </label>
</template>

<script>
    import { computed } from "vue";

    export default {
        emits: ["update:values"],
        props: {
            value: {
                type: String,
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            fieldId: {
                type: String,
                required: true,
            },
            values: {
                type: Array,
                required: true,
            },
        },
        setup(props, context) {
            const checked = computed(() => props.values.includes(props.value));

            function check() {
                let updatedValues = [];

                updatedValues.push(props.value);
                context.emit("update:values", updatedValues);
            }

            return {
                check,
                checked,
            };
        },
    };
</script>

<style>
</style>
