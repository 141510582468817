<template>
    <div
        class="similar-products"
    >
        <div class="similar-products__title">
            {{ title }}
        </div>
        <Carousel
            class="catalog-sections__wrapper"
            :itemsToShow="itemsToShow"
            snapAlign="start"
            :touchDrag="false"
            :mouseDrag="false"
        >
            <slide
                v-for="product in products"
                :key="product.id"
            >
                <router-link
                    class="similar-products-item"
                    :to="generatePartnerRedirectUrl('/catalog/detail/' + product.code + '/')"
                >
                    <div class="product-badges">
                        <span
                            v-if="product.is_bestseller"
                            class="product-badges__elem product-badges__elem--hit"
                        >
                            Хит
                        </span>
                        <span
                            v-if="product.is_new"
                            class="product-badges__elem product-badges__elem--new"
                        >
                            Новинка
                        </span>
                        <span
                            v-if="product.is_sale"
                            class="product-badges__elem product-badges__elem--razprodazha"
                        >
                            Распродажа
                        </span>
                        <span
                            v-if="product.is_promo"
                            class="product-badges__elem product-badges__elem--sale"
                        >
                            Акция
                        </span>
                    </div>
                    <div class="similar-products-item__image">
                        <img :src="getWebUrl() + product.image">
                    </div>
                    <div class="similar-products-item__title">
                        {{ product.title }}
                    </div>
                    <div class="product-price">
                        <span>
                            {{ product.discount_price_formatted }}
                        </span>
                    </div>
                </router-link>
            </slide>
            <template #addons>
                <Navigation/>
            </template>
        </Carousel>
    </div>
</template>

<script>
    //import 'vue-snap/dist/vue-snap.css'
    //import {Carousel, Slide} from 'vue-snap'
    import {useRouter} from 'vue-router'
    import store from '@/store/store'
    import {Carousel, Navigation, Slide} from 'vue3-carousel'
    import 'vue3-carousel/dist/carousel.css'
    import {onMounted, onUnmounted, ref} from "vue";

    export default {
        components: {
            Carousel,
            Slide,
            Navigation
        },
        props: {
            products: {
                type: Array,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        },
        setup() {
            const itemsToShow = ref(6);

            onMounted(() => {
                /*function getDomIndex(target) {
                    return [].slice.call(target.parentNode.children).indexOf(target)
                }

                const vsWrapper = document.querySelector('.catalog-sections__wrapper .vs-carousel__wrapper');
                const activeSection = document.querySelector('.catalog-sections__slide.active');
                if (activeSection) {
                    const index = getDomIndex(activeSection);
                    console.log('activeSection', activeSection)
                    vsWrapper.scrollBy({left: activeSection.clientWidth * index, behavior: 'smooth'})
                }*/

                window.addEventListener("resize", onResize);
            });

            onUnmounted(() => {
                window.removeEventListener("resize", onResize);
            });

            const onResize = () => {
                const width = window.innerWidth;

                if(width <= 450) {
                    itemsToShow.value = 2;
                    return;
                }
                if(width <= 574) {
                    itemsToShow.value = 3;
                    return;
                }
                if(width <= 768) {
                    itemsToShow.value = 4;
                    return;
                }
                if(width <= 991) {
                    itemsToShow.value = 5;
                    return;
                }
                if (width <= 1100) {
                    itemsToShow.value = 5;
                    return;
                }

                itemsToShow.value = 6;
            }

            onResize();

            return {
                itemsToShow
            }
        }
    }
</script>

<style lang="scss">

</style>
