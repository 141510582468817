<template>
    <Modal
        v-show="isVisible"
        @close="closeModal"
        small
    >
        <template v-slot:header>
            Добавление в проект
        </template>
        <template v-slot:body>
            <div
                class="project-form"
            >
                <div class="project-form__group">
                    <form-select
                        :options="projectsSelect"
                        :default="defaultProject"
                        @select="selectProject($event)"
                        add-input="Создать проект"
                        @add="addProject($event)"
                    />
                </div>
                <div
                    class="btn btn--orange"
                    v-if="selectedProject"
                    @click="add2Project"
                >
                    Добавить в проект
                </div>
            </div>
        </template>
    </Modal>
</template>

<script setup>
    import {computed, defineEmits} from 'vue'
    import store from '@/store/store'
    import Modal from "@/components/Modal"
    import FormSelect from '@/components/form/Select'
    import {useRoute} from "vue-router";

    const emit = defineEmits(['close', 'add']);

    const props = defineProps({
        itemId: {
            type: String,
            required: true
        },
        isVisible: {
            type: Boolean,
            required: true
        },
        isNotSelectProject: {
            type: Boolean,
            default: false
        }
    });

    const route = useRoute();

    const closeModal = () => {
        emit('close');
    }

    const projects = computed(() => store.getters['project/projects']);
    const selectedProject = computed(() => store.getters['project/selectedProject']);
    const defaultProject = computed(() => {
        return {
            text: selectedProject.value ? selectedProject.value.name : 'Выберите проект',
            value: selectedProject.value ? selectedProject.value.id : 'Выберите проект',
        }
    });

    const projectsSelect = computed(() => {
        const projectsSelect = [];
        for (let project of projects.value) {
            projectsSelect.push({
                text: project.name,
                value: project.id,
            });
        }

        return projectsSelect;
    });

    const selectProject = async (value) => {
        await store.dispatch('project/selectProject', value.value);
        store.dispatch('catalog/getViewedItems');
    }

    const addProject = async (value) => {
        await store.dispatch('project/addProject', value.text);
        store.dispatch('project/getProjects');
    }

    const add2Project = async () => {
        if (props.itemId) {
            const metaStore = route.meta.store || 'catalog';
            await store.dispatch('project/addItem', props.itemId);
            store.commit(metaStore + '/addToProject', props.itemId);
            store.commit('catalog/addToProject');
            emit('add');
        }

        closeModal();
    }
</script>

<style lang="scss">
.project-set {
    position: relative;
    margin-bottom: 40px;
}

.project-set__wrapper {
    .set-item {
        background: #fff;

        &:last-child {
            border-bottom: unset;
        }
    }

    .set-footer {
        background: #fff;
    }
}

.project-set__header {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 1;

    .btn {
        padding: 8px 20px;
        min-height: 32px;
    }
}

.project-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    @media (max-width: 576px) {
        .container {
            padding: 0;
        }
    }
}

.project-actions__wrapper {
    padding: 10px 20px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(47, 61, 81, 0.1), 0px 2px 10px rgba(47, 61, 81, 0.08);
    display: flex;
    gap: 10px;


    .btn {
        padding: 8px 20px;
        min-height: 32px;
    }
}

@media (max-width: 768px) {
    .project-set {
        .set-item {
            grid-template-columns: 80px 1fr;
        }

        .set-item__checkbox {
            grid-row-start: 1;
        }

        .set-item__image {
            max-width: 80px;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        .set-item__title {
            grid-row: 2;
            grid-column-end: 4;
        }

        .set-item__price {
            grid-row: 1;
        }

        .set-item__quantity {
            grid-column-start: 2;
            grid-row: 3;
        }

        .set-item__action {
            grid-row: 1;
        }
    }

}
</style>
