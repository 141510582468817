// hooks/useConfirm.js
import { ref, createApp, defineComponent, h } from 'vue';

const ConfirmModal = defineComponent({
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		confirmButtonText: {
			type: String,
			default: 'Подтвердить',
		},
		cancelButtonText: {
			type: String,
			default: 'Отмена',
		},
	},
	setup(props, { emit }) {
		const handleConfirm = () => {
			emit('confirm');
			close();
		};
		
		const handleCancel = () => {
			emit('cancel');
			close();
		};
		
		const close = () => {
			app.unmount();
			document.body.removeChild(container);
		};
		
		return { handleConfirm, handleCancel, close };
	},
	render() {
		return h(
			'div',
			{ class: 'modal-backdrop' },
			h('div', { class: 'modal', role: 'dialog', 'aria-labelledby': 'modalTitle', 'aria-describedby': 'modalDescription' }, [
				h('header', { class: 'modal-header', id: 'modalTitle' }, [
					this.$props.title,
					h('button', { type: 'button', class: 'btn-close', 'aria-label': 'Close modal', onClick: this.close }, [
						h('svg', { width: '14', height: '14', viewBox: '0 0 14 14', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, [
							h('path', {
								opacity: '0.4',
								d: 'M13 1L1 13M1 1L13 13',
								stroke: '#8B8B8B',
								'stroke-width': '2',
								'stroke-linecap': 'round',
								'stroke-linejoin': 'round',
							}),
						]),
					]),
				]),
				h('section', { class: 'modal-body', id: 'modalDescription' }, this.$props.content),
				h('div', { class: 'modal-footer' }, [
					h('div', { class: 'btn btn--gray', onClick: this.handleCancel }, this.$props.cancelButtonText),
					h('div', { class: 'btn btn--orange', onClick: this.handleConfirm }, this.$props.confirmButtonText),
				]),
			])
		);
	},
});

let app;
let container;

export function useConfirm() {
	return ({ title, content, confirmButtonText, cancelButtonText, onConfirm, onCancel }) => {
		container = document.createElement('div');
		document.body.appendChild(container);
		
		app = createApp(ConfirmModal, {
			title,
			content,
			confirmButtonText,
			cancelButtonText,
			onConfirm,
			onCancel,
		});
		
		app.mount(container);
	};
}
