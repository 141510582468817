<template>
    <div class="project-menu">
        <div class="project-list">
            <project-list-item v-for="project in projects"
                               :key="project.id"
                               :project="project"
            />
        </div>
        <div class="project-list__actions">
            <div
                class="btn btn--orange"
                @click="isShowNewProjectModal = true"
            >
                <span>Создать новый проект</span>
            </div>
            <div
                class="btn btn--orange"
                @click="showLoadProjectModal"
            >
                <span>Загрузить проект по номеру</span>
            </div>
        </div>

        <Modal
            v-show="isShowNewProjectModal"
            @close="isShowNewProjectModal = false"
            small
        >
            <template v-slot:header>
                Создать проект
            </template>
            <template v-slot:body>
                <div
                    class="feedback-form"
                >
                    <div class="feedback-form__group">
                        <input
                            type="text"
                            class="feedback-form__input"
                            placeholder="Введите название проекта"
                            v-model="newProjectName"
                        >
                    </div>
                    <div
                        class="btn btn--orange"
                        @click="addProject(newProjectName)"
                    >
                        Создать
                    </div>
                </div>
            </template>
        </Modal>
        <Modal
            v-show="isShowProjectModalVisible"
            @close="closeLoadProjectModal"
            small
        >
            <template v-slot:header>
                Загрузить проект по номеру
            </template>
            <template v-slot:body>
                <div
                    class="feedback-form"
                >
                    <div class="feedback-form__group">
                        <input
                            type="text"
                            class="feedback-form__input"
                            placeholder="Введите номер проекта"
                            v-model="projectId"
                        >
                    </div>
                    <div
                        class="btn btn--orange"
                        @click="loadProject"
                    >
                        Загрузить
                    </div>
                </div>
            </template>
        </Modal>
    </div>

</template>

<script>
    import {ref, computed} from 'vue'
    import store from '@/store/store'
    import Modal from "@/components/Modal"
    import ProjectListItem from "@/components/project/ProjectListItem";

    export default {
        components: {
            ProjectListItem,
            Modal,
        },
        setup() {
            const projects = computed(() => store.getters['project/projects']);

            const addProject = async (name) => {
                if (name) {
                    await store.dispatch('project/addProject', name);
                    store.dispatch('project/getProjects');
                    isShowNewProjectModal.value = false
                }
            }

            const isShowNewProjectModal = ref(false);

            const isShowProjectModalVisible = ref(false);

            const showLoadProjectModal = () => {
                isShowProjectModalVisible.value = true
            }
            const closeLoadProjectModal = () => {
                isShowProjectModalVisible.value = false
            }

            const loadProject = async () => {
                await store.dispatch('project/loadProject', projectId.value);
                store.dispatch('project/getProjects');
                closeLoadProjectModal();
            }

            const newProjectName = ref('');
            const projectId = ref('');

            return {
                projects,
                addProject,
                isShowNewProjectModal,
                showLoadProjectModal,
                closeLoadProjectModal,
                loadProject,
                isShowProjectModalVisible,
                newProjectName,
                projectId
            };
        }
    }
</script>

<style lang="scss">
.project-main {
    display: flex;
    gap: 20px;

    .fitting-main__header {
        background: none;
        justify-content: flex-start;
        padding: 0;
        margin-bottom: 20px;
    }
}

.project-menu {
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 12px;
}

.project-list {


    .project-list__item {
        padding: 10px 18px;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 10px;
        display: flex;
        gap: 8px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.active {
            color: var(--orange);
        }
    }
}

.project-list__actions {
    .btn {
        padding: 15px 20px;
        min-height: 32px;
        margin-bottom: 8px;
        width: 100%;
        text-align: left;
        justify-content: flex-start;
        font-size: 14px;

        svg {
            width: 18px;
            height: 18px;
            fill: currentColor;
        }
    }
}

.project-list__item_actions {
    display: flex;
    align-items: center;
    gap: 8px;
}
</style>
