<template>
    <div
        v-if="prevRoutePath"
        class="back"
    >
        <div
            class="back__btn"
            :data-test="prevRoutePath"
            @click="goToBackPage"
        >
            <icon-base width="14"
                       height="14"
                       stroke="#5a5a5a"
            >
                <icon-back/>
            </icon-base>
        </div>
    </div>
</template>

<script>
    import IconBack from "@/components/icons/IconBack"
    import {defineComponent} from 'vue'

    export default defineComponent({
        name: 'Error404',
        components: {
            IconBack
        },
        computed: {
            prevRoutePath () {
                return this.$router.options.history.state.back
            }
        },
        methods: {
            goToBackPage() {
                this.$router.go(-1);
            }
        },
    });
</script>

<style lang="scss">
    .back__btn {
        display: inline-flex;
        align-items: center;
        color: #8B8B8B;
        cursor: pointer;

        svg {
            margin-right: 8px;
            width: 32px;
            height: 32px;
        }
    }
</style>
