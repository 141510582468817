<template>
    <radio
        v-for="aggregation in aggregations"
        :key="aggregation.control_id"
        :fieldId="aggregation.control_id"
        v-model:values="checkedValues"
        :value="aggregation.control_id.toString()"
        :title="aggregation.name"
        :name="aggregation.param"
    />
</template>

<script>
    import Radio from "@/components/form/Radio"
    import {ref, watch, computed} from 'vue'
    import store from '@/store/store'
    import {useRoute, useRouter} from 'vue-router'

    export default {
        components: {
            Radio
        },
        props: {
            aggregations: {
                type: Array,
                required: true
            }
        },
        setup(props) {
            const router = useRouter();
            const route = useRoute();

            const checkedValues = ref([]);
            let activeFilters = store.getters[route.meta.store + '/activeFilters'];

            const isResetFilters = computed(() => {
                return store.getters[route.meta.store + '/isResetFilters'];
            });

            watch(isResetFilters, (value) => {
                if(value) {
                    checkedValues.value = [];
                    store.commit(route.meta.store + '/setIsResetFilters', false);
                }
            })

            props.aggregations.forEach(function(item) {
                if(activeFilters[item.control_id]) {
                    checkedValues.value.push(item.control_id)
                }
            });

            watch(checkedValues, async (newCheckedValues) => {
                props.aggregations.forEach(function(item) {
                    if(newCheckedValues.includes(item.control_id)) {
                        activeFilters[item.control_id] = 'Y';
                    }
                    else {
                        delete activeFilters[item.control_id];
                    }
                });

                store.commit(route.meta.store + '/setActiveFilters', activeFilters);
                store.commit(route.meta.store + '/setIsFetching', true);
                await store.dispatch(route.meta.store + '/getProducts');
                store.commit(route.meta.store + '/setIsFetching', false);
                router.push({ query: store.getters[route.meta.store + '/query']});
            });

            return {
                checkedValues,
            };
        },
    }
</script>
