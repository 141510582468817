<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         :viewBox="'0 0 ' + width + ' ' + height"
         role="presentation"
         :fill="fill"
         :stroke="stroke"
    >
        <slot/>
    </svg>
</template>

<script>
    export default {
        props: {
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 18
            },
            fill: {
                type: String,
                default: '#4C4C4C'
            },
            stroke: {
                type: String,
                default: '#4C4C4C'
            }
        }
    }
</script>

<style scoped
       lang="scss"
>
    svg {
        display: inline-block;
        vertical-align: baseline;
    }
</style>
