<template>
    <div class="catalog-buttons">
        <router-link :to="generatePartnerRedirectUrl('/catalogs/')" class="btn btn--border">
            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.75 3.76724C9.33579 3.76724 9 4.10303 9 4.51724C9 4.93146 9.33579 5.26724 9.75 5.26724V3.76724ZM10.9167 5.26724C11.3309 5.26724 11.6667 4.93146 11.6667 4.51724C11.6667 4.10303 11.3309 3.76724 10.9167 3.76724V5.26724ZM9.75 7.28448C9.33579 7.28448 9 7.62027 9 8.03448C9 8.4487 9.33579 8.78448 9.75 8.78448V7.28448ZM10.9167 8.78448C11.3309 8.78448 11.6667 8.4487 11.6667 8.03448C11.6667 7.62027 11.3309 7.28448 10.9167 7.28448V8.78448ZM4.91667 18C4.91667 18.4142 5.25245 18.75 5.66667 18.75C6.08088 18.75 6.41667 18.4142 6.41667 18H4.91667ZM14.25 2V15.2414H15.75V2H14.25ZM1.75 15.2414V2H0.25V15.2414H1.75ZM2 1.75H5.66667V0.25H2V1.75ZM5.66667 1.75H14V0.25H5.66667V1.75ZM14 15.4914H5.66667V16.9914H14V15.4914ZM5.66667 15.4914H2V16.9914H5.66667V15.4914ZM4.91667 1V16.2414H6.41667V1H4.91667ZM9.75 5.26724H10.9167V3.76724H9.75V5.26724ZM9.75 8.78448H10.9167V7.28448H9.75V8.78448ZM4.91667 16.2414V18H6.41667V16.2414H4.91667ZM0.25 15.2414C0.25 16.2079 1.0335 16.9914 2 16.9914V15.4914C1.86193 15.4914 1.75 15.3795 1.75 15.2414H0.25ZM14.25 15.2414C14.25 15.3795 14.1381 15.4914 14 15.4914V16.9914C14.9665 16.9914 15.75 16.2079 15.75 15.2414H14.25ZM15.75 2C15.75 1.0335 14.9665 0.25 14 0.25V1.75C14.1381 1.75 14.25 1.86193 14.25 2H15.75ZM1.75 2C1.75 1.86193 1.86193 1.75 2 1.75V0.25C1.0335 0.25 0.25 1.0335 0.25 2H1.75Z"
                      :fill="getPartner().includes('amperkin.ru') ? '#ffd11b' : '#FD7621'"
                />
            </svg>
            <span>
                Скачать <br>каталоги производителей
            </span>
        </router-link>
        <div
            v-if="getPartner().includes('eway.elevel.ru')"
            class="btn btn--border"
            @click="showModal"
        >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 4.42857V3C1 1.89543 1.89543 1 3 1H15C16.1046 1 17 1.89543 17 3V4.42857M1 4.42857V9V13.5714V15C1 16.1046 1.89543 17 3 17H6M1 4.42857H6M17 4.42857V9M17 4.42857H12M17 9V13.5714M17 9H6M17 13.5714V15C17 16.1046 16.1046 17 15 17H12M17 13.5714H6M6 4.42857H12M6 4.42857V9M6 17H12M6 17V13.5714M12 4.42857V17M6 9V13.5714"
                      stroke="#FD7621"
                      stroke-width="1.5"
                />
            </svg>
            <span>
                Заказать <br>светотехнический расчет
            </span>
        </div>
    </div>
    <Modal
        v-show="isModalVisible"
        @close="closeModal"
        small
    >
        <template v-slot:header>
            Заказать светотехнический расчет
        </template>
        <template v-slot:body>
            <div
                v-if="isFeedbackSubmit"
                class="feedback-success"
            >
                <svg width="32"
                     height="32"
                     viewBox="0 0 32 32"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M26.7845 23.1259L22.7003 19.0414C21.8501 18.1912 20.4717 18.1903 19.6206 19.0414L18.4312 20.2309C15.7134 18.5711 13.4301 16.2876 11.7703 13.5696L13.0745 12.2654C13.9235 11.4162 13.9235 10.0345 13.0745 9.18541L8.99031 5.10093C8.14255 4.25322 6.76612 4.24534 5.90807 5.1035L3.34159 7.69014C2.11364 8.7213 1.68172 10.4143 2.24172 12.0189C5.11532 20.2542 11.7472 26.8865 19.9818 29.7603C21.5311 30.3009 23.122 29.8966 24.1336 28.8543C24.1482 28.8414 24.1625 28.8281 24.1764 28.8141L26.7845 26.2058C27.6335 25.3567 27.6335 23.9751 26.7845 23.1259ZM25.4834 24.9047L22.9343 27.454C22.9032 27.4798 22.8738 27.5078 22.8461 27.5379C22.3096 28.1212 21.4465 28.3226 20.5881 28.0229C12.8784 25.3323 6.66943 19.1229 3.97903 11.4125C3.67097 10.5297 3.89322 9.61512 4.54526 9.08237C4.57003 9.06213 4.59377 9.04058 4.6163 9.01789L7.21171 6.40205C7.29773 6.31596 7.3983 6.30311 7.45053 6.30311C7.50281 6.30311 7.60327 6.31591 7.68935 6.40205L11.7734 10.4865C11.9051 10.6182 11.9051 10.8324 11.7735 10.9641L9.96643 12.7713C9.67434 13.0634 9.61271 13.5142 9.8156 13.8739C11.7766 17.3506 14.6505 20.2248 18.1269 22.1858C18.4866 22.3888 18.9374 22.327 19.2294 22.035L20.9217 20.3426C21.0535 20.2108 21.2674 20.2107 21.3993 20.3426L25.4835 24.4271C25.6151 24.5588 25.6151 24.773 25.4834 24.9047Z"
                          :fill="getPartner().includes('amperkin.ru') ? '#ffd11b' : '#FD7621'"
                    />
                    <path d="M16.7055 9.18168C16.1974 9.18168 15.7855 9.59361 15.7855 10.1018C15.7855 10.6099 16.1974 11.0218 16.7055 11.0218C19.0618 11.0218 20.9788 12.9389 20.9788 15.2954C20.9788 15.8035 21.3907 16.2154 21.8988 16.2154C22.4069 16.2154 22.8188 15.8035 22.8188 15.2954C22.8188 11.9243 20.0764 9.18168 16.7055 9.18168Z"
                          :fill="getPartner().includes('amperkin.ru') ? '#ffd11b' : '#FD7621'"
                    />
                    <path d="M16.7055 5.57052C16.1974 5.57052 15.7855 5.98246 15.7855 6.4906C15.7855 6.99874 16.1974 7.41067 16.7055 7.41067C21.0529 7.41067 24.5897 10.9477 24.5897 15.2954C24.5897 15.8035 25.0016 16.2155 25.5097 16.2155C26.0178 16.2155 26.4297 15.8035 26.4297 15.2954C26.4297 9.9331 22.0674 5.57052 16.7055 5.57052Z"
                          :fill="getPartner().includes('amperkin.ru') ? '#ffd11b' : '#FD7621'"
                    />
                    <path d="M26.1061 5.89409C23.5952 3.38293 20.2566 2 16.7055 2C16.1974 2 15.7855 2.41194 15.7855 2.92008C15.7855 3.42821 16.1974 3.84015 16.7055 3.84015C23.0216 3.84015 28.16 8.97897 28.16 15.2955C28.16 15.8036 28.5719 16.2155 29.08 16.2155C29.5881 16.2155 30 15.8036 30 15.2955C29.9999 11.7441 28.6171 8.40531 26.1061 5.89409Z"
                          :fill="getPartner().includes('amperkin.ru') ? '#ffd11b' : '#FD7621'"
                    />
                </svg>
                <span>
                    Светотехнический расчет заказан
                </span>
            </div>
            <div
                v-else
                class="feedback-form"
            >
                <div class="feedback-form__group">
                    <input
                        type="text"
                        class="feedback-form__input"
                        :class="{error: nameError}"
                        placeholder="Имя"
                        v-model="name"
                        @input="nameError = false"
                    >
                    <span
                        v-if="nameError"
                        class="feedback-form__error"
                    >
                        Введите имя
                    </span>
                </div>
                <div class="feedback-form__group">
                    <input
                        type="tel"
                        class="feedback-form__input"
                        :class="{error: phoneError}"
                        placeholder="Телефон"
                        v-model="phone"
                        @input="phoneError = false"
                        v-maska="'+7 (###) ###-##-##'"
                    >
                    <span
                        v-if="phoneError"
                        class="feedback-form__error"
                    >
                        Введите телефон
                    </span>
                </div>
                <div class="feedback-form__group">
                    <textarea
                        class="feedback-form__textarea"
                        v-model="text"
                        rows="10"
                        placeholder="Сообщение"
                    ></textarea>
                </div>
                <div
                    class="btn btn--orange"
                    @click="feedbackSubmit"
                >
                    Заказать
                </div>
                <div class="feedback-form__footer">
                    Нажимая кнопку «Заказать», Вы соглашаетесь с условиями <a href="/" target="_blank">политики
                    конфиденциальности</a>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
    import IconSearch from '@/components/icons/IconSearch'
    import IconHanger from "@/components/icons/IconHanger"
    import {ref} from 'vue'
    import HeaderSearch from "@/components/header/HeaderSearch"
    import Modal from "@/components/Modal"
    import axios from 'axios'
    import {maska} from 'maska'

    export default {
        directives: {maska},
        components: {
            Modal,
            HeaderSearch,
            IconHanger,
            IconSearch,
        },
        setup() {
            const isModalVisible = ref(false);

            const name = ref('');
            const phone = ref('');
            const text = ref('');
            const nameError = ref(false);
            const phoneError = ref(false);

            const showModal = () => {
                isModalVisible.value = true
            }

            const closeModal = () => {
                isModalVisible.value = false
            }

            const isFeedbackSubmit = ref(false);
            const feedbackSubmit = () => {
                if (!name.value) {
                    nameError.value = true;
                }
                if (phone.value.replace(/[^.\d]+/g, "").substring(1).length !== 10) {
                    phoneError.value = true;
                }
                if (nameError.value || phoneError.value) {
                    return;
                }

                axios.post(process.env.VUE_APP_API_URL + '/calculation/', {
                    name: name.value,
                    phone: phone.value,
                    text: text.value
                })
                    .then(response => {
                        isFeedbackSubmit.value = true;
                    })
                    .catch(error => console.log('Ошибка: ', error))
                isFeedbackSubmit.value = true;
            }

            return {
                isModalVisible,
                showModal,
                closeModal,
                feedbackSubmit,
                name,
                phone,
                text,
                nameError,
                phoneError,
                isFeedbackSubmit
            }
        }
    }
</script>

<style>

</style>
