<template>
    <div
        class="fitting-viewed-item"
        @click="goToProduct('/catalog/detail/' + product.code + '/')"
    >
        <div class="fitting-viewed-item__image">
            <img :src="getWebUrl() + product.image">
        </div>
        <div
            class="fitting-viewed-item__title"
            @click.stop="projectAdd(product.id)"
            :class="{ active: product.is_in_project }"
        >
            <span>{{ product.is_in_project ? 'В проекте' : 'В проект' }}</span>
        </div>
    </div>
    <ProjectModal :item-id="product?.id" :isVisible="isProjectModalVisible" @close="onCloseModal" @add="onAdd2Project"/>
</template>

<script>
    import {Carousel, Slide} from 'vue-snap'
    import 'vue-snap/dist/vue-snap.css'
    import store from '@/store/store'
    import {useRouter, useRoute} from 'vue-router'
    import ProjectModal from "@/components/project/ProjectModal"
    import {ref} from "vue";

    export default {
        components: {
            Carousel,
            Slide,
            ProjectModal
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            const router = useRouter();
            const route = useRoute();

            const goToProduct = async (url) => {
                await router.push(url);
            }

            const isProjectModalVisible = ref(false);

            const projectAdd = () => {
                if (!props.product.is_in_project) {
                    isProjectModalVisible.value = true;
                }
            }

            const onCloseModal = () => {
                isProjectModalVisible.value = false;
            }

            const onAdd2Project = () => {
                isProjectModalVisible.value = false;
                store.commit('catalog/addToProjectFromViewed', props.product.id);
                store.dispatch('project/getItems');
            }

            return {
                projectAdd,
                goToProduct,
                isProjectModalVisible,
                onCloseModal,
                onAdd2Project
            }
        }
    }
</script>

<style lang="scss">

    .fitting-viewed {
        padding: 0 15px;
        margin: 0 -15px;
        margin-top: 20px;
        padding-bottom: 30px;
    }

    .fitting-viewed__wrapper {
        margin-bottom: 12px;

        .fitting-viewed__slide {
            flex: 0 0 33%
        }

        @media(min-width: 500px) {
            .fitting-viewed__slide {
                flex: 0 0 25%
            }
        }

        @media(min-width: 768px) {
            .fitting-viewed__slide {
                flex: 0 0 20%
            }
        }

        @media(min-width: 1024px) {
            .fitting-viewed__slide {
                flex: 0 0 12%
            }
        }

        @media(min-width: 1280px) {
            .fitting-viewed__slide {
                flex: 0 0 10%
            }
        }
    }

    .fitting-viewed__title {
        font-size: 20px;
        font-weight: bold;
        color: #434A54;
        margin-bottom: 12px;
    }

    .fitting-viewed-item {
        padding: 12px;
        background: #FFFFFF;
        border-radius: 4px;
        text-align: center;
        max-width: 100px;
        cursor: pointer;
        margin-right: 10px;
        box-shadow: 0px 2px 4px rgb(47 61 81 / 10%), 0px 5px 10px rgb(47 61 81 / 8%);
    }

    .fitting-viewed-item__title {
        margin-top: 6px;
        font-size: 13px;

        span {
            border-bottom: 1px dashed #4C4C4C;
        }

        &.active {
            span {
                border-bottom: none;
            }
        }
    }

    .fitting-viewed-item__image {
        width: 70px;
        height: 70px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .clearfix:after {
        clear: both;
    }
</style>
