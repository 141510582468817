<template>
    <transition name="modal-fade">
        <div
            class="modal-backdrop"
            @click="close"
        >
            <div class="modal"
                 role="dialog"
                 aria-labelledby="modalTitle"
                 aria-describedby="modalDescription"
                 @click.stop="() => {}"
                 :class="{sm: small, md: medium, lg: large}"
            >
                <header
                    class="modal-header"
                    id="modalTitle"
                >
                    <slot name="header">

                    </slot>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close"
                        aria-label="Close modal"
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M13 1L1 13M1 1L13 13" stroke="#8B8B8B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </header>

                <section
                    class="modal-body"
                    id="modalDescription"
                >
                    <slot name="body">
                    </slot>
                </section>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'Modal',
        props: {
            small: {
                type: Boolean,
                default: false
            },
            medium: {
                type: Boolean,
                default: false
            },
            large: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss">
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99999;
    }

    .modal {
        background: #FFFFFF;
        box-shadow: 0px 3px 6px rgba(47, 61, 81, 0.1), 0px 30px 40px rgba(47, 61, 81, 0.24);
        border-radius: 4px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        transition: 200ms linear;

        &.sm {
            max-width: 400px;
        }

        &.md {
            max-width: 600px;
        }

        &.lg {
            max-width: 1000px;
        }
    }

    .modal-header {
        padding: 24px 30px;
        display: flex;
    }

    .modal-header {
        position: relative;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        padding-right: 40px;
        color: #434A54;
    }

    .modal-body {
        position: relative;
        padding: 0 30px 24px;
    }

    .modal-footer {
        padding: 0 30px 24px;
        display: flex;
        gap: 12px;
        justify-content: flex-end;
    }

    .btn-close {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        cursor: pointer;
        background: transparent;
    }

    .btn-green {
        color: white;
        background: #4AAE9B;
        border: 1px solid #4AAE9B;
        border-radius: 2px;
    }

    .modal-fade-enter-from {
        opacity: 0;
    }

    .modal-fade-enter-to {
        opacity: 1;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: all .5s ease;
    }

    .modal-fade-leave-from {
        opacity: 1;
    }

    .modal-fade-leave-to {
        opacity: 0;
    }
</style>
