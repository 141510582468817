<template>
    <g>
        <path d="M18.9754 15.3665C18.9754 14.3034 18.2618 13.3346 17.1416 12.9584L10.806 9.77866L10.7858 8.05854C11.9991 7.74957 12.8928 6.75649 12.8928 5.57984C12.8928 4.15726 11.591 3 9.99065 3C9.20294 3 8.11277 3.54139 7.52111 4.33737C7.373 4.53516 7.35975 4.79598 7.48681 5.00554C7.61349 5.21545 7.85904 5.35227 8.12758 5.35227H8.14083C8.39184 5.35227 8.62336 5.22619 8.74691 5.03187C9.00728 4.62002 9.5545 4.33287 9.9922 4.33287C10.7667 4.33287 11.3977 4.8947 11.3977 5.58227C11.3977 6.27156 10.7667 6.83235 9.9922 6.83235H9.9887C9.80434 6.83235 9.627 6.89574 9.49799 7.01178C9.3682 7.12851 9.29531 7.28542 9.29765 7.44995L9.31519 9.77797L2.8502 12.9584C1.73119 13.3346 1 14.302 1 15.3658V15.7769C1 16.9695 2.04963 18 3.39119 18H16.5959C17.9363 18 19 16.9751 19 15.7828L18.9754 15.3665ZM17.5049 15.7766C17.5049 16.2352 17.1104 16.6599 16.5952 16.6599H3.39119C2.87593 16.6599 2.48772 16.2352 2.48772 15.7766V15.3651C2.48772 14.8511 2.83539 14.3838 3.3756 14.203L10.0012 10.9723L16.6178 14.203C17.1592 14.3838 17.5045 14.8511 17.5045 15.3651L17.5049 15.7766Z"
        />
    </g>
</template>

<script>
    export default {
        props: {
            stroke: {
                type: String,
            }
        }
    }
</script>
