<template>
    <div class="catalog-menu" :class="{active: isOpen}">
        <div class="catalog-menu__btn btn btn--orange" @click="isOpen = !isOpen">
            <span>{{ isOpen ? 'Скрыть' : 'Показать' }} меню каталога</span>
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L4 4L7 1" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path>
            </svg>
        </div>
        <slide-up-down v-model="isOpen" :duration="300">
            <div class="catalog-menu__wrapper">
                <catalog-menu-group
                    v-for="section in sections"
                    :key="section.id"
                    :sectionGroup="section"
                    @close-menu="isOpen = false"
                />
            </div>
        </slide-up-down>
    </div>
</template>

<script>
    import CatalogMenuGroup from "@/components/catalog/CatalogMenuGroup";
    import {ref} from 'vue';

    export default {
        components: {CatalogMenuGroup},
        props: {
            sections: {
                type: Array,
                required: true
            }
        },
        setup() {
            const width = window.innerWidth;
            const isOpen = ref(width >= 991);

            return {
                isOpen
            };
        }
    };
</script>
