<template>
    <div class="project-set">
        <div class="project-set__wrapper">
            <label class="set-room set-room--all">
                <div class="set-room__checkbox">
                    <checkbox
                        fieldId="all"
                        v-model:values="checkedAll"
                        value="all"
                        :title="''"
                        :disabled="false"
                    />
                </div>
                <span>Выбрать все</span>
            </label>
            <project-item
                v-for="item in items"
                :key="item.id + '_' + item.room"
                :item="item"
            />
            <div class="set-room"></div>
            <div class="set-footer">
                <div class="set-footer__info">
                    <div class="set-footer__title">
                        Стоимость комплекта
                    </div>
                    <div class="set-footer__price">
                        <span>{{ priceSum.toLocaleString('ru-RU') }} руб.</span>
                        <Popper
                            hover
                            closeDelay="100"
                            arrow
                        >
                            <span class="price-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                     width="24" height="24"
                                     viewBox="0 0 24 24"
                                >
                                    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path>
                                </svg>
                            </span>
                            <template #content>
                                <div class="price-icon__text">
                                    Указана розничная цена. Цену с учетом вашей индивидуальной скидки можно увидеть в корзине, после добавления товара.
                                </div>
                            </template>
                        </Popper>
                    </div>
                </div>
                <div
                    class="btn btn--orange"
                    @click="buySet"
                >
                    Добавить товары в корзину
                </div>
            </div>
        </div>
    </div>
    <div class="project-actions" v-if="checkedItems.length > 0">
        <div class="container">
            <div class="project-actions__wrapper">
                <div
                    class="btn btn--orange"
                    @click="isEditRoomModalVisible = true"
                >
                    Изменить помещение
                </div>
                <div
                    class="btn btn--orange"
                    @click="isEditProjectModalVisible = true"
                >
                    Переместить в проект
                </div>
                <div
                    class="btn btn--border"
                    @click="removeGroup"
                >
                    Удалить
                </div>
            </div>
        </div>
        <Modal
            v-show="isEditRoomModalVisible"
            @close="isEditRoomModalVisible = false"
            small
        >
            <template v-slot:header>
                Изменить помещение
            </template>
            <template v-slot:body>
                <div
                    class="feedback-form"
                >
                    <div class="feedback-form__group">
                        <input
                            type="text"
                            class="feedback-form__input"
                            placeholder="Введите название помещения"
                            v-model="room"
                        >
                    </div>
                    <div
                        class="btn btn--orange"
                        @click="editRoomGroup"
                    >
                        Применить
                    </div>
                </div>
            </template>
        </Modal>
        <Modal
            v-show="isEditProjectModalVisible"
            @close="isEditProjectModalVisible = false"
            small
        >
            <template v-slot:header>
                Изменить проект
            </template>
            <template v-slot:body>
                <div
                    class="project-form"
                >
                    <div class="project-form__group">
                        <form-select
                            :options="projectsSelect"
                            :default="defaultProject"
                            @select="selectProject($event)"
                        />
                    </div>
                    <div
                        class="btn btn--orange"
                        v-if="selectedProject"
                        @click="editProjectGroup"
                    >
                        Применить
                    </div>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script>
    import {computed, ref} from 'vue'
    import store from '@/store/store'
    import ProjectItem from "@/components/project/ProjectItem"
    import Modal from "@/components/Modal"
    import axios from "axios";
    import FormSelect from '@/components/form/Select'
    import Checkbox from "@/components/form/Checkbox"
    import {watch} from "vue";

    export default {
        components: {ProjectItem, Modal, FormSelect, Checkbox},
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        setup(props) {
            const priceSum = computed(() => {
                return store.getters['project/priceSum']
            });

            const buySet = () => {
                const partner = window.axios.defaults.headers.common['partner'];
                let items = [];

                props.items.forEach(function (item) {
                    if(partner.includes('eway.elevel.ru') ||
                        partner.includes('elv.garm.work') ||
                        partner.includes('eway-test.elevel.ru')
                    ) {
                        if(item.show_room_title) {
                            items.push({
                                name: item.room === '' ? 'Без помещения' : item.room,
                                temporary: true,
                                quantity: 0,
                            })
                        }
                    }
                    items.push({
                        vendor_code: item.vendor_code,
                        raec_id: item.raec_id,
                        quantity: item.count,
                    })
                })

                window.parent.postMessage({
                    type: 'DsAdd2Basket',
                    items: items,
                }, '*');
            };

            const room = ref('');

            const isEditRoomModalVisible = ref(false);

            const editRoomGroup = () => {
                let ids = [];
                props.items.forEach(function (item) {
                    if(item.is_checked) {
                        ids.push(item.id);
                    }
                });
                axios.post(process.env.VUE_APP_API_URL + '/project/room-group/', {
                    ids: ids,
                    room: room.value,
                })
                    .then(response => {
                        store.dispatch('project/getItems');
                        isEditRoomModalVisible.value = false
                    })
                    .catch(error => console.log('Ошибка: ', error))
            };

            const projects = computed(() => store.getters['project/projects']);
            const selectedProject = computed(() => store.getters['project/selectedProject']);
            const defaultProject = computed(() => {
                return {
                    text: selectedProject.value ? selectedProject.value.name : 'Выберите проект',
                    value: selectedProject.value ? selectedProject.value.id : 'Выберите проект',
                }
            });

            const projectsSelect = computed(() => {
                const projectsSelect = [];
                for (let project of projects.value) {
                    projectsSelect.push({
                        text: project.name,
                        value: project.id,
                    });
                }

                return projectsSelect;
            });

            const selectedProjectOption = ref('');
            const selectProject = (value) => {
                selectedProjectOption.value = value.value;
            }

            const isEditProjectModalVisible = ref(false);

            const editProjectGroup = () => {
                let ids = [];
                props.items.forEach(function (item) {
                    if(item.is_checked) {
                        ids.push(item.id);
                    }
                });
                axios.post(process.env.VUE_APP_API_URL + '/project/project-group/', {
                    ids: ids,
                    projectId: selectedProjectOption.value,
                })
                    .then(response => {
                        store.dispatch('project/getItems');
                        isEditProjectModalVisible.value = false
                    })
                    .catch(error => console.log('Ошибка: ', error))
            };

            const removeGroup = () => {
                let ids = [];
                props.items.forEach(function (item) {
                    if(item.is_checked) {
                        ids.push(item.id);
                    }
                });
                axios.post(process.env.VUE_APP_API_URL + '/project/item/delete-group/', {
                    ids: ids,
                })
                    .then(response => {
                        store.dispatch('project/getItems');
                        isEditRoomModalVisible.value = false
                    })
                    .catch(error => console.log('Ошибка: ', error))
            };

            const checkedItems = computed(() => {
                return store.getters['project/checkedItems'];
            });

            const checkedAll = ref([]);

            watch(checkedAll, (newCheckedAll) => {
                console.log('newCheckedAll', newCheckedAll)
                store.commit('project/setCheckedAll', {checked: newCheckedAll[0] === 'all'});
            });

            return {
                priceSum,
                buySet,
                editRoomGroup,
                removeGroup,
                room,
                isEditRoomModalVisible,
                checkedItems,
                isEditProjectModalVisible,
                editProjectGroup,
                selectedProject,
                defaultProject,
                projectsSelect,
                selectProject,
                checkedAll
            };
        }
    }
</script>

<style lang="scss">
    .project-set {
        position: relative;
        margin-bottom: 40px;
    }

    .project-set__wrapper {
        .set-item {
            background: #fff;

            &:last-child {
                border-bottom: unset;
            }
        }

        .set-footer {
            background: #fff;
        }
    }

    .project-set__header {
        position: absolute;
        top: 5px;
        right: 0;
        z-index: 1;

        .btn {
            padding: 8px 20px;
            min-height: 32px;
        }
    }

    .project-actions {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1000;

        @media (max-width: 576px) {
            .container {
                padding: 0;
            }
        }
    }

    .project-actions__wrapper {
        padding: 10px 20px;
        background: #fff;
        box-shadow: 0px 0px 10px rgba(47, 61, 81, 0.1), 0px 2px 10px rgba(47, 61, 81, 0.08);
        display: flex;
        gap: 10px;


        .btn {
            padding: 8px 20px;
            min-height: 32px;
        }
    }

    .set-room--all {
        font-weight: 300;
        top: 0;
    }

    @media (max-width: 768px) {
        .project-set {
            .set-item {
                grid-template-columns: 80px 1fr;
            }

            .set-item__checkbox {
                grid-row-start: 1;
            }

            .set-item__image {
                max-width: 80px;
                grid-row-start: 2;
                grid-row-end: 3;
            }

            .set-item__title {
                grid-row: 2;
                grid-column-end: 4;
            }

            .set-item__price {
                grid-row: 1;
            }

            .set-item__quantity {
                grid-column-start: 2;
                grid-row: 3;
            }

            .set-item__action {
                grid-row: 1;
            }
        }

    }
</style>
