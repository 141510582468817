<template>
    <div class="catalog-banners">
        <vueper-slides fixed-height="130px"
                       :arrows="false"
                       :autoplay="true"
                       :dragging-distance="30"
                       prevent-y-scroll
                       ref="myVueperSlides"
        >
            <vueper-slide v-for="banner in banners"
                          :key="banner"
                          :image="banner.image"
            >
                <template #content>
                    <div
                        class="catalog-banners__item"
                        @click="goToUrl($event, banner.url)"
                    >
                    </div>
                </template>
            </vueper-slide>
        </vueper-slides>
    </div>
</template>

<script>
    import {useRouter} from "vue-router";
    import {VueperSlides, VueperSlide} from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import {ref} from 'vue'

    export default {
        components: {
            VueperSlides, VueperSlide
        },
        props: {
            banners: {
                type: Array,
                required: true
            }
        },
        setup() {
            const router = useRouter();
            const myVueperSlides = ref(null);
            const goToUrl = async (event, url) => {
                if (myVueperSlides.value.justDragged()) {
                    event.preventDefault()
                }
                else {
                    await router.push(url);
                }
            }

            return {
                goToUrl,
                myVueperSlides
            }
        },
    }
</script>

<style lang="scss">
.catalog-banners {
    overflow: hidden;
    margin-bottom: 34px;
    border-radius: 4px;

    .carousel__pagination {
        position: absolute;
        bottom: 6px;
        right: 10px;
    }

    .carousel__pagination-button {
        width: 8px;
        height: 8px;
        background: #fff;
    }

    .carousel__pagination-button--active {
        background: #4C4C4C;
    }
}

.catalog-banners__item {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.catalog-banners__wrapper {
    text-align: left;
    margin-top: 15px;
    margin-left: 30px;

    .btn {
        padding: 8px 20px;
        font-weight: bold;
    }
}

.catalog-banners__title {
    font-weight: bold;
    font-size: 20px;
    color: #1F2226;
}

.catalog-banners__description {
    font-size: 14px;
    color: #434A54;
    margin-bottom: 10px;
}

</style>
