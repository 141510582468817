import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import Cookies from "js-cookie";

const getDefaultState = () => {
    return {
        isFetching: false,
        sections: [],
        sliderSections: [],
        products: [],
        navigation: {},
        activeView: 'table',
        detailProduct: {},
        currentPage: 1,
        filters: {},
        activeFilters: {},
        sectionParent: null,
        section: null,
        sort: Cookies.get('sort') ? Cookies.get('sort') : null,
        sectionsCarousel: [],
        openFilters: [],
        viewed: [],
        productLamps: [],
        navigationLamps: {},
        isResetFilters: false,
        isAppendTo: false,
        lampsParams: {
            page: 1,
            sort: null,
            type: null,
            brand: null,
            isAppendTo: false,
        },
        typesLamps: [],
        brandsLamps: [],
        brand: null,
        brands: [],
    }
};

const state = getDefaultState();

export default {
    defaultState: getDefaultState(),
    state,
    mutations,
    getters,
    actions
};
