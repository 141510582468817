import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const getDefaultState = () => {
    return {
        items: [],
        recommendedItems: [],
        allItems: [],
        allRecommendedItems: [],
        backgrounds: [],
        selectedBackground: null,
        kitItems: [],
        viewItems: [],
        activeFilters: {},
        activeSections: [],
        sections: [],
        backgroundWidth: null,
        isRecommended: false,
        rooms: [
            {
                text: 'Гостиная',
                value: 'Гостиная',
            },
            {
                text: 'Кухня',
                value: 'Кухня',
            },
        ],
        selectedRoom: {
            text: 'Гостиная',
            value: 'Гостиная',
        }
    }
};

const state = getDefaultState();

export default {
    defaultState: getDefaultState(),
    state,
    mutations,
    getters,
    actions
};
