<template>
    <div
        class="fitting-viewed"
    >
        <div class="fitting-viewed__title">Ранее вы смотрели</div>

        <carousel
            class="fitting-viewed__wrapper"
            hideArrowsOnBound
        >
            <slide
                v-for="product in products"
                :key="product.id"
                class="fitting-viewed__slide"
            >
                <project-catalog-viewed-item :product="product" />
            </slide>
        </carousel>
    </div>
</template>

<script>
    import {Carousel, Slide} from 'vue-snap'
    import 'vue-snap/dist/vue-snap.css'
    import {onMounted, onUnmounted, ref} from 'vue'
    import ProjectModal from "@/components/project/ProjectModal"
    import ProjectCatalogViewedItem from "@/components/project/ProjectCatalogViewedItem";

    export default {
        components: {
            ProjectCatalogViewedItem,
            Carousel,
            Slide,
            ProjectModal
        },
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        setup(props) {
            const displayItemsCount = ref(9);

            onMounted(() => {
                window.addEventListener("resize", onResize);
            });

            onUnmounted(() => {
                window.removeEventListener("resize", onResize);
            });

            const onResize = () => {
                if (window.innerWidth <= 576) {
                    displayItemsCount.value = 4;
                    return;
                }
                if (window.innerWidth <= 768) {
                    displayItemsCount.value = 5;
                    return;
                }
                if (window.innerWidth <= 991) {
                    displayItemsCount.value = 7;
                    return;
                }
                displayItemsCount.value = 9;
            }

            onResize();

            return {
                displayItemsCount,
            }
        }
    }
</script>

<style lang="scss">

    .fitting-viewed {
        padding: 0 15px;
        margin: 0 -15px;
        margin-top: 20px;
        padding-bottom: 30px;
    }

    .fitting-viewed__wrapper {
        margin-bottom: 12px;

        .fitting-viewed__slide {
            flex: 0 0 33%
        }

        @media(min-width: 500px) {
            .fitting-viewed__slide {
                flex: 0 0 25%
            }
        }

        @media(min-width: 768px) {
            .fitting-viewed__slide {
                flex: 0 0 20%
            }
        }

        @media(min-width: 1024px) {
            .fitting-viewed__slide {
                flex: 0 0 12%
            }
        }

        @media(min-width: 1280px) {
            .fitting-viewed__slide {
                flex: 0 0 10%
            }
        }
    }

    .fitting-viewed__title {
        font-size: 20px;
        font-weight: bold;
        color: #434A54;
        margin-bottom: 12px;
    }

    .fitting-viewed-item {
        padding: 12px;
        background: #FFFFFF;
        border-radius: 4px;
        text-align: center;
        max-width: 100px;
        cursor: pointer;
        margin-right: 10px;
        box-shadow: 0px 2px 4px rgb(47 61 81 / 10%), 0px 5px 10px rgb(47 61 81 / 8%);
    }

    .fitting-viewed-item__title {
        margin-top: 6px;
        font-size: 13px;

        span {
            border-bottom: 1px dashed #4C4C4C;
        }

        &.active {
            span {
                border-bottom: none;
            }
        }
    }

    .fitting-viewed-item__image {
        width: 70px;
        height: 70px;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        line-height: 0;
        content: "";
    }

    .clearfix:after {
        clear: both;
    }
</style>
