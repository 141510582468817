<template>
    <ul
        v-if="breadcrumbs.length > 1"
        class="breadcrumbs noscroll-x"
    >
        <li
            v-for="(breadcrumb, i) in breadcrumbs"
            :key="i"
            class="breadcrumbs__elem"
        >
            <router-link
                v-if="breadcrumb !== breadcrumbs.at(-1)"
                :to="breadcrumb.url"
                class="breadcrumbs__link"
            >
                <span>{{ breadcrumb.title }}</span>
            </router-link>
            <div
                v-else
                class="breadcrumbs__link breadcrumbs__link--active"
            >
                <span>{{ breadcrumb.title }}</span>
            </div>
            <span class="breadcrumbs__line"></span>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            breadcrumbs: {
                type: Array,
                required: true
            },
        },
    }
</script>
