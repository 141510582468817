import axios from 'axios'
import Cookies from 'js-cookie'

export default {
    getSections({commit, getters}) {
        let url = process.env.VUE_APP_API_URL + '/catalog/sections/';
    
        if(getters.brand) {
            url += '?brand=' + getters['brand'];
        }
        
        return axios.get(url)
            .then(response => {
                if(response.data.data.length > 0) {
                    commit('setSections', response.data.data)
                }
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getProducts({state, commit, getters}, isAppendTo) {
        let url = process.env.VUE_APP_API_URL + '/catalog/items/',
            params = {};

        if(getters.section) {
            url += getters.section + '/'
        }

        if(getters['currentPage'] > 1) {
            params.page = getters['currentPage'];
        }
        
        if(getters.brand) {
            params.brand = getters['brand'];
        }

        if(getters['sort'] || Cookies.get('sort')) {
            params.sort = getters['sort'] ? getters['sort'] : Cookies.get('sort');
        }

        if(Object.keys(getters['activeFilters']).length) {
            for(let filterName in getters['activeFilters']) {
                params[filterName] = getters['activeFilters'][filterName];
            }
        }

        return axios.get(url, {params: params})
            .then(response => {
                if(getters.isAppendTo || isAppendTo) {
                    commit('productsAppendTo', response.data.data.items)
                    commit('setIsAppendTo', false)
                }
                else {
                    commit('setProducts', response.data.data.items)
                }

                commit('setNavigation', response.data.data.navigation)
                commit('setFilters', response.data.data.filters)
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getDetailProduct({commit}, code) {
        let url = process.env.VUE_APP_API_URL + '/catalog/detail/' + code + '/';
        commit('setDetailProduct', {})
        return axios.get(url)
            .then(response => {
                commit('setDetailProduct', response.data.data)
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getProductLamps({commit, getters}, code) {
        let url = process.env.VUE_APP_API_URL + '/catalog/detail/' + code + '/lamps/',
            params = {};

        let lampsParams = getters['lampsParams'];
        console.log('lampsParams', lampsParams)

        if(lampsParams.page > 1) {
            params.page = lampsParams.page;
        }
        if(lampsParams.sort && lampsParams.sort !== 'available') {
            params.sort = lampsParams.sort;
        }
        if(lampsParams.type && lampsParams.type !== 'all') {
            params.type = lampsParams.type;
        }
        if(lampsParams.brand && lampsParams.brand !== 'all') {
            params.brand = lampsParams.brand;
        }
    
        

        return axios.get(url, {params: params})
            .then(response => {
                if(response.data?.data?.items) {
                    if(lampsParams.isAppendTo) {
                        commit('lampsAppendTo', response.data.data.items)
                    }
                    else {
                        commit('setProductLamps', response.data.data.items)
                    }
                }
                else {
                    commit('setProductLamps', [])
                }
                if(response.data?.data?.navigation) {
                    commit('setNavigationLamps', response.data.data.navigation)
                }
                if(response.data?.data?.types) {
                    commit('setTypesLamps', response.data.data.types)
                }
                if(response.data?.data?.brands) {
                    commit('setBrandsLamps', response.data.data.brands)
                }
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getAvailable({commit}, code) {
        let url = process.env.VUE_APP_API_URL + '/catalog/stock/' + code + '/';

        return axios.get(url)
            .then(response => {
                return response.data.data
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getSectionsCarousel({commit}, sectionCode) {
        let url = process.env.VUE_APP_API_URL + '/catalog/sections/carousel/',
            params = {};

        if(sectionCode) {
            params.code = sectionCode;
        }
        return axios.post(url, params)
            .then(response => {
                commit('setSectionsCarousel', response.data.data)
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getViewedItems({commit}) {
        let url = process.env.VUE_APP_API_URL + '/catalog/viewed/';

        return axios.get(url)
            .then(response => {
                if(response.data.data.length > 0) {
                    commit('setViewed', response.data.data)
                }
            })
            .catch(error => console.log('Ошибка: ', error))

    },
    getBrands({commit}) {
        let url = process.env.VUE_APP_API_URL + '/catalog/brands/';
        
        return axios.get(url)
            .then(response => {
                if(response.data.data.length > 0) {
                    commit('setBrands', response.data.data)
                }
            })
            .catch(error => console.log('Ошибка: ', error))
        
    },
}

