<template>
    <Popper
        v-if="product.available_shops_count > 0"
        arrow
        @open:popper="getAvailable"
    >
        <button class="product-availability">
            В наличии в {{ product.available_shops_count }} {{
            getDecl(product.available_shops_count, ['магазине', 'магазинах', 'магазинах'])
            }}
        </button>
        <template #content="{ close }">
            <div
                v-if="Object.keys(available).length > 0"
                class="popper__wrapper popper--small"
            >
                <button
                    class="product-available__close close"
                    @click="close"
                >
                    <svg width="14"
                         height="14"
                         viewBox="0 0 14 14"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M13 1L1 13M1 1L13 13"
                              stroke="#788496"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
                <span class="product-available__title">
                    <svg width="20"
                         height="20"
                         viewBox="0 0 20 20"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 8.20948 19.5294 6.52887 18.7052 5.0751L9.13703 14.745L4.14404 10.0538L5.85589 8.23186L9.07343 11.2549L17.191 3.05096C15.3727 1.16974 12.823 0 10 0Z"
                              fill="#2AB930"
                        ></path>
                    </svg>
                    Товар в наличии
                </span>
                <div class="product-available__head">
                    <div
                        class="product-available-row"
                        v-for="(count, shopName) in available"
                        :key="shopName"
                    >
                        <span class="product-available-row__name">
                            <span>{{ shopName }}</span>
                        </span>
                        <span class="product-available-row__number">
                            {{ count }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </Popper>
</template>

<script>
    import IconBack from "@/components/icons/IconBack"
    import {ref} from 'vue'
    import store from '@/store/store'

    export default {
        components: {
            IconBack
        },
        props: {
            product: {
                type: Object,
                required: true
            },
        },
        setup(props) {
            const available = ref({});

            const getAvailable = async () => {
                available.value = await store.dispatch('catalog/getAvailable', props.product.code) || {};
                window.dispatchEvent(new Event('resize'))
            }

            return {
                getAvailable,
                available
            }
        }
    }
</script>
