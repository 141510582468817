<template>
    <div
        class="fitting-category"
        v-click-outside="hide"
    >
        <div
            class="fitting-category__button"
            @click="isActive = !isActive"

        >
            <span>Категория товара</span>
            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M1 7L4 4L1 1" stroke="#4C4C4C" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
        <div
            class="fitting-category__wrapper"
            v-if="isActive"
        >
            <fitting-category-group
                v-for="(section, i) in sections"
                :key="section.id"
                :sectionGroup="section"
                :ref="el => { if (el) groups[i] = el }"
            />
            <div class="fitting-category__actions">
                <div
                    class="btn btn--border"
                    @click="resetSections"
                >
                    Сбросить выбор
                </div>
                <div
                    class="btn btn--orange"
                    @click="setSections"
                >
                    Применить
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ref, computed, onBeforeUpdate} from 'vue'
    import store from '@/store/store'
    import FittingCategoryGroup from "@/components/fitting/FittingCategoryGroup"
    import {useRouter} from 'vue-router'

    export default {
        components: {
            FittingCategoryGroup
        },
        setup() {
            const router = useRouter();

            const sections = computed(() => {
                return store.getters['fitting/sections'];
            });

            const isActive = ref(false);

            const hide = () => {
                isActive.value = false;
            }

            const setSections = () => {
                let activeSections = store.getters['fitting/activeSections'];

                if(store.getters['fitting/isRecommended']) {
                    let items = store.getters['fitting/allRecommendedItems'];
                    if(activeSections.length > 0) {
                        items = items.filter(item => activeSections.includes(item.section_id));
                    }

                    store.commit('fitting/setRecommendedItems', items);
                }
                else {
                    let items = store.getters['fitting/allItems'];
                    if(activeSections.length > 0) {
                        items = items.filter(item => activeSections.includes(item.section_id));
                    }

                    store.commit('fitting/setItems', items);
                }
                //store.dispatch('fitting/getItems');
                //router.push({ query: store.getters['fitting/query']});
                hide();
            }

            const groups = ref([]);

            onBeforeUpdate(() => {
                groups.value = []
            })

            const resetSections = () => {
                store.commit('fitting/setActiveSections', []);
                store.dispatch('fitting/getItems');
                //router.push({ query: store.getters['fitting/query']});
                //groups.value.resetCheckedValues();

                groups.value.forEach(function(item) {
                    item.resetCheckedValues();
                });
                hide();
            }


            return {
                sections,
                isActive,
                hide,
                setSections,
                resetSections,
                groups
            };
        },
    }
</script>

<style>

</style>
