<template>
    <g>
        <path opacity="0.4" d="M1 1L8 8L1 15" :stroke="stroke" stroke-width="2" stroke-linecap="round"/>
    </g>
</template>

<script>
    export default {
        props: {
            stroke: {
                type: String,
            }
        }
    }
</script>
