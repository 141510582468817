<template>
    <div class="catalog catalog-brand">
        <div class="catalog-grid">
            <div
                class="catalog-sidebar"
            >
                <catalog-menu v-if="sections && sections.length > 0" :sections="sections"/>
                <catalog-buttons v-if="sections && sections.length > 0"/>
                <catalog-brands  v-if="brands && brands.length > 0" :brands="brands"/>
                <catalog-filters
                    v-if="!isEmptyFilters"
                    :filters="filters"
                    :activeFilter="activeFilter"
                    @closeFilter="onCloseFilter"
                />
            </div>
            <div
                class="catalog__right"
                v-if="!isFetching"
            >
                <breadcrumbs :breadcrumbs="breadcrumbs"/>
                <catalog-share :title="brand" />
                <catalog-top v-if="products.length > 0" :sorts="sorts" :filters="mainFilters" :selected-sort="selectedSort" show-filters/>
                <div class="catalog-products-filters">
                    <div
                        @click="activeFilter = !activeFilter"
                        class="btn btn--orange"
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.4495 1H2.55054C1.90061 1 1.52192 1.73396 1.89857 2.26362L5.8 7.75V13L8.2 11.875V7.75L12.1014 2.26362C12.4781 1.73396 12.0994 1 11.4495 1Z"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linejoin="round"
                            />
                        </svg>
                        <span>
                            Все фильтры
                        </span>
                    </div>
                    <div
                        class="btn btn--border"
                        @click="resetFilter"
                    >
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 1L1 9M1 1L9 9" stroke="#8B8B8B" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        <span>
                            Сбросить фильтры
                        </span>
                    </div>
                </div>
                <catalog-products v-if="products.length > 0" :products="products"/>
                <catalog-pagination v-if="products.length > 0" :navigation="navigation"/>
            </div>
            <div
                v-else
                class="catalog__preloader"
            >
                <preloader/>
            </div>
        </div>
    </div>
</template>

<script>
    import CatalogMenu from "@/components/catalog/CatalogMenu"
    import CatalogSectionsCarousel from "@/components/catalog/CatalogSectionsCarousel"
    import CatalogTop from "@/components/catalog/CatalogTop"
    import CatalogProducts from "@/components/catalog/CatalogProducts"
    import CatalogPagination from "@/components/catalog/CatalogPagination"
    import {useRoute, onBeforeRouteUpdate, useRouter} from 'vue-router'
    import store from '@/store/store'
    import {computed, onMounted, watch, ref} from 'vue'
    import CatalogFilters from "@/components/catalog/CatalogFilters"
    import Breadcrumbs from "@/components/Breadcrumbs"
    import CatalogButtons from "@/components/catalog/CatalogButtons"
    import CatalogBrands from "@/components/catalog/CatalogBrands";
    import Modal from "@/components/Modal"
    import CatalogShare from "@/components/catalog/CatalogShare";

    export default {
        components: {
            CatalogShare,
            CatalogBrands,
            Breadcrumbs,
            CatalogFilters,
            CatalogPagination,
            CatalogProducts,
            CatalogTop,
            CatalogSectionsCarousel,
            CatalogMenu,
            CatalogButtons,
            Modal
        },
        setup() {
            const route = useRoute();
            const router = useRouter();
            const isFetching = computed(() => {
                return store.getters['catalog/isFetching']
            });
            const brand = computed(() => {
                return store.getters['catalog/brand']
            });

            store.commit('catalog/setBrand', route.params.code);

            const breadcrumbs = ref([]);

            const fetchCatalog = async (newRoute, force) => {
                let sectionCode = null;

                store.commit('catalog/setBrand', newRoute.params.code);

                if (newRoute.params.sectionCode) {
                    sectionCode = newRoute.params.sectionCode;
                }
                store.commit('catalog/setSection', sectionCode);

                let activeFilters = {};
                Object.keys(newRoute.query).map((key, index) => {
                    let val = newRoute.query[key]

                    if (key === 'page') {
                        store.commit('catalog/setCurrentPage', val);
                    } else if (key === 'sort') {
                        store.commit('catalog/setSort', val);
                    } else {
                        activeFilters[key] = val
                    }
                });

                if(!newRoute.query['page']) {
                    store.commit('catalog/setCurrentPage', 1);
                }

                store.commit('catalog/setActiveFilters', activeFilters);

                await store.dispatch('catalog/getSections');

                if(store.getters['catalog/brands'].length === 0) {
                    await store.dispatch('catalog/getBrands');
                }

                breadcrumbs.value = [
                    {
                        'url': '/catalog/',
                        'title': 'Каталог товаров',
                    }
                ];
                if (newRoute.params.code) {
                    for (let i in store.getters['catalog/sections']) {
                        let section = store.getters['catalog/sections'][i];
                        if (section.code === newRoute.params.code) {
                            breadcrumbs.value.push({
                                'url': '/catalog/' + section.code + '/',
                                'title': section.title,
                            });
                            break;
                        }

                        for (let i2 in section.children) {
                            let childrenSection = section.children[i2];

                            if (childrenSection.code === newRoute.params.code) {
                                breadcrumbs.value.push({
                                    'url': '/catalog/section/' + section.code + '/',
                                    'title': section.title,
                                });

                                breadcrumbs.value.push({
                                    'url': '/catalog/section/' + childrenSection.code + '/',
                                    'title': childrenSection.title,
                                });

                                break;
                            }
                        }
                    }
                }

                await store.dispatch('catalog/getProducts');
                await store.dispatch('catalog/getSectionsCarousel', sectionCode);

                let filters = store.getters['catalog/filters'];

                catalogFilter:
                for(let i in filters) {
                    if(filters[i].items) {
                        for(let i2 in filters[i].items) {
                            let filterItem = filters[i].items[i2];

                            if(filterItem.code === 'COMM_CATALOG' && filterItem.checked) {
                                for(let i3 in filterItem.values) {
                                    let filterValue = filterItem.values[i3];

                                    if(filterValue.checked) {
                                        breadcrumbs.value.push({
                                            'title': filterValue.name,
                                        });

                                        break catalogFilter;
                                    }
                                }
                            }
                        }
                    }
                }

                store.commit('catalog/setIsFetching', false);
            }

            const isAppendTo = computed(() => {
                return store.getters['catalog/isAppendTo']
            });

            onBeforeRouteUpdate((to, from) => {
                store.commit('catalog/setIsFetching', !isAppendTo.value);
                fetchCatalog(to);
            })

            onMounted(() => {
                store.commit('catalog/setIsFetching', true);
                fetchCatalog(route, true);
            });

            const sections = computed(() => {
                return store.getters['catalog/sections']
            });
            const products = computed(() => {
                return store.getters['catalog/products']
            });
            const filters = computed(() => {
                return store.getters['catalog/filters']
            });
            const mainFilters = computed(() => {
                return store.getters['catalog/mainFilters']
            });
            const sectionsCarousel = computed(() => {
                return store.getters['catalog/sectionsCarousel']
            });
            const navigation = computed(() => {
                return store.getters['catalog/navigation']
            });
            const brands = computed(() => {
                return store.getters['catalog/brands']
            });

            const isEmptyFilters = ref(true);
            watch(filters, () => {
                isEmptyFilters.value = true;
                for (let code in filters.value) {
                    if (filters.value[code].items && filters.value[code].items.length > 0) {
                        isEmptyFilters.value = false;
                        break;
                    }
                }
            });

            const activeFilter = ref(false);

            watch(activeFilter, () => {
                if (window.innerWidth < 991) {
                    if (activeFilter.value) {
                        document.documentElement.classList.add('overflow-y-hidden');
                    } else {
                        document.documentElement.classList.remove('overflow-y-hidden');
                    }
                }

            });

            const onCloseFilter = () => {
                activeFilter.value = false;
            }

            const resetFilter = async () => {
                store.commit(route.meta.store + '/setActiveFilters', {});
                store.commit(route.meta.store + '/setIsFetching', true);
                await store.dispatch(route.meta.store + '/getProducts');
                store.commit(route.meta.store + '/setIsFetching', false);
                await router.push({query: store.getters[route.meta.store + '/query']});
            }

            const sorts = [
                {
                    text: 'Популярность',
                    value: 'popular',
                },
                {
                    text: 'Сначала дешевые',
                    value: 'price-asc',
                },
                {
                    text: 'Сначала дорогие',
                    value: 'price-desc',
                },
            ];

            let selectedSort = computed(() => {
                let index = sorts.findIndex(d => d.value === store.getters['catalog/sort']);

                if(index > -1) {
                    return sorts[index];
                }
                else {
                    return sorts[0]
                }
            });

            return {
                sections,
                products,
                filters,
                isEmptyFilters,
                sectionsCarousel,
                isFetching,
                navigation,
                activeFilter,
                onCloseFilter,
                resetFilter,
                breadcrumbs,
                sorts,
                selectedSort,
                mainFilters,
                brand,
                brands,
            };
        }
    }
</script>

<style>

</style>
