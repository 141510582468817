<template>
    <div class="fitting-catalog">
        <div class="fitting-catalog__header">
            <div
                class="btn btn--gray"
                @click="deleteAll"
            >
                <svg width="12"
                     height="16"
                     viewBox="0 0 12 16"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                >
                    <path fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.08366 0.5L8.91699 1.33333H11.8337V3H0.166992V1.33333H3.08366L3.91699 0.5H8.08366ZM1.00033 13.8333C1.00033 14.75 1.75033 15.5 2.66699 15.5H9.33366C10.2503 15.5 11.0003 14.75 11.0003 13.8333V3.83333H1.00033V13.8333ZM2.66699 5.5H9.33366V13.8333H2.66699V5.5Z"
                          fill="#fff"
                    />
                </svg>

                <span>Очистить все</span>
            </div>
        </div>
    </div>

    <div class="fitting-catalog__wrapper">
        <div
            class="fitting-catalog__items"
            v-dragscroll
        >
            <fitting-catalog-card
                v-for="product in products"
                :key="product.id"
                :product="product"
            />
        </div>

    </div>
</template>

<script>
    import {ref, computed, onMounted} from 'vue'
    import store from '@/store/store'
    import FittingCatalogCard from "@/components/fitting/FittingCatalogCard"

    export default {
        components: {FittingCatalogCard},
        props: {
            products: {
                type: Array,
                required: true
            }
        },
        setup() {
            const deleteAll = () => {
                store.dispatch('fitting/removeAllItems');
                store.commit('fitting/removeAllItems');
            }
            return {
                deleteAll
            };
        }
    }
</script>

<style>

</style>
