import axios from 'axios'

export default {
    getProducts({state, commit, getters}, isAppendTo) {
        let url = process.env.VUE_APP_API_URL + '/catalogs/',
            params = {};

        if(getters['currentPage'] > 1) {
            params.page = getters['currentPage'];
        }

        if(getters['sort']) {
            params.sort = getters['sort'];
        }

        if(Object.keys(getters['activeFilters']).length) {
            for(let filterName in getters['activeFilters']) {
                params[filterName] = getters['activeFilters'][filterName];
            }
        }

        return axios.get(url, {params: params})
            .then(response => {
                if(getters.isAppendTo || isAppendTo) {
                    commit('productsAppendTo', response.data.data.items)
                    commit('setIsAppendTo', false)
                }
                else {
                    commit('setProducts', response.data.data.items || []);
                }

                commit('setNavigation', response.data.data.navigation || {});
                commit('setFilters', response.data.data.filters || []);
            })
            .catch(error => console.log('Ошибка: ', error))
    },
}

