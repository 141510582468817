<template>
    <div class="preloader">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
        <div class="dot dot3"></div>
    </div>
</template>

<script>

</script>

<style lang="scss">
    .preloader {
        position: relative;
        vertical-align: middle;
    }

    .preloader .dot {
        width: 10px;
        height: 10px;
        background: var(--orange);
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 5px);
    }

    .preloader .dot1 {
        left: 0px;
        -webkit-animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }

    .preloader .dot2 {
        left: 20px;
        -webkit-animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }

    .preloader .dot3 {
        left: 40px;
        -webkit-animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
        animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }

    @-webkit-keyframes dot-jump {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
        }
    }

    @keyframes dot-jump {
        0% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
        }
        100% {
            -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
        }
    }
</style>
