<template>
    <checkbox
        v-for="aggregation in aggregations"
        :key="isCustom ? aggregation.value : aggregation.control_id"
        :fieldId="isCustom ? aggregation.value : aggregation.control_id"
        v-model:values="checkedValues"
        :value="isCustom ? aggregation.value.toString() : aggregation.control_id.toString()"
        :title="title || aggregation.name"
        :disabled="!!aggregation.disabled"
        :class="{hide: searchQuery !== '' && !aggregation.name.toLowerCase().includes(searchQuery.toLowerCase())}"
    />
</template>

<script>
    import Checkbox from "@/components/form/Checkbox"
    import {ref, watch, computed} from 'vue'
    import store from '@/store/store'
    import {useRoute, useRouter} from 'vue-router'
    import Cookies from 'js-cookie'

    export default {
        components: {
            Checkbox
        },
        props: {
            aggregations: {
                type: Array,
                required: true
            },
            isCustom: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            searchQuery: {
                type: String,
                default: ''
            },
        },
        setup(props) {
            const router = useRouter();
            const route = useRoute();

            const checkedValues = ref([]);
            let activeFilters = store.getters[route.meta.store + '/activeFilters'];

            const isResetFilters = computed(() => {
                return store.getters[route.meta.store + '/isResetFilters'];
            });

            watch(isResetFilters, (value) => {
                if(value) {
                    checkedValues.value = [];
                    store.commit(route.meta.store + '/setIsResetFilters', false);
                }
            })

            if(props.isCustom) {
                if (activeFilters[props.aggregations[0].param]) {
                    checkedValues.value = activeFilters[props.aggregations[0].param].split(',');
                }
            }
            else {
                props.aggregations.forEach(function (item) {
                    if (activeFilters[item.control_id]) {
                        checkedValues.value.push(item.control_id)
                    }
                });
            }

            let isAvailableRemoved = false;
            watch(checkedValues, async (newCheckedValues) => {
                activeFilters = store.getters[route.meta.store + '/activeFilters'];

                if(props.isCustom) {
                    let paramCode = props.aggregations[0].param;
                    if(newCheckedValues.length > 0) {
                        activeFilters[paramCode] = newCheckedValues.join(',');
                    }
                    else {
                        delete activeFilters[paramCode];
                    }
                }
                else {
                    props.aggregations.forEach(function(item) {
                        if(newCheckedValues.includes(item.control_id)) {
                            activeFilters[item.control_id] = 'Y';
                            if(item.control_id === 'available') {
                                Cookies.set('available', 'Y', { expires: 365 * 5 });
                            }
                        }
                        else {
                            delete activeFilters[item.control_id];
                            if(item.control_id === 'available') {
                                Cookies.remove('available');
                                isAvailableRemoved = true;
                            }
                        }
                    });
                }

                await store.commit(route.meta.store + '/setActiveFilters', activeFilters);
                if(isAvailableRemoved && !route.query['available']) {
                    store.commit(route.meta.store + '/setIsFetching', true);
                    await store.dispatch(route.meta.store + '/getProducts');
                    store.commit(route.meta.store + '/setIsFetching', false);
                }
                /*store.commit(route.meta.store + '/setIsFetching', true);
                await store.dispatch(route.meta.store + '/getProducts');
                store.commit(route.meta.store + '/setIsFetching', false);*/
                router.push({ query: store.getters[route.meta.store + '/query']});
            });

            return {
                checkedValues,
            };
        },
    }
</script>
