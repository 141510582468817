<template>
    <div class="header">
        <div class="header__container container">
            <router-link
                :to="generatePartnerRedirectUrl('/catalog/')"
                class="header-logo"
            >
                <img :src="getWebUrl() + (getPartner().includes('amperkin.ru') ? '/src/assets/images/logo_amperkin.svg' : '/src/assets/images/logo.svg')" alt="">
            </router-link>
            <header-search/>
            <div class="header-menu">
                <router-link
                    :to="generatePartnerRedirectUrl('/project/')"
                    class="header-fitting__link"
                >
                    <svg width="18" height="15" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.399994 0.999994C0.399994 0.668623 0.668623 0.399994 0.999994 0.399994H13C13.3314 0.399994 13.6 0.668623 13.6 0.999994C13.6 1.33136 13.3314 1.59999 13 1.59999H0.999994C0.668623 1.59999 0.399994 1.33136 0.399994 0.999994Z"
                            fill="#4C4C4C"/>
                        <path
                            d="M0.399994 4.99999C0.399994 4.66862 0.668623 4.39999 0.999994 4.39999H6.33333C6.6647 4.39999 6.93333 4.66862 6.93333 4.99999C6.93333 5.33136 6.6647 5.59999 6.33333 5.59999H0.999994C0.668623 5.59999 0.399994 5.33136 0.399994 4.99999Z"
                            fill="#4C4C4C"/>
                        <path
                            d="M0.999994 8.39999C0.668623 8.39999 0.399994 8.66862 0.399994 8.99999C0.399994 9.33137 0.668623 9.59999 0.999994 9.59999H9.66666C9.99803 9.59999 10.2667 9.33137 10.2667 8.99999C10.2667 8.66862 9.99803 8.39999 9.66666 8.39999H0.999994Z"
                            fill="#4C4C4C"/>
                    </svg>
                    <span class="header-fitting__name">Проекты</span>
                    <span class="header-fitting__number">
                        <span>(</span>
                        {{ projectCount }}
                        <span>)</span>
                    </span>
                </router-link>
                <router-link
                    :to="generatePartnerRedirectUrl('/fitting/')"
                    class="header-fitting__link"
                >
                    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.9754 12.3665C17.9754 11.3034 17.2618 10.3346 16.1416 9.95844L9.80603 6.77866L9.78576 5.05854C10.9991 4.74957 11.8928 3.75649 11.8928 2.57984C11.8928 1.15726 10.591 0 8.99065 0C8.20294 0 7.11277 0.541393 6.52111 1.33737C6.373 1.53516 6.35975 1.79598 6.48681 2.00554C6.61349 2.21545 6.85904 2.35227 7.12758 2.35227H7.14083C7.39184 2.35227 7.62336 2.22619 7.74691 2.03187C8.00728 1.62002 8.5545 1.33287 8.9922 1.33287C9.76666 1.33287 10.3977 1.8947 10.3977 2.58227C10.3977 3.27156 9.76666 3.83235 8.9922 3.83235H8.9887C8.80434 3.83235 8.627 3.89574 8.49799 4.01178C8.36819 4.12851 8.29531 4.28542 8.29765 4.44995L8.31519 6.77797L1.8502 9.95844C0.731194 10.3346 0 11.302 0 12.3658V12.7769C0 13.9695 1.04963 15 2.39119 15H15.5959C16.9363 15 18 13.9751 18 12.7828L17.9754 12.3665ZM16.5049 12.7766C16.5049 13.2352 16.1104 13.6599 15.5952 13.6599H2.39119C1.87593 13.6599 1.48772 13.2352 1.48772 12.7766V12.3651C1.48772 11.8511 1.83539 11.3838 2.3756 11.203L9.00117 7.97229L15.6178 11.203C16.1592 11.3838 16.5045 11.8511 16.5045 12.3651L16.5049 12.7766Z"
                              fill="#4C4C4C"
                        />
                    </svg>
                    <span class="header-fitting__name">Примерка</span>
                    <span class="header-fitting__number">
                        <span>(</span>
                        {{ itemsCount }}
                        <span>)</span>
                    </span>
                </router-link>
            </div>
            <div class="header-button">
                <div
                    class="btn btn--gray"
                    @click="showModal"
                >
                    Задать вопрос
                </div>
                <Modal
                    v-show="isModalVisible"
                    @close="closeModal"
                    small
                >
                    <template v-slot:header>
                        Задать вопрос
                    </template>
                    <template v-slot:body>
                        <div
                            v-if="isFeedbackSubmit"
                            class="feedback-success"
                        >
                            <svg width="32"
                                 height="32"
                                 viewBox="0 0 32 32"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M26.7845 23.1259L22.7003 19.0414C21.8501 18.1912 20.4717 18.1903 19.6206 19.0414L18.4312 20.2309C15.7134 18.5711 13.4301 16.2876 11.7703 13.5696L13.0745 12.2654C13.9235 11.4162 13.9235 10.0345 13.0745 9.18541L8.99031 5.10093C8.14255 4.25322 6.76612 4.24534 5.90807 5.1035L3.34159 7.69014C2.11364 8.7213 1.68172 10.4143 2.24172 12.0189C5.11532 20.2542 11.7472 26.8865 19.9818 29.7603C21.5311 30.3009 23.122 29.8966 24.1336 28.8543C24.1482 28.8414 24.1625 28.8281 24.1764 28.8141L26.7845 26.2058C27.6335 25.3567 27.6335 23.9751 26.7845 23.1259ZM25.4834 24.9047L22.9343 27.454C22.9032 27.4798 22.8738 27.5078 22.8461 27.5379C22.3096 28.1212 21.4465 28.3226 20.5881 28.0229C12.8784 25.3323 6.66943 19.1229 3.97903 11.4125C3.67097 10.5297 3.89322 9.61512 4.54526 9.08237C4.57003 9.06213 4.59377 9.04058 4.6163 9.01789L7.21171 6.40205C7.29773 6.31596 7.3983 6.30311 7.45053 6.30311C7.50281 6.30311 7.60327 6.31591 7.68935 6.40205L11.7734 10.4865C11.9051 10.6182 11.9051 10.8324 11.7735 10.9641L9.96643 12.7713C9.67434 13.0634 9.61271 13.5142 9.8156 13.8739C11.7766 17.3506 14.6505 20.2248 18.1269 22.1858C18.4866 22.3888 18.9374 22.327 19.2294 22.035L20.9217 20.3426C21.0535 20.2108 21.2674 20.2107 21.3993 20.3426L25.4835 24.4271C25.6151 24.5588 25.6151 24.773 25.4834 24.9047Z"
                                      fill="#FD7621"
                                />
                                <path d="M16.7055 9.18168C16.1974 9.18168 15.7855 9.59361 15.7855 10.1018C15.7855 10.6099 16.1974 11.0218 16.7055 11.0218C19.0618 11.0218 20.9788 12.9389 20.9788 15.2954C20.9788 15.8035 21.3907 16.2154 21.8988 16.2154C22.4069 16.2154 22.8188 15.8035 22.8188 15.2954C22.8188 11.9243 20.0764 9.18168 16.7055 9.18168Z"
                                      fill="#FD7621"
                                />
                                <path d="M16.7055 5.57052C16.1974 5.57052 15.7855 5.98246 15.7855 6.4906C15.7855 6.99874 16.1974 7.41067 16.7055 7.41067C21.0529 7.41067 24.5897 10.9477 24.5897 15.2954C24.5897 15.8035 25.0016 16.2155 25.5097 16.2155C26.0178 16.2155 26.4297 15.8035 26.4297 15.2954C26.4297 9.9331 22.0674 5.57052 16.7055 5.57052Z"
                                      fill="#FD7621"
                                />
                                <path d="M26.1061 5.89409C23.5952 3.38293 20.2566 2 16.7055 2C16.1974 2 15.7855 2.41194 15.7855 2.92008C15.7855 3.42821 16.1974 3.84015 16.7055 3.84015C23.0216 3.84015 28.16 8.97897 28.16 15.2955C28.16 15.8036 28.5719 16.2155 29.08 16.2155C29.5881 16.2155 30 15.8036 30 15.2955C29.9999 11.7441 28.6171 8.40531 26.1061 5.89409Z"
                                      fill="#FD7621"
                                />
                            </svg>
                            <span>
                                Звонок заказан
                            </span>
                        </div>
                        <div
                            v-else
                            class="feedback-form"
                        >
                            <div class="feedback-form__group">
                                <input
                                    type="text"
                                    class="feedback-form__input"
                                    :class="{error: nameError}"
                                    placeholder="Имя"
                                    v-model="name"
                                    @input="nameError = false"
                                >
                                <span
                                    v-if="nameError"
                                    class="feedback-form__error"
                                >
                                    Введите имя
                                </span>
                            </div>
                            <div class="feedback-form__group">
                                <input
                                    type="tel"
                                    class="feedback-form__input"
                                    :class="{error: phoneError}"
                                    placeholder="Телефон"
                                    v-model="phone"
                                    @input="phoneError = false"
                                    v-maska="'+7 (###) ###-##-##'"
                                >
                                <span
                                    v-if="phoneError"
                                    class="feedback-form__error"
                                >
                                    Введите телефон
                                </span>
                            </div>
                            <div class="feedback-form__group">
                                <select
                                    required
                                    class="feedback-form__select"
                                    :class="{error: cityError}"
                                    v-model="city"
                                >
                                    <option selected :value="'Москва (Центральный и Приволжский ФО)'">
                                        Москва (Центральный и Приволжский ФО)
                                    </option>
                                    <option value="Санкт-Петербург (и Северо-Западный ФО)">
                                        Санкт-Петербург (и Северо-Западный ФО)
                                    </option>
                                    <option value="Екатеринбург (и Уральский ФО)">
                                        Екатеринбург (и Уральский ФО)
                                    </option>
                                    <option value="Новосибирск (Сибирский и Дальневосточный ФО)">
                                        Новосибирск (Сибирский и Дальневосточный ФО)
                                    </option>
                                    <option value="Ростов-на-Дону (Южный и Северо-Кавказский ФО)">
                                        Ростов-на-Дону (Южный и Северо-Кавказский ФО)
                                    </option>
                                </select>
                                <span
                                    v-if="cityError"
                                    class="feedback-form__error"
                                >
                                    Введите город или федеральный округ
                                </span>
                            </div>
                            <div class="feedback-form__group">
                                <textarea
                                    class="feedback-form__textarea"
                                    v-model="text"
                                    rows="10"
                                    placeholder="Сообщение"
                                ></textarea>
                            </div>
                            <div
                                class="btn btn--orange"
                                @click="feedbackSubmit"
                            >
                                Заказать звонок
                            </div>
                            <div class="feedback-form__footer">
                                Нажимая кнопку «Заказать звонок», Вы соглашаетесь с условиями <a href="/" target="_blank">политики
                                конфиденциальности</a>
                            </div>
                        </div>
                    </template>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script>
    import IconSearch from '@/components/icons/IconSearch'
    import IconHanger from "@/components/icons/IconHanger"
    import {computed, ref} from 'vue'
    import store from '@/store/store'
    import HeaderSearch from "@/components/header/HeaderSearch"
    import Modal from "@/components/Modal"
    import axios from 'axios'
    import {maska} from 'maska'

    export default {
        directives: {maska},
        components: {
            Modal,
            HeaderSearch,
            IconHanger,
            IconSearch,
        },
        setup() {
            const itemsCount = computed(() => {
                return store.getters['fitting/items'].length
            });
            const projectCount = computed(() => {
                return store.getters['project/projects'].length
            });

            const isModalVisible = ref(false);

            const name = ref('');
            const phone = ref('');
            const city = ref('Москва (Центральный и Приволжский ФО)');
            const text = ref('');
            const nameError = ref(false);
            const phoneError = ref(false);
            const cityError = ref(false);

            const showModal = () => {
                isModalVisible.value = true
            }

            const closeModal = () => {
                isModalVisible.value = false
            }

            const isFeedbackSubmit = ref(false);
            const feedbackSubmit = () => {
                if (!name.value) {
                    nameError.value = true;
                }
                if (phone.value.replace(/[^.\d]+/g, "").substring(1).length !== 10) {
                    phoneError.value = true;
                }
                if (city.value === '') {
                    cityError.value = true;
                }
                if (nameError.value || phoneError.value || cityError.value) {
                    return;
                }

                axios.post(process.env.VUE_APP_API_URL + '/feedback/', {
                    name: name.value,
                    phone: phone.value,
                    city: city.value,
                    text: text.value,
                    partner: window.axios.defaults.headers.common['partner']
                })
                    .then(response => {
                        isFeedbackSubmit.value = true;
                    })
                    .catch(error => console.log('Ошибка: ', error))
                isFeedbackSubmit.value = true;
            }

            return {
                itemsCount,
                isModalVisible,
                showModal,
                closeModal,
                feedbackSubmit,
                name,
                phone,
                city,
                text,
                nameError,
                phoneError,
                cityError,
                isFeedbackSubmit,
                projectCount
            }
        }
    }
</script>

<style lang="scss">

</style>
