import Cookies from 'js-cookie'

export default {
	setIsFetching(state, isFetching) {
		state.isFetching = isFetching
	},
	setSections(state, sections) {
		state.sections = sections
	},
	setProducts(state, products) {
		state.products = products
	},
	productsAppendTo(state, products) {
		state.products = [...state.products, ...products];
	},
	setNavigation(state, navigation) {
		state.navigation = navigation
	},
	setActiveView(state, activeView) {
		state.activeView = activeView
	},
	setDetailProduct(state, product) {
		state.detailProduct = product
	},
	setCurrentPage(state, currentPage) {
		state.currentPage = currentPage
	},
	setSectionParent(state, sectionParent) {
		state.sectionParent = sectionParent
	},
	setSection(state, section) {
		state.section = section
	},
	setSort(state, sort) {
		Cookies.set('sort', sort, {expires: 365 * 5});
		state.sort = sort
	},
	setFilters(state, filters) {
		const imgFilter = filters.IMG
		const videoFilter = filters.VIDEO
		
		filters = Object.values(filters);
		
		if (imgFilter || videoFilter) {
			filters = filters.filter(item => item.code !== "IMG" && item.code !== "VIDEO");
			if (videoFilter) filters.unshift(videoFilter);
			if (imgFilter) filters.unshift(imgFilter);
		}
		
		filters.unshift({
			"title": "",
			"items": [
				{
					"id": "available",
					"code": "available",
					"name": "Есть в наличии",
					"display_type": "F",
					"hide_title": true,
					"values": [
						{
							"control_id": "available",
							"name": "Есть в наличии",
							"value": 'y',
							"disabled": null,
							"hide_title": true,
							"checked": Cookies.get('available') === 'Y',
						}
					],
					"checked": Cookies.get('available') === 'Y'
				}
			]
		});
		
		for (const i1 in filters) {
			if (filters[i1]?.items.length > 0) {
				filters[i1].items = filters[i1]?.items.filter(function (item) {
					return !(item.display_type === 'A' && item?.values[0]?.value === 0 && item?.values[1]?.value === 0)
				});
			}
		}
		
		state.filters = filters
	},
	setActiveFilters(state, filters) {
		if (Cookies.get('available') === 'Y') {
			filters['available'] = 'Y'
		}
		
		state.activeFilters = filters
	},
	addToFitting(state, productId) {
		if (productId) {
			let index = state.products.findIndex(d => d.id === productId);
			
			state.products[index].is_in_fitting = true;
		} else {
			state.detailProduct.is_in_fitting = true;
		}
	},
	addToFittingFromViewed(state, id) {
		let index = state.viewed.findIndex(d => d.id === id);
		
		state.viewed[index].is_in_fitting = true;
	},
	addToProjectFromViewed(state, id) {
		let index = state.viewed.findIndex(d => d.id === id);
		
		state.viewed[index].is_in_project = true;
	},
	setSectionsCarousel(state, sections) {
		for (let i in sections) {
			let url = sections[i].url;
			var query = url.split('?').pop();
			
			if (window.location.href.includes(query)) {
				sections[i].active = true;
			}
			
		}
		state.sectionsCarousel = sections
	},
	setOpenFilters(state, filters) {
		state.openFilters = filters
	},
	setViewed(state, items) {
		state.viewed = items
	},
	setProductLamps(state, productLamps) {
		state.productLamps = productLamps
	},
	setNavigationLamps(state, navigationLamps) {
		state.navigationLamps = navigationLamps
	},
	lampsAppendTo(state, products) {
		state.productLamps = [...state.productLamps, ...products];
	},
	setIsResetFilters(state, isResetFilters) {
		state.isResetFilters = isResetFilters
	},
	setIsAppendTo(state, isAppendTo) {
		state.isAppendTo = isAppendTo
	},
	setLampsParams(state, params) {
		if (params.page) {
			state.lampsParams.page = params.page;
		}
		state.lampsParams.isAppendTo = !!params.isAppendTo;
		if (params.sort) {
			state.lampsParams.sort = params.sort;
		}
		if (params.type) {
			state.lampsParams.type = params.type;
		}
		if (params.brand) {
			state.lampsParams.brand = params.brand;
		}
	},
	setTypesLamps(state, types) {
		state.typesLamps = types
	},
	setBrandsLamps(state, brands) {
		state.brandsLamps = brands
	},
	addToProject(state, productId) {
		if (productId) {
			let index = state.products.findIndex(d => d.id === productId);
			
			if(index > -1) {
				state.products[index].is_in_project = true;
			}
		} else if(state.detailProduct) {
			state.detailProduct.is_in_project = true;
		}
	},
	setBrand(state, brand) {
		state.brand = brand
	},
	setBrands(state, brands) {
		state.brands = brands
	},
}
