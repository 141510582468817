import axios from 'axios'

export default {
    getProducts({state, commit, getters}, isAppendTo) {
        let url = process.env.VUE_APP_API_URL + '/search/',
            params = {};

        if(getters['q']) {
            params.q = getters['q'];

            if(getters['currentPage'] > 1) {
                params.page = getters['currentPage'];
            }

            if(getters['sort']) {
                params.sort = getters['sort'];
            }

            if(Object.keys(getters['activeFilters']).length) {
                for(let filterName in getters['activeFilters']) {
                    params[filterName] = getters['activeFilters'][filterName];
                }
            }

            return axios.get(url, {params: params})
                .then(response => {
                    if(getters.isAppendTo || isAppendTo) {
                        commit('productsAppendTo', response.data.data.items)
                        commit('setIsAppendTo', false)
                    }
                    else {
                        commit('setProducts', response.data.data.items || []);
                    }

                    commit('setSections', response.data.data.sections || []);
                    commit('setQueries', response.data.data.queries || []);
                    commit('setItemsCount', response.data.data.items_count || 0);
                    commit('setNavigation', response.data.data.navigation || {});
                    commit('setFilters', response.data.data.filters || {});
                })
                .catch(error => console.log('Ошибка: ', error))
        }
    },
    searchPreview({commit, getters}, query) {
        let url = process.env.VUE_APP_API_URL + '/search/preview/';

        return axios.get(url, {params: {q: query}})
            .then(response => {
                return response.data.data;
            })
            .catch(error => console.log('Ошибка: ', error))

    },
}

