<template>
    <div
        class="fitting-category__block"
        :class="{active: isOpen}"
    >
        <div
            class="fitting-category__top"
            @click="toggleGroup"
        >
            <div
                class="fitting-category__title"
            >
                <div
                    @click="resetCheckedValues()"
                    class="fitting-category__icon"
                >
                    <img v-if="sectionGroup.icon" :src="getWebUrl() + sectionGroup.icon">
                </div>
                <div
                    v-if="sectionGroup.children && sectionGroup.children.length > 0"
                    class="fitting-category__label"
                >
                    <div
                        class="fitting-category__tree"
                        :class="{active: checkedAll}"
                        @click.stop="checkChildren"
                    ></div>
                    <span>{{ sectionGroup.title }}</span>
                </div>

                <checkbox
                    v-else
                    :fieldId="sectionGroup.id"
                    v-model:values="checkedValues"
                    :value="sectionGroup.id.toString()"
                    :title="sectionGroup.title"
                    :disabled="false"
                />
            </div>
            <div
                v-if="sectionGroup.children && sectionGroup.children.length > 0"
                class="fitting-category__arrow"
            >
                <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M1 7L4 4L1 1" stroke="#4C4C4C" stroke-width="2" stroke-linecap="round"/>
                </svg>

            </div>
        </div>
        <slide-up-down v-model="isOpen" :duration="300">
            <div
                v-if="sectionGroup.children && sectionGroup.children.length > 0"
                class="fitting-category__body"
            >
                <checkbox
                    v-for="sectionChildren in sectionGroup.children"
                    :key="sectionChildren.id"
                    :fieldId="sectionChildren.id"
                    v-model:values="checkedValues"
                    :value="sectionChildren.id.toString()"
                    :title="sectionChildren.title"
                    :disabled="false"
                />
            </div>
        </slide-up-down>
    </div>
</template>

<script>
    import {ref, watch } from 'vue'
    import IconArrowRight from "@/components/icons/IconArrowRight"
    import store from '@/store/store'
    import Checkbox from "@/components/form/Checkbox"
    import {useRouter} from 'vue-router'

    export default {
        emits: ["resetChecked"],
        components: {
            Checkbox,
            IconArrowRight
        },
        props: {
            sectionGroup: {
                type: Object,
                required: true
            }
        },
        setup(props) {
            const router = useRouter();
            const isOpen = ref(false);
            const checkedAll = ref(false);
            const toggleGroup = () => {
                isOpen.value = !isOpen.value
            };

            const checkedValues = ref([]);
            let activeSections = store.getters['fitting/activeSections'];

            if(activeSections.includes(props.sectionGroup.id)) {
                isOpen.value = true;
                checkedValues.value.push(props.sectionGroup.id)
            }

            props.sectionGroup.children.forEach(function(item) {
                if(activeSections.includes(item.id)) {
                    isOpen.value = true;
                    checkedValues.value.push(item.id)
                }
            });

            watch(checkedValues, (newCheckedValues) => {
                let item = props.sectionGroup;

                const index = activeSections.indexOf(item.id);

                if(newCheckedValues.includes(item.id)) {
                    if (index === -1) {
                        activeSections.push(item.id);
                    }
                }
                else {
                    if (index > -1) {
                        activeSections.splice(index, 1);
                    }
                }

                props.sectionGroup.children.forEach(function(item) {
                    const index = activeSections.indexOf(item.id);

                    if(newCheckedValues.includes(item.id)) {
                        if (index === -1) {
                            activeSections.push(item.id);
                        }
                    }
                    else {
                        if (index > -1) {
                            activeSections.splice(index, 1);
                        }
                    }
                });

                let ids = props.sectionGroup.children.map(function (section) {
                    return section.id;
                });

                if(checkedValues.value.length === ids.length) {
                    checkedAll.value = true;
                }
                else {
                    checkedAll.value = false;
                }

                store.commit('fitting/setActiveSections', activeSections);
                /*store.dispatch('fitting/getProducts');
                router.push({ query: store.getters['fitting/query']});*/
            });

            const resetCheckedValues = () => {
                checkedValues.value = [];
                isOpen.value = false;
            }

            const checkChildren = () => {
                isOpen.value = true;

                let ids = props.sectionGroup.children.map(function (section) {
                    return section.id;
                });

                if(checkedValues.value.length === ids.length) {
                    checkedValues.value = [];
                    checkedAll.value = true;
                }
                else {
                    checkedValues.value = ids;
                    checkedAll.value = false;
                }
            }

            return {
                toggleGroup,
                isOpen,
                checkedValues,
                resetCheckedValues,
                checkChildren,
                checkedAll
            }
        },
    }
</script>

<style lang="scss">

</style>
