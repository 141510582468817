export default {
    isFetching: state => state.isFetching,
    products: state => state.products,
    navigation: state => state.navigation,
    activeView: state => state.activeView,
    currentPage: state => state.currentPage,
    sort: state => state.sort,
    filters: state => state.filters,
    activeFilters: state => state.activeFilters,
    query: (state, getters) => {
        let values = {};

        if (getters.q) {
            values['q'] = getters.q;
        }
        if (getters.currentPage > 1) {
            values['page'] = getters.currentPage;
        }
        if (getters.sort) {
            values['sort'] = getters.sort;
        }
        Object.keys(getters.activeFilters).map((key) => {
            if (getters.activeFilters[key]) {
                values[key] = getters.activeFilters[key];
            }
        });

        return values
    },
    openFilters: state => state.openFilters,
    isResetFilters: state => state.isResetFilters,
    isAppendTo: state => state.isAppendTo,
}
