<template>
    <div class="lamp-filters">
        <div class="lamp-filters__item">
            <span class="lamp-filters__title">Тип лампы:</span>
            <form-select
                :options="typesSelect"
                v-model="selectedType"
                @select="setType"
            />
        </div>
        <div class="lamp-filters__item">
            <span class="lamp-filters__title">Бренд:</span>
            <form-select
                :options="brandsSelect"
                v-model="selectedBrand"
                @select="setBrand"
            />
        </div>
    </div>
</template>

<script>
    import FormSelect from '@/components/form/Select'
    import store from '@/store/store'
    import { computed, reactive, toRefs } from 'vue'
    import { useRoute } from 'vue-router'

    export default {
        components: {
            FormSelect
        },
        setup(props) {
            const route = useRoute();

            const typesLamps = computed(() => store.getters['catalog/typesLamps']);
            const brandsLamps = computed(() => store.getters['catalog/brandsLamps']);

            const state = reactive({
                selectedType: 'all',
                selectedBrand: 'all',
                typesSelect: [{ text: 'Все', value: 'all' }],
                brandsSelect: [{ text: 'Все', value: 'all' }]
            });

            typesLamps.value.forEach(type => {
                state.typesSelect.push({ text: type, value: type });
            });

            brandsLamps.value.forEach(brand => {
                state.brandsSelect.push({ text: brand, value: brand });
            });

            const setType = (type) => {
                state.selectedType = type.value;
                updateFilters();
            };

            const setBrand = (brand) => {
                state.selectedBrand = brand.value;
                updateFilters();
            };

            const updateFilters = () => {
                store.commit(route.meta.store + '/setLampsParams', {
                    type: state.selectedType,
                    brand: state.selectedBrand
                });
                store.dispatch('catalog/getProductLamps', route.params.code);
            };

            return {
                ...toRefs(state),
                setType,
                setBrand
            }
        }
    }
</script>



<style lang="scss">
    .lamp-filters {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        justify-content: space-between;
        grid-gap: 12px;
    }

    .lamp-filters__wrap {
        display: block;
    }

    .lamp-filters__title {
        display: block;
        font-size: 12px;
        line-height: 14px;
        color: #434A54;
        margin-bottom: 12px;
    }

    .catalog-products-select {
        z-index: 12;
    }
</style>
