<template>
    <Header/>
    <div class="main">
        <div class="container">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import Header from '@/components/header/Header'
    import store from '@/store/store'
    import {onMounted} from 'vue'
    import {useRouter, useRoute} from 'vue-router'
    import axios from 'axios'

    export default {
        components: {
            Header
        },
        setup() {
            const router = useRouter();
            const route = useRoute();

            onMounted(async () => {
                const urlParams = new URLSearchParams(window.location.search);
                const partner = urlParams.get('partner') || '';
                const back_url = urlParams.get('back_url') || '';

                if(back_url) {
                    router.push(back_url)
                }

                if(partner && partner.includes('amperkin.ru')) {
                    let styles = document.querySelector("#app").style;
                    styles.setProperty('--orange', '#FFCC00');
                    styles.setProperty('--orange-hover', '#f1c000');
                }

                if (urlParams.get('login')) {
                    let params = {
                        login: urlParams.get('login')
                    }

                    if(urlParams.get('contract')) {
                        params.contract = urlParams.get('contract');
                    }
                    if(urlParams.get('profile')) {
                        params.profile = urlParams.get('profile');
                    }
                    axios.post(process.env.VUE_APP_API_URL + '/stats/add/', params)
                }
                if (urlParams.get('share')) {
                    await store.dispatch('fitting/loadFittingKit', window.location.href);
                    router.push('/fitting/');
                } else if (urlParams.get('project')) {
                    await store.dispatch('project/loadProject', urlParams.get('project'));
                    router.push('/project/');
                } else if (urlParams.get('item')) {
                    if (urlParams.get('item').includes('url-')) {
                        const url = urlParams.get('item').replace('url-', '')
                        router.push(url);
                    } else {
                        router.push('/catalog/detail/' + urlParams.get('item') + '/');
                    }
                }
                else {
                    setTimeout(() => {
                        if(route.name !== 'fitting') {
                            store.dispatch('fitting/getItems');
                        }
                        if(route.name !== 'project') {
                            store.dispatch('project/getProjects');
                            store.dispatch('project/getItems');
                        }
                    }, 100)
                }

                if (partner && partner.includes('eway.elevel.ru')) {
                    let recaptchaScript = document.createElement('script');
                    recaptchaScript.setAttribute('src', '//code.jivo.ru/widget/7M50sJeCR4');
                    document.head.appendChild(recaptchaScript);
                }
            });

            return {};
        }
    }
</script>

<style>

</style>
